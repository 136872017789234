import * as React from "react";
const SpicySvgIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5127 14.9998C12.2986 14.9998 12.0079 14.9684 11.6289 14.9055C7.60973 14.2379 4.33116 10.0693 4.2979 10.0279L4.31206 10.014C4.3004 9.99646 4.2879 9.97797 4.27541 9.95855C3.97054 9.49529 3.04177 8.08513 3.00096 6.91269C2.95264 5.5155 4.75019 4.38003 4.82683 4.33293C4.84848 4.32276 6.75098 3.42674 7.648 4.39396C8.39933 5.20491 8.77 7.00899 8.89161 7.60173C8.9116 7.6979 8.92826 7.78112 8.93409 7.79407L8.93826 7.80054L8.93743 7.80516L9.01239 8.30542C9.16399 10.4516 10.8866 12.2742 12.0261 13.4789C12.6658 14.1558 13.089 14.6033 12.984 14.84C12.9382 14.9473 12.7884 14.9998 12.5127 14.9998ZM4.42275 10.0933C4.96084 10.7452 7.99453 14.2423 11.6362 14.8462C12.4542 14.9821 12.8665 14.9719 12.9365 14.8138C13.024 14.6159 12.5675 14.1323 11.9894 13.5211C10.8441 12.3098 9.1124 10.4781 8.95908 8.31141L8.88494 7.81856C8.87745 7.80007 8.86745 7.75568 8.8383 7.61328C8.71751 7.02426 8.35017 5.23307 7.60967 4.43415C6.73756 3.49374 4.86846 4.37497 4.84927 4.38329C4.83345 4.39346 3.00592 5.54747 3.05255 6.90861C3.09254 8.06354 4.0138 9.46162 4.31617 9.92204C4.39947 10.0459 4.41863 10.0755 4.42279 10.0931L4.42275 10.0933Z"
      fill="#676C6F"
    />
    <path
      d="M4.95421 4.87084C5.11414 4.75155 5.22159 4.68405 5.22159 4.68405C5.22159 4.68405 5.24159 4.67481 5.26991 4.66278C4.93838 4.35578 4.72431 3.92488 4.63434 3.42927C4.5852 2.74131 4.80844 1.71213 5.34486 1.84621C6.03039 2.0182 5.29238 1.24516 4.79677 1.04359C4.46608 0.910435 4.23951 1.79167 4.12457 2.39547C4.11458 2.43153 4.10125 2.46482 4.09292 2.50181C4.06376 2.63311 4.04794 2.76535 4.04211 2.89573C4.03961 2.91607 4.03711 2.93087 4.03711 2.93087C4.03711 2.93457 4.03794 2.93827 4.03878 2.94196C4.01628 3.70204 4.3703 4.42607 4.95421 4.87084Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.94286 4.31323C4.78176 4.06251 4.66815 3.76565 4.60819 3.43471C4.5732 2.94094 4.67233 2.28348 4.92888 1.97463C5.04883 1.8313 5.19294 1.77767 5.35037 1.81651C5.41368 1.83223 5.46699 1.84055 5.50697 1.84055C5.54445 1.84055 5.58944 1.83408 5.59443 1.80171C5.61609 1.66394 5.15296 1.21917 4.78644 1.07122C4.76729 1.06383 4.7473 1.0592 4.72897 1.0592C4.51989 1.0592 4.31415 1.53633 4.14921 2.40185L4.14847 2.40447C4.14379 2.4209 4.1398 2.43496 4.13505 2.44901C4.12839 2.46843 4.12256 2.48784 4.11756 2.50911C4.09007 2.63117 4.07425 2.75786 4.06758 2.89747L4.06259 2.93538C4.06343 2.93538 4.06426 2.93815 4.06426 2.94C4.04791 3.52157 4.2493 4.08149 4.61684 4.5123C4.75335 4.41285 4.84081 4.35844 4.84081 4.35844C4.84081 4.35844 4.87742 4.34088 4.94286 4.31323ZM4.57134 4.54579C4.19823 4.10497 3.99399 3.53321 4.01011 2.94092C4.00928 2.93907 4.00845 2.93445 4.00845 2.92982L4.01344 2.89191C4.02011 2.75136 4.03677 2.62099 4.06509 2.49522C4.07009 2.47303 4.07675 2.45083 4.08425 2.42957C4.08601 2.42369 4.08792 2.41782 4.08986 2.41188C4.0925 2.40378 4.09517 2.39556 4.09757 2.38703C4.21752 1.75546 4.42494 1 4.72896 1C4.75395 1 4.77977 1.00462 4.80476 1.01479C5.15626 1.1572 5.67855 1.61677 5.64689 1.81187C5.64022 1.85164 5.61107 1.89972 5.50695 1.89972C5.46197 1.89972 5.40533 1.8914 5.33869 1.87475C5.19958 1.83961 5.07463 1.8877 4.96801 2.0153C4.72396 2.30843 4.62815 2.96126 4.66065 3.42728C4.7196 3.75269 4.83293 4.04553 4.99406 4.29201C5.46268 4.10135 6.88958 3.61541 7.63042 4.41484C8.3763 5.2197 8.74292 7.00682 8.86641 7.60879C8.89103 7.72882 8.90599 7.80173 8.91236 7.81034L8.98732 8.3106C9.13887 10.4655 10.8777 12.3045 12.0085 13.5005C13.0957 14.6503 13.6208 15.2057 11.6328 14.8758C7.57695 14.2028 4.31843 10.0083 4.31843 10.0083L4.39768 10.1024C4.39615 10.0914 4.3586 10.0343 4.29683 9.9405C3.98713 9.47007 3.06855 8.07476 3.02827 6.91145C2.99155 5.85103 4.05753 4.92785 4.57134 4.54579Z"
      fill="#676C6F"
    />
  </svg>
);
export default SpicySvgIcon;
