import * as React from "react";
const DailyMealSvgIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 2V4"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 2V4"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2V4"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6H13C13.1326 6 13.2598 6.05268 13.3536 6.14645C13.4473 6.24021 13.5 6.36739 13.5 6.5V12.5C13.5 12.8978 13.342 13.2794 13.0607 13.5607C12.7794 13.842 12.3978 14 12 14H4C3.60218 14 3.22064 13.842 2.93934 13.5607C2.65804 13.2794 2.5 12.8978 2.5 12.5V6.5C2.5 6.36739 2.55268 6.24021 2.64645 6.14645C2.74021 6.05268 2.86739 6 3 6Z"
      fill="#676C6F"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 7L13.5 8.5"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 7L2.5 8.5"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DailyMealSvgIcon;
