import * as React from "react";
const DeleteSvgIcon = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.999 4.48671C11.779 4.26671 9.54569 4.15338 7.31902 4.15338C5.99902 4.15338 4.67902 4.22005 3.35902 4.35338L1.99902 4.48671"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66602 3.81337L5.81268 2.94004C5.91935 2.30671 5.99935 1.83337 7.12602 1.83337H8.87268C9.99935 1.83337 10.086 2.33337 10.186 2.94671L10.3327 3.81337"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.566 6.59338L12.1326 13.3067C12.0593 14.3534 11.9993 15.1667 10.1393 15.1667H5.85928C3.99928 15.1667 3.93928 14.3534 3.86595 13.3067L3.43262 6.59338"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.88574 11.5001H9.10574"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33301 8.83337H9.66634"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DeleteSvgIcon;
