
import * as React from "react";
const RefreshIconSvg = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 2.07301 10.96 2.07301 10.96M2.07301 10.96H5.08634M2.07301 10.96V14.2934M1.33301 8.00004C1.33301 4.32004 4.29301 1.33337 7.99967 1.33337C12.4463 1.33337 14.6663 5.04004 14.6663 5.04004M14.6663 5.04004V1.70671M14.6663 5.04004H11.7063"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default RefreshIconSvg;
