
import * as React from "react";
const HeadPhoneSvgIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.83321 12.4333C1.55988 12.4333 1.33321 12.2067 1.33321 11.9333V8.13333C1.29988 6.32667 1.97321 4.62 3.22655 3.34C4.47988 2.06667 6.15988 1.36667 7.96655 1.36667C11.6599 1.36667 14.6665 4.37334 14.6665 8.06667V11.8667C14.6665 12.14 14.4399 12.3667 14.1665 12.3667C13.8932 12.3667 13.6665 12.14 13.6665 11.8667V8.06667C13.6665 4.92667 11.1132 2.36667 7.96655 2.36667C6.42655 2.36667 4.99988 2.96 3.93988 4.04C2.87321 5.12667 2.30655 6.57334 2.33321 8.12V11.9267C2.33321 12.2067 2.11321 12.4333 1.83321 12.4333Z"
      fill="#404548"
    />
    <path
      d="M3.95967 8.29999H3.87301C2.47301 8.29999 1.33301 9.43999 1.33301 10.84V12.0933C1.33301 13.4933 2.47301 14.6333 3.87301 14.6333H3.95967C5.35967 14.6333 6.49967 13.4933 6.49967 12.0933V10.84C6.49967 9.43999 5.35967 8.29999 3.95967 8.29999Z"
      fill="#404548"
    />
    <path
      d="M12.1267 8.29999H12.04C10.64 8.29999 9.5 9.43999 9.5 10.84V12.0933C9.5 13.4933 10.64 14.6333 12.04 14.6333H12.1267C13.5267 14.6333 14.6667 13.4933 14.6667 12.0933V10.84C14.6667 9.43999 13.5267 8.29999 12.1267 8.29999Z"
      fill="#404548"
    />
  </svg>
);
export default HeadPhoneSvgIcon;
