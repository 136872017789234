
import * as React from "react";
const DangerSvgIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1331 13.2666L12.7997 3.66663C12.0831 2.37496 11.0914 1.66663 9.99974 1.66663C8.90807 1.66663 7.9164 2.37496 7.19974 3.66663L1.8664 13.2666C1.1914 14.4916 1.1164 15.6666 1.65807 16.5916C2.19974 17.5166 3.2664 18.025 4.6664 18.025H15.3331C16.7331 18.025 17.7997 17.5166 18.3414 16.5916C18.8831 15.6666 18.8081 14.4833 18.1331 13.2666ZM9.37474 7.49996C9.37474 7.15829 9.65807 6.87496 9.99974 6.87496C10.3414 6.87496 10.6247 7.15829 10.6247 7.49996V11.6666C10.6247 12.0083 10.3414 12.2916 9.99974 12.2916C9.65807 12.2916 9.37474 12.0083 9.37474 11.6666V7.49996ZM10.5914 14.7583C10.5497 14.7916 10.5081 14.825 10.4664 14.8583C10.4164 14.8916 10.3664 14.9166 10.3164 14.9333C10.2664 14.9583 10.2164 14.975 10.1581 14.9833C10.1081 14.9916 10.0497 15 9.99974 15C9.94974 15 9.8914 14.9916 9.83307 14.9833C9.78307 14.975 9.73307 14.9583 9.68307 14.9333C9.63307 14.9166 9.58307 14.8916 9.53307 14.8583C9.4914 14.825 9.44974 14.7916 9.40807 14.7583C9.25807 14.6 9.1664 14.3833 9.1664 14.1666C9.1664 13.95 9.25807 13.7333 9.40807 13.575C9.44974 13.5416 9.4914 13.5083 9.53307 13.475C9.58307 13.4416 9.63307 13.4166 9.68307 13.4C9.73307 13.375 9.78307 13.3583 9.83307 13.35C9.9414 13.325 10.0581 13.325 10.1581 13.35C10.2164 13.3583 10.2664 13.375 10.3164 13.4C10.3664 13.4166 10.4164 13.4416 10.4664 13.475C10.5081 13.5083 10.5497 13.5416 10.5914 13.575C10.7414 13.7333 10.8331 13.95 10.8331 14.1666C10.8331 14.3833 10.7414 14.6 10.5914 14.7583Z"
      fill="#FF6E1D"
    />
  </svg>
);
export default DangerSvgIcon;
