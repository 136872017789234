import * as React from "react";

const GoIconSvg = () => {

  return (
    <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.06656 2.49664L2.4099 1.83998L1.3399 0.769976C1.11323 0.546642 0.726562 0.706642 0.726562 1.02664V3.10331V4.97331C0.726562 5.29331 1.11323 5.45331 1.3399 5.22664L3.06656 3.49998C3.34323 3.22664 3.34323 2.77331 3.06656 2.49664Z" fill="#851D1E"/>
    </svg>
    
  );
};

export default GoIconSvg;
