
import * as React from "react";
const MicrophoneIconSvg = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9324 7.60001C15.6074 7.60001 15.3491 7.85834 15.3491 8.18334V9.50001C15.3491 12.45 12.9491 14.85 9.99909 14.85C7.04909 14.85 4.64909 12.45 4.64909 9.50001V8.17501C4.64909 7.85001 4.39076 7.59167 4.06576 7.59167C3.74076 7.59167 3.48242 7.85001 3.48242 8.17501V9.49168C3.48242 12.8833 6.09076 15.675 9.41576 15.975V17.75C9.41576 18.075 9.67409 18.3333 9.99909 18.3333C10.3241 18.3333 10.5824 18.075 10.5824 17.75V15.975C13.8991 15.6833 16.5158 12.8833 16.5158 9.49168V8.17501C16.5074 7.85834 16.2491 7.60001 15.9324 7.60001Z"
      fill="#851D1E"
    />
    <path
      d="M9.99974 1.66663C7.96641 1.66663 6.31641 3.31663 6.31641 5.34996V9.61663C6.31641 11.65 7.96641 13.3 9.99974 13.3C12.0331 13.3 13.6831 11.65 13.6831 9.61663V5.34996C13.6831 3.31663 12.0331 1.66663 9.99974 1.66663ZM11.0914 7.45829C11.0331 7.67496 10.8414 7.81663 10.6247 7.81663C10.5831 7.81663 10.5414 7.80829 10.4997 7.79996C10.1747 7.70829 9.83307 7.70829 9.50807 7.79996C9.24141 7.87496 8.98307 7.71663 8.91641 7.45829C8.84141 7.19996 8.99974 6.93329 9.25807 6.86663C9.74974 6.73329 10.2664 6.73329 10.7581 6.86663C11.0081 6.93329 11.1581 7.19996 11.0914 7.45829ZM11.5331 5.84163C11.4581 6.04163 11.2747 6.15829 11.0747 6.15829C11.0164 6.15829 10.9664 6.14996 10.9081 6.13329C10.3247 5.91663 9.67474 5.91663 9.09141 6.13329C8.84141 6.22496 8.55807 6.09163 8.46641 5.84163C8.37474 5.59163 8.50807 5.30829 8.75807 5.22496C9.55807 4.93329 10.4414 4.93329 11.2414 5.22496C11.4914 5.31663 11.6247 5.59163 11.5331 5.84163Z"
      fill="#851D1E"
    />
  </svg>
);
export default MicrophoneIconSvg;
