import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginActions } from "../store/loginSlice";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../store/constants";

const useGeoLocation = () => {
  const API_ENDPOINT = "https://maps.googleapis.com/maps/api/geocode/json";
  const dispatch = useDispatch();

  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });

  const [city, setCity] = useState("");

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
    dispatch(
      loginActions.setCoords({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      })
    );
  };

  const onError = (error) => {
    setLocation({
      loaded: false,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  useEffect(() => {
    if (location.loaded) {
      axios
        .get(
          `${API_ENDPOINT}?latlng=${location.coordinates.lat},${location.coordinates.lng}&key=${YOUR_GOOGLE_MAPS_API_KEY}`
        )
        .then((res) => setCity(res.data.results[0].formatted_address));
    }
  }, [location]);

  return { location, city };
};

export default useGeoLocation;
