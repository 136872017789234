import * as React from "react";
const VeganSvgIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9997 5.08184V5.15763C10.9997 5.08184 10.9997 5.08184 10.9996 5.08184L10.9993 5.08184L10.9983 5.08184L10.9948 5.08186L10.9813 5.08203C10.9694 5.08221 10.9521 5.08258 10.9294 5.08331C10.8842 5.08476 10.8179 5.08766 10.733 5.09343C10.5632 5.10496 10.3191 5.128 10.0203 5.17401C9.42284 5.266 8.60574 5.44995 7.72696 5.81801C5.99302 6.54423 4.01846 7.98786 3.02058 10.8518C2.99877 10.826 2.97342 10.7953 2.94531 10.7601C2.84843 10.6387 2.71916 10.4638 2.58993 10.2501C2.33062 9.82116 2.07579 9.24311 2.07579 8.63148C2.07579 8.0192 2.36874 7.04782 3.03596 6.15045C3.70154 5.25527 4.73633 4.43824 6.21566 4.12697L6.21568 4.12696C6.96316 3.96954 7.78608 3.84149 8.61111 3.71808C8.72483 3.70107 8.8386 3.68415 8.9522 3.66725C9.66199 3.56167 10.3657 3.45699 11.0136 3.33762C11.7647 3.19923 12.4454 3.04033 12.9771 2.83543C13.3477 2.69264 13.6553 2.52411 13.8623 2.31733C13.8542 2.35689 13.8453 2.39972 13.8356 2.44565C13.7738 2.73805 13.678 3.15607 13.5425 3.65781C13.2713 4.66158 12.8415 5.99903 12.2065 7.33572C10.9337 10.0153 8.85247 12.6607 5.59988 12.6607C5.39823 12.6607 5.10913 12.5832 4.82266 12.476L4.75278 12.4499L4.72555 12.5193L4.18284 13.9033L3.19051 13.5385C3.23408 13.4242 3.27768 13.3092 3.32148 13.1937C3.89699 11.6758 4.50741 10.066 5.54974 8.66493C6.68526 7.13864 8.33496 5.859 11.017 5.23142L10.9997 5.08184Z"
      fill="#676C6F"
      stroke="#676C6F"
      strokeWidth={0.151577}
    />
  </svg>
);
export default VeganSvgIcon;
