import * as React from "react";
const CartIconSvg = (props) =>{
  const fillColor = props.active ? "#851D1E" : "#676C6F";
  return <svg
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <path
    d="M6.25 6.39167V5.58334C6.25 3.70834 7.75833 1.86667 9.63333 1.69167C11.8667 1.47501 13.75 3.23334 13.75 5.42501V6.57501"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M7.49986 18.3333H12.4999C15.8499 18.3333 16.4499 16.9916 16.6249 15.3583L17.2499 10.3583C17.4749 8.32496 16.8915 6.66663 13.3332 6.66663H6.66652C3.10819 6.66663 2.52486 8.32496 2.74986 10.3583L3.37486 15.3583C3.54986 16.9916 4.14986 18.3333 7.49986 18.3333Z"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.9131 10H12.9206"
    stroke={fillColor}
    strokeWidth={1.66667}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M7.07859 10H7.08608"
    stroke={fillColor}
    strokeWidth={1.66667}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
};
export default CartIconSvg;
