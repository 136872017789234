import React, { useState, Suspense, lazy, useEffect } from "react";
import AppContainer from "../util/AppContainer";
import BottomBar from "../BottomBar";
import "./login.css";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import {
  registeration,
  updateName,
  updateReferral,
  sendOTP,
  login,
  addWaitingList,
  //getUserCount,
} from "../../store/loginActions";
import Privacy from "../../constant/Privacy&Policy/Privacy";
import Terms from "../../constant/Terms&Condition/Terms";
import Cancellation from "../../constant/Cancellation/Cancellation";
import EarlyUsers from "../userprofile/EarlyUsers";
import {
  PopBenifits,
  PrivacyWrapper,
  UsersTermsWrapper,
} from "../userprofile/styled";
import CloseIcon from "../../assets/close.png";
import mainImage from "../../assets/loginTheme.png";
import CuiratoLogo from "../../assets/cuirato-main-box.png";
import { SuspenseImg } from "../util/SuspendImg";
import ImageLoader from "../chef/ImageLoader";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ReactGA from "react-ga4";
import { useReadOTP } from "react-read-otp";

import Banner from "../banner/Banner";
import BannerWrapper from "../banner/BannerWrapper";
import Slider from "react-slick";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import mixpanel from "mixpanel-browser";
import { isWebApp } from "../../utils/helper";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
// import clevertap from "clevertap-web-sdk";
import PlaystoreSvg from "../../assets/ReactSvg/PlaystoreSvg";
import ApplestoreSvg from "../../assets/ReactSvg/ApplestoreSvg";


const Login = (props) => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/login" });
    ReactGA.event({
      category: "tab",
      action: "login",
    });
    mixpanel.track("login");
    // clevertap.event.push("login");
  }, []);
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const getName = useSelector((state) => state.auth.getName);
  const getReferral = useSelector((state) => state.auth.getReferral);
  const chefId = useSelector((state) => state.chef.chefId);
  const user = useSelector((state) => state.auth.user);
  const menuOpens = useSelector((state) => state.auth.freeMenuOpens);
  const menuIdsOpened = useSelector((state) => state.auth.freeMenuidsOpened);
  const [UserBenefits, setUserBenefits] = useState(props.showBenefits);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loginState, setLoginState] = useState({
    name: "",
    phoneNumber: user ? user.phonenumber : "",
    referral: params.get("referral_code") ? params.get("referral_code") : "",
    otp: "",
    onOtpSend: false,
  });

  const [otpValue, setOtpValue] = useState("");
  // useReadOTP(setOtpValue);
  const [enabled, setEnabled] = useState(false);
  const [otp, setOTP] = useState("");
  const [generateOTP, setGenerateOTP] = useState("");
  const [isShownValue, setIsShownValue] = useState("no Value");
  const [stepperIndex, setStepperIndex] = useState(
    props.activeStepper ? props.activeStepper : 1
  );
  const [Privacydata, setPrivacydata] = useState(false);
  const [Termsdata, setTermsdata] = useState(false);
  const [Cancellationdata, setCancellationdata] = useState(false);
  //const bottombardisplay = useSelector((state) => state.bottomBar.bottombardisplay);
  const [waitingList, setWaitingList] = useState(false);
  const waitListSuccess = useSelector((state) => state.auth.waitListSuccess);
  const waitListFail = useSelector((state) => state.auth.waitListFail);
  // const userCount = useSelector((state) => state.auth.userCount);

  // useEffect(() => {
  //   dispatch(getUserCount());
  // }, []);

  // useReadOTP(
  //   (otp) => {
  //     setIsShownValue("Method is called");
  //     setOTP(otp);
  //   },
  //   {
  //     enabled,
  //   }
  // );

  const isMobileDevice = () => {
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent;

    /* Creating a regular expression
    containing some mobile devices keywords
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);
    const isIpad = /ipad/i.test(details) || 
                 (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    return isMobileDevice || isIpad;
  };

  const register = () => {
    let maxDishes;
    const { name, phoneNumber, referral } = loginState;
    //add validations, handle signup api
    const pattern = /^[A-Za-z\s]+$/;
    if (!pattern.test(loginState.name) || !loginState.name?.trim()) {
      setError(true);
      setErrorText("Please enter valid name");
    }
    else if (loginState.name) {
      // (loginState.name && loginState.referral)
      setError(false);
      maxDishes = 2;
      dispatch(
        registeration(
          { name, phoneNumber, referral, menuOpens, menuIdsOpened, maxDishes },
          setError,
          chefId
        )
      );
    } else {
      if (!loginState.name) {
        setError(true);
        setErrorText("Your name is required");
      }
      // else if (!loginState.referral) {
      //   setError(true);
      //   setErrorText("Only people with a Referral code can register");
      // }
    }
  };

  const doLogin = () => {
    const { phoneNumber, otp, onOtpSend } = loginState;
    if (
      phoneNumber.length === 10 &&
      otpValue.length === 4 &&
      //Number(otpValue) === otp &&
      onOtpSend
    ) {
      dispatch(login({ phoneNumber, menuOpens, menuIdsOpened }, chefId));
      mixpanel.track("otp_proceed_clicked");
      // clevertap.event.push("otp_proceed_clicked");
    }
  };

  const updateNameInUser = () => {
    dispatch(updateName(user, loginState.name));
  };

  const updateReferralInUser = () => {
    // api needs to return user, function doesn't work.
    const { name, phoneNumber, referral } = loginState;
    let user = { name, phoneNumber, referral, menuOpens, menuIdsOpened };
    dispatch(updateReferral(user, loginState.referral));
  };

  const closeOTPPopup = () => {
    setLoginState({ ...loginState, onOtpSend: false });
  };

  const resendOTP = () => {
    setOtpSent();
    mixpanel.track("resend_otp_clicked");
    // clevertap.event.push("resend_otp_clicked");
  };

  function setOtpSent() {
    if (loginState.phoneNumber.length === 10) {
      // const otp = Math.floor(1000 + Math.random() * 9000);
      const otp = 1234;
      setGenerateOTP(otp);
      setLoginState({ ...loginState, otp, onOtpSend: true });
      setError(false);
      // get country mobile code
      const mobileNumber = "91" + loginState.phoneNumber;
      //call sms-otp api
      dispatch(sendOTP({ mobileNumber, otp }));
      setEnabled(true);
      return true;
    } else if (loginState.phoneNumber.length !== 10) {
      setError(true);
      setErrorText("Phone number should be 10 characters");
      return false;
    }
  }

  const handleNextStepper = (currentOpenedIndex) => {
    switch (currentOpenedIndex) {
      case 1:
        setStepperIndex((prevIndex) => prevIndex + 1);
        return;
      case 2:
        let success = setOtpSent();
        if (success) setStepperIndex(5);
        mixpanel.track("send_otp_clicked");
        // clevertap.event.push("send_otp_clicked");
        return;
      case 3:
        setStepperIndex((prevIndex) => prevIndex + 1);
        return;
      default:
        break;
    }
  };

  const goToLogin = (step) => {
    setWaitingList(false);
    handleNextStepper(step);
    mixpanel.track("login_clicked-have_referral_code");
    // clevertap.event.push("login_clicked-have_referral_code");
  };

  const joinWaitingList = (step) => {
    setWaitingList(true);
    handleNextStepper(step);
    mixpanel.track("waiting_list_clicked-want_referral_code");
    // clevertap.event.push("waiting_list_clicked-want_referral_code");
  };

  const waitingListSubmit = () => {
    document
      .getElementsByClassName("btn-login")[0]
      .classList.add("disabled-link");
    dispatch(addWaitingList(loginState.phoneNumber, enableButton));
    setLoginState({ phoneNumber: "" });
    mixpanel.track("join_waiting_list_clicked-want_referral_code");
    // clevertap.event.push("join_waiting_list_clicked-want_referral_code");
  };

  const enableButton = () => {
    document
      .getElementsByClassName("btn-login")[0]
      .classList.remove("disabled-link");
  };

  useEffect(() => {
    if (getName) {
      closeOTPPopup();
      if (stepperIndex != 4) {
        setStepperIndex(3); //redirect to sign up;
      }
    }
  }, [getName]);

  useEffect(() => {
    if (getReferral) {
      setError(true);
      setErrorText(
        "You can't join without a referral code. We’ll add you to the waiting list."
      );
    }
  }, [getReferral]);

  const [gif1, setGif1] = useState("images/intro-banner-chef-profile.gif");

  useEffect(() => {
    setGif1("images/intro-banner-chef-profile.gif");
  }, [gif1]);

  const [gif2, setGif2] = useState("images/intro-banner-cost.gif");

  useEffect(() => {
    setGif2("images/intro-banner-cost.gif");
  }, [gif2]);


  const onAndroidDownloadClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.cuirato.app");
  }

  const onIosDownloadClick = () => {
    window.open("https://apps.apple.com/in/app/cuirato/id6464570070");
  }

  const LoginWrapper = (stepper) => {
    switch (stepper) {
      case 1:
        // dispatch(
        //   bottomBarActions.changeBottombar({bottombardisplay : true})
        // );
        return (
          <>
            <div className="login-container">
              {!props.initial ? (
                <div className="back-btn-wrapper mg-10 back-banner-btn">
                  <button
                    onClick={() => {
                      onBackClick(1);
                    }}
                    style={{ margin: "10px 0 0 10px" }}
                    type="button"
                    className="btn back-btn"
                  >
                    <BackBtnSvg />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <BannerWrapper className="banner-wrapper">
                <Slider {...settings}>
                  <div className="banner-login-slider">
                    <Banner src="images/intro-banner-1.jpg" />
                    <h2 className="container-title" align="center">
                      Order food directly from <br />
                      professional chefs
                    </h2>
                    <p>
                      Authentic, gourmet cuisine <br />
                      from your own, personal chefs.
                    </p>
                  </div>
                  <div className="banner-login-slider">
                    <Banner src={gif1} />
                    <h2 className="container-title" align="center">
                      Meet handpicked <br />
                      Cuisine experts
                    </h2>
                    <p>
                      Connect with people who take the ownership <br />
                      of getting your food right!
                    </p>
                  </div>
                  <div className="banner-login-slider">
                    <Banner src="images/intro-banner-personalisation.jpg" />
                    <h2 className="container-title" align="center">
                      Your own, personal chef
                    </h2>
                    <p>
                      Your fav dishes - the way you like it, <br />
                      from trusted experts.
                    </p>
                  </div>
                  <div className="banner-login-slider">
                    <Banner src={gif2} />
                    <h2 className="container-title" align="center">
                      Guilt-free food <br />
                      every single time
                    </h2>
                    <p>
                      Don’t break the bank <br />
                      to satisfy your food cravings.
                    </p>
                  </div>
                  <div className="banner-login-slider">
                    <Banner src="images/intro-banner-2.jpg" />
                    <h2 className="container-title" align="center">
                      A first-ever cloud kitchen
                    </h2>
                    <p>
                      {" "}
                      where only professional chefs cook to serve you <br />
                      their cuisine specialities goes live soon!
                    </p>
                  </div>
                  <div className="banner-login-slider">
                    <Banner src="images/intro-banner-3.jpg" />
                    <h2 className="container-title" align="center">
                      Explore chef menus and <br /> pre-order dishes.
                    </h2>
                    <p>
                      The first 8 cuisine experts are available for pre-orders.
                    </p>
                  </div>
                  <div className="banner-login-slider">
                    <Banner src="images/intro-banner-4.jpg" />
                    <h2 className="container-title" align="center">
                      First come-best buy
                    </h2>
                    <p>
                      Dish prices increase as more people join and book; <br />
                      Don’t miss out on the best deals!
                    </p>
                  </div>
                </Slider>
              </BannerWrapper>

              {isMobileDevice() ? (
                <div className="banner-box">
                  <div className="button-box">
                    {/* {!isWebApp() ? (
                      <a
                        className="btn-login btn-install-button"
                      >
                        <span style={{cursor:"pointer"}} onClick={onAndroidDownloadClick}><PlaystoreSvg /></span>
                        <span style={{cursor:"pointer"}} onClick={onIosDownloadClick}><ApplestoreSvg /></span>
                      </a>
                    ) : ( */}
                      <a className="btn-login" onClick={() => goToLogin(1)}>
                        <span>Login</span>
                      </a>
                    {/* )} */}
                  </div>
                </div>
              ) : (
                <div className="banner-box">
                  <div className="button-box">
                    <a className="btn-login btn-install-button" style={{ width: "45%" }} >
                      <span style={{ cursor: "pointer" }} onClick={onAndroidDownloadClick}><PlaystoreSvg /></span>
                      <span style={{ cursor: "pointer" }} onClick={onIosDownloadClick}><ApplestoreSvg /></span>
                    </a>
                  </div>
                </div>
              )}
              {/* {isMobileDevice() ? (
                <div className="login-options">
                  {!isWebApp() ? (
                    <p onClick={() => dispatch(bottomBarActions.showChef())}>
                      Skip
                    </p>
                  ) : (
                    <p onClick={() => joinWaitingList(1)}>
                      I want an exclusive code
                    </p>
                  )}
                </div>
              ) : (
                <></>
              )} */}
              {/* <div className="signup-progress-container">
                <p>{userCount} of 1000 slots have been filled!</p>
                <progress
                  className="signup-progress"
                  value={userCount}
                  max="1000"
                ></progress>
              </div> */}
            </div>
          </>
        );
      case 2:
        // dispatch(
        //   bottomBarActions.changeBottombar({bottombardisplay : false})
        // );
        return (
          <>
            <div className="login-section">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="mt login-box">
                  <h2 style={{ paddingLeft: 35 }} className="login-title">
                    {waitingList ? "Join Waitlist" : "Login"}
                  </h2>
                  <SuspenseImg src={mainImage}></SuspenseImg>{" "}
                  <div className="login-content pg-5">
                    <div className="back-btn-wrapper">
                      <button
                        onClick={() => onBackClick(2)}
                        type="button"
                        className="btn back-btn"
                      >
                        <BackBtnSvg />
                      </button>
                    </div>
                  </div>
                </div>
              </Suspense>

              <div className="form-box">
                <div>
                  <div className={error ? "" : "hide"}>
                    <p className="error-msg">{errorText}</p>
                  </div>
                  <TextField
                    selectTextOnFocus={true}
                    className="material-form-field "
                    id="standard-basic"
                    name="phoneNumber"
                    label="PHONE NUMBER"
                    type="number"
                    value={loginState.phoneNumber}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                      if (e.target.value.length == 10) {
                        e.target.blur();
                      }
                    }}
                    onChange={({ target }) => {
                      setLoginState({
                        ...loginState,
                        [target.name]: target.value,
                        otp: "",
                        onOtpSend: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>+91</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="button-box mt-30">
                  <button
                    className={`btn-login ${loginState.phoneNumber.length !== 10 && "disabled"
                      }`}
                    disabled={loginState.phoneNumber.length !== 10}
                    onClick={() =>
                      waitingList ? waitingListSubmit() : handleNextStepper(2)
                    }
                  >
                    {waitingList ? <span>Join</span> : <span>Continue</span>}
                  </button>
                </div>
                <br />
                {waitListSuccess ? (
                  <p className="waitList-msg">
                    Thank you! We're releasing the app on <br />
                    first come basis.
                    <br />
                    You will be receiving your exclusive code <br />
                    to pre-order dishes on your SMS soon!
                  </p>
                ) : (
                  <></>
                )}
                {waitListFail ? (
                  <p className="waitList-msg">
                    Your number is already registered with us.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        );
      case 3:
        // dispatch(
        //   bottomBarActions.changeBottombar({bottombardisplay : false})
        // );
        return (
          <>
            <div className="login-section">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="login-box login-sign-up-box">
                  <h2 style={props.activeStepper === 3 ? { paddingLeft: 35 } : {}} className="login-title">Login</h2>
                  <SuspenseImg src={mainImage}></SuspenseImg>{" "}
                  <div className="login-content pg-10">
                    {props.activeStepper === 3 && (
                      <>
                        <div className="back-btn-wrapper">
                          <button
                            onClick={() => onBackClick(3)}
                            type="button"
                            className="btn back-btn"
                          >
                            <BackBtnSvg />
                          </button>
                        </div>
                      </>
                    )}

                    {/* <p className="container-text">
                    We don't know you enough.
                  </p> */}
                  </div>
                </div>
              </Suspense>

              <div className="form-box">
                <div className={error ? "" : "hide"}>
                  <p className="error-msg">{errorText}</p>
                </div>
                <div>
                  <TextField
                    autoComplete="off"
                    className="material-form-field"
                    id="standard-basic"
                    label="NAME"
                    name="name"
                    value={loginState.name}
                    onFocus={({ target }) => {
                      setTimeout(window.scrollTo(0, 200), 160);
                    }}
                    onChange={({ target }) => {
                      setLoginState({
                        ...loginState,
                        [target.name]: target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-30">
                  {params.get("referral_code") ? (
                    <TextField
                      autoComplete="off"
                      className="material-form-field"
                      id="standard-basic"
                      label="REFERRAL CODE"
                      value={params.get("referral_code")}
                      name="referral"
                      onFocus={({ target }) => {
                        window.scrollTo(0, 350);
                      }}
                    />
                  ) : (
                    <TextField
                      autoComplete="off"
                      className="material-form-field"
                      id="standard-basic"
                      label="REFERRAL CODE"
                      value={loginState.referral}
                      name="referral"
                      onFocus={({ target }) => {
                        window.scrollTo(0, 350);
                      }}
                      onChange={({ target }) => {
                        setLoginState({
                          ...loginState,
                          [target.name]: target.value.trim(),
                        });
                      }}
                    />
                  )}
                </div>
                <div className="mt-30">
                  <TextField
                    className="material-form-field "
                    id="standard-basic"
                    label="PHONE NUMBER"
                    name="phoneNumber"
                    value={loginState.phoneNumber}
                    disabled
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                      if (e.target.value.length == 10) {
                        e.target.blur();
                      }
                    }}
                    onChange={({ target }) => {
                      setLoginState({
                        ...loginState,
                        [target.name]: target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>+91</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="button-box mt-30" onClick={register}>
                  <a className="btn-login">
                    {/* <span>Continue</span> */}
                    <span>Create Account</span>
                  </a>
                </div>
                {/*<div className="conditions-box">
                   By clicking, I accept the <a href="">Terms &amp; Conditions </a> &amp; <a href="">Privacy Policy</a>
                  <a href="">Have a referral code?</a>
                </div>*/}
              </div>
            </div>
          </>
        );
      case 4:
        // dispatch(
        //   bottomBarActions.changeBottombar({bottombardisplay : true})
        // );
        return (
          <>
            <div className="login-section">
              <div className="login-box edit-box">
                <img
                  className="login-bg-img"
                  src="./images/demo.jpg"
                  alt="login"
                />
                <div className="login-content pg-10">
                  {props.activeStepper === 3 && (
                    <>
                      <div className="back-btn-wrapper">
                        <button
                          onClick={() => onBackClick(3)}
                          type="button"
                          className="btn back-btn"
                        >
                          <BackBtnSvg />
                        </button>
                      </div>
                    </>
                  )}
                  <h2 className="login-title">Edit Profile</h2>
                </div>
              </div>

              <div className="form-box">
                <div className={error ? "" : "hide"}>
                  <p className="error-msg">{errorText}</p>
                </div>
                <div>
                  <TextField
                    autoComplete="off"
                    className="material-form-field"
                    id="standard-basic"
                    label="NAME"
                    name="name"
                    value={loginState.name}
                    onFocus={({ target }) => {
                      setTimeout(window.scrollTo(0, 200), 160);
                    }}
                    onChange={({ target }) => {
                      setLoginState({
                        ...loginState,
                        [target.name]: target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-20">
                  {params.get("referral_code") ? (
                    <TextField
                      autoComplete="off"
                      className="material-form-field"
                      id="standard-basic"
                      label="REFERRAL CODE"
                      value={params.get("referral_code")}
                      name="referral"
                      onFocus={({ target }) => {
                        window.scrollTo(0, 250);
                      }}
                    />
                  ) : (
                    <TextField
                      autoComplete="off"
                      className="material-form-field"
                      id="standard-basic"
                      label="REFERRAL CODE"
                      value={loginState.referral}
                      name="referral"
                      onFocus={({ target }) => {
                        window.scrollTo(0, 250);
                      }}
                      onChange={({ target }) => {
                        setLoginState({
                          ...loginState,
                          [target.name]: target.value,
                        });
                      }}
                    />
                  )}
                </div>
                <div className="mt-30">
                  <TextField
                    className="material-form-field "
                    id="standard-basic"
                    label="PHONE NUMBER"
                    name="phoneNumber"
                    value={loginState.phoneNumber}
                    disabled
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                      if (e.target.value.length == 10) {
                        e.target.blur();
                      }
                    }}
                    onChange={({ target }) => {
                      setLoginState({
                        ...loginState,
                        [target.name]: target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>+91</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="button-box mt-30" onClick={register}>
                  <a className="btn-login">
                    {/* <span>Continue</span> */}
                    <span>Update Account</span>
                  </a>
                </div>
                {/*<div className="conditions-box">
                   By clicking, I accept the <a href="">Terms &amp; Conditions </a> &amp; <a href="">Privacy Policy</a>
                  <a href="">Have a referral code?</a>
                </div>*/}
              </div>
            </div>
          </>
        );
      case 5:
        // dispatch(
        //   bottomBarActions.changeBottombar({bottombardisplay : false})
        // );
        return (
          <>
            <div className="login-section">
              <div className="login-box login-otp">
                <div className="login-content pg-10">
                  <div className="back-btn-wrapper">
                    <button
                      onClick={() => onBackClick(4)}
                      type="button"
                      style={{ margin: "10px 0 0 10px" }}
                      className="btn back-btn"
                    >
                      <BackBtnSvg />
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-box">
                <div>
                  <div className={error ? "" : "hide"}>
                    <p className="error-msg">{errorText}</p>
                  </div>
                </div>

                <div className="otp-wrapper">
                  <div className="otp-input">
                    <input
                      type="number"
                      value={otpValue}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                        if (e.target.value.length == 4) {
                          e.target.blur();
                        }
                      }}
                      onChange={(e) => {
                        setOtpValue(e.target.value);
                      }}
                      maxLength={4}
                      minLength={4}
                      pattern="\d{4}"
                      autoFocus
                    />
                  </div>
                  <p className="enter-otp-msg">
                    Enter the 4-digit code <br /> sent on your phone via SMS
                    {/* {otpValue != generateOTP && <p>Enter 4-digit otp...</p>} */}
                  </p>
                  {/* <h1>{isShownValue}</h1>
                <input placeholder="Enter otp" value={otp} onChange={e => setOTP(e.target.value)} /> */}
                  <div className="button-panel">
                    <button
                      disabled={otpValue != generateOTP ? true : false}
                      className={
                        "proceed-btn " +
                        (otpValue != generateOTP ? "disabled" : "")
                      }
                      onClick={doLogin}
                    >
                      <p className="button-text">Proceed</p>
                    </button>
                  </div>
                  <div className="resend_otp-wrapper">
                    <a className="resend-otp" onClick={() => resendOTP()}>
                      <span>Resend OTP</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return;
    }
  };

  const onBackClick = (currentOpenedIndex) => {
    switch (currentOpenedIndex) {
      case 1:
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        return;
      case 2:
        setStepperIndex((prevIndex) => prevIndex - 1);
        return;
      case 3:
        if (props.activeStepper) {
          dispatch(bottomBarActions.loadTab({ tabName: "profile" }));
          return;
        }
        setStepperIndex((prevIndex) => prevIndex - 1);
        return;
      case 4:
        setStepperIndex(2);
        return;
      default:
        return;
    }
    // dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      if (current - next < 0) {
        setGif1("");
        setGif2("");
        mixpanel.track("intro-banner-slide-l2r");
        // clevertap.event.push("intro-banner-slide-l2r");
      } else {
        setGif1("");
        setGif2("");
        mixpanel.track("intro-banner-slide-r2l");
        // clevertap.event.push("intro-banner-slide-r2l");
      }
    },
  };

  return (
    <AppContainer id={props.id}>
      {LoginWrapper(stepperIndex)}
      {/* <div className="login-options">
        <p onClick={() => setUserBenefits(!UserBenefits)}>
          Early user benefits
        </p>
      </div> */}
      {UserBenefits && (
        <PopBenifits>
          <div className="modal-top-section">
            <div onClick={() => setUserBenefits(false)} className="colse-btn">
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
          <EarlyUsers notLoggedIn={true} />
        </PopBenifits>
      )}

      {/* <div className="Td-head">
        <p onClick={() => setPrivacydata(!Privacydata)}>
          Privacy And Policy
        </p>
      </div>
      {Privacydata && (
        <UsersTermsWrapper>
          <div className="modal-top-section">
            <div onClick={() => setPrivacydata(false)} className="colse-btn">
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
          <Privacy/>
        </UsersTermsWrapper>
      )}
      <div className="Td-head">
        <p onClick={() => setTermsdata(!Termsdata)}>
        Terms and Conditions
        </p>
      </div>
      {Termsdata && (
        <UsersTermsWrapper>
          <div className="modal-top-section">
            <div onClick={() => setTermsdata(false)} className="colse-btn">
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
          <Terms/>
        </UsersTermsWrapper>
      )}
      <div className="Td-head">
        <p onClick={() => setCancellationdata(!Cancellationdata)}>
        Cancellation and Refund Policy
        </p>
      </div>
      {Cancellationdata && (
        <UsersTermsWrapper>
          <div className="modal-top-section">
            <div onClick={() => setCancellationdata(false)} className="colse-btn">
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
          <Cancellation/>
        </UsersTermsWrapper>
      )} */}

      {!props.initial ? <BottomBar /> : <></>}
    </AppContainer>
  );
};

export default Login;
