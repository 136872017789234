import * as React from "react";
const CODPaymentIconSvg = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.3} y={0.3} width={31.4} height={31.4} rx={6.1} fill="white" />
    <rect
      x={0.3}
      y={0.3}
      width={31.4}
      height={31.4}
      rx={6.1}
      stroke="#D5D1C9"
      strokeWidth={0.6}
    />
    <path
      d="M21.9748 11.5333C21.6165 9.72502 20.2748 8.93335 18.4081 8.93335H11.0915C8.89147 8.93335 7.4248 10.0333 7.4248 12.6V16.8917C7.4248 18.7417 8.18314 19.825 9.43314 20.2917C9.61647 20.3583 9.81647 20.4167 10.0248 20.45C10.3581 20.525 10.7165 20.5583 11.0915 20.5583H18.4165C20.6165 20.5583 22.0831 19.4583 22.0831 16.8917V12.6C22.0831 12.2083 22.0498 11.8583 21.9748 11.5333ZM10.6081 16C10.6081 16.3417 10.3248 16.625 9.98314 16.625C9.64147 16.625 9.35814 16.3417 9.35814 16V13.5C9.35814 13.1583 9.64147 12.875 9.98314 12.875C10.3248 12.875 10.6081 13.1583 10.6081 13.5V16ZM14.7498 16.95C13.5331 16.95 12.5498 15.9667 12.5498 14.75C12.5498 13.5333 13.5331 12.55 14.7498 12.55C15.9665 12.55 16.9498 13.5333 16.9498 14.75C16.9498 15.9667 15.9665 16.95 14.7498 16.95ZM20.1331 16C20.1331 16.3417 19.8498 16.625 19.5081 16.625C19.1665 16.625 18.8831 16.3417 18.8831 16V13.5C18.8831 13.1583 19.1665 12.875 19.5081 12.875C19.8498 12.875 20.1331 13.1583 20.1331 13.5V16Z"
      fill="#676C6F"
    />
    <path
      d="M24.5831 15.0999V19.3916C24.5831 21.9583 23.1165 23.0666 20.9081 23.0666H13.5915C12.9665 23.0666 12.4081 22.9749 11.9248 22.7916C11.5331 22.6499 11.1915 22.4416 10.9165 22.1749C10.7665 22.0333 10.8831 21.8083 11.0915 21.8083H18.4081C21.4915 21.8083 23.3248 19.9749 23.3248 16.8999V12.5999C23.3248 12.3999 23.5498 12.2749 23.6915 12.4249C24.2581 13.0249 24.5831 13.8999 24.5831 15.0999Z"
      fill="#676C6F"
    />
  </svg>
);
export default CODPaymentIconSvg;
