import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import { S3_MENU_FOLDER } from "../../store/constants";
import { fetchDishBand } from "../../store/menuActions";
import "./exploreStory.css";
import { ToastContainer, toast } from "react-toastify";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import {
  getDisplayChefTimeLabel,
  getTodayDateValue,
} from "../../utils/timeHelper";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";
import ImageLoader from "../chef/ImageLoader";
import { addDish, removeDish, updatePortion } from "../../store/cartActions";
// import clevertap from "clevertap-web-sdk";
import { calculateCurrentPrice, combineTheDate } from "../../utils/helper";
import "react-lazy-load-image-component/src/effects/blur.css";
import AudioSvg from "../../assets/ReactSvg/AudioSvg";
import AudioMuteSvg from "../../assets/ReactSvg/AudioMuteSvg";
import Joyride from "react-joyride";
import PlayIconSvg from "../../assets/ReactSvg/PlayIconSvg";
import PauseSvg from "../../assets/ReactSvg/PauseSvg";
import ChefCardSkeleton from "../ChefListScreen/ChefCardSkeleton";
import { ControlBar, Player } from "video-react";
import { uniq } from "lodash";
import { useSwiperSlide } from "swiper/react";
import VolumeHighSvgIcon from "../../assets/ReactSvg/VolumeHighSvgIcon";
import VolumeSlashSvgIcon from "../../assets/ReactSvg/VolumeSlashSvgIcon";
import AddCartIconSvg from "../../assets/ReactSvg/AddCartIconSvg";
import StarFourIconSvg from "../../assets/ReactSvg/StarFourIconSvg";
import TimerSvgIcon from "../../assets/ReactSvg/TimerSvgIcon";
// import "video-react/dist/video-react.css"; // import css

const steps = [
  {
    title: "You can see dish related info here",
    content: "below is description of the dish ingredients",
    spotlightPadding: 0,
    placement: "left-start",
    locale: {
      last: "Got it",
    },
    target: ".ingrident-list-header-label-active",
    disableBeacon: true,
  },
];

const VideoPlayer = memo(
  ({
    videoUrl,
    isVideoActiveOnScreen,
    action,
    loaded,
    isVideoReadyToPlay,
    setIsVideoReadyToPlay,
    onReady,
    imageLoaded,
    isPlaying: isPlayingVideo,
    seekTimeRef,
    seekTime,
    setSeekTime,
    swiper,
    exploreIndex,
    isMuted,
    setIsMuted,
  }) => {
    const playPauseBtnRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
      setIsPlaying(isPlayingVideo);
    }, [isPlayingVideo]);

    const handleReady = () => {
      onReady();
      imageLoaded();
    };

    useEffect(() => {
      handleReady();
    }, []);

    const handlePlayPause = () => {
      const player = playerRef.current;
      if (isPlaying) {
        player.pause();
        action("pause");
      } else {
        player.play();
        action("play");
      }
      setIsPlaying(!isPlaying);
    };

    useEffect(() => {
      const player = playerRef.current;
      if (isVideoActiveOnScreen) {
        player.play();
        setIsPlaying(true);
      } else {
        player.pause();
      }
    }, [isVideoActiveOnScreen]);

    const handleMuteUnmute = () => {
      const player = playerRef.current;
      if (isMuted) {
        player.muted = true;
      } else {
        player.muted = false;
      }
      setIsMuted(!isMuted);
    };

    const handlePlay = () => {
      setIsVideoReadyToPlay(true);
    };

    useEffect(() => {
      const player = playerRef.current;

      const handleReadyStateChange = () => {
        handlePlay();
        setIsReady(true);
      };

      const handleTimeUpdate = () => {
        if (player.currentTime >= 2) {
          player.pause();
          setIsPlaying(false);
        }
      };

      const videoElement = player.video.video;

      videoElement.addEventListener("loadeddata", handleReadyStateChange);
      videoElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoElement.removeEventListener("loadeddata", handleReadyStateChange);
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }, []);

    const src = videoUrl;

    return (
      <div>
        {!isVideoReadyToPlay && (
          <div>
            ChefCardSkeleton
            <ChefCardSkeleton darkMode />
          </div>
        )}
        <div>
          <div style={{ position: "relative" }} className="player-wrapper">
            {/* <button
              id={`muteBtn-${exploreIndex}`}
              class="btn-play-pause react-player-mute-unmute-button"
              onClick={handleMuteUnmute}
              style={{ background: "transparent", border: "0px" }}
            >
              <span id={`iconMute-${exploreIndex}`}>
                {!isMuted ? <AudioMuteSvg /> : <AudioSvg />}
              </span>
            </button> */}
            {/* <button
              onClick={handlePlayPause}
              id={`playPauseBtn-${exploreIndex}`}
              className="btn-play-pause react-player-play-pause-button"
              ref={playPauseBtnRef}
              title="Play/Pause"
            >
              <span id={`iconPlayPause-${exploreIndex}`}>
                {!isPlaying ? <PlayIconSvg /> : <PauseSvg />}
              </span>
            </button> */}
            <div class="explore-video-player">
              <div class="videoContainer" id="videoContainer">
                <Player
                  ref={playerRef}
                  src={src}
                  muted={!isVideoActiveOnScreen}
                  autoPlay={true}
                  width="100%"
                  playsInline
                ></Player>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const ExploreStory = memo((props) => {
  const {
    exploreIndex,
    name,
    headers,
    isActive,
    dishSpecialType,
    dishInfo,
    chefName,
    chefId,
    action,
    isPaused,
    storyImages,
    labels,
    labelDesc,
    swiper,
  } = props;
  const chefTimeData = useSelector((state) => state.chef.chefTime);
  const currentChefsTime = chefTimeData[chefId];
  const swiperIndexSelector = useSelector((store) => store.storie.swiperIndex);
  const swiperSlide = useSwiperSlide();
  const runJoyrideSelector = useSelector((store) => store.storie.runJoyride);
  const { cartData, scheduleTime, isScheduleNow } = useSelector(
    ({ cart }) => cart
  );
  const scheduleDateTime = combineTheDate(
    scheduleTime?.date,
    scheduleTime?.time
  );
  const currentDishCount =
    cartData?.find((order) => order._id === dishInfo._id)?.portion || 0;
  const menuCartDataInfo = cartData.find(
    (c) => (c.dishId || c._id) === dishInfo._id
  );
  const user = useSelector(({ auth }) => auth.user);
  const loggedIn = user.phonenumber ? true : false;
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const chefList = useSelector((state) => state.chef.chefList);
  const currentChefDetails = chefList.find((c) => c._id === chefId) || {};
  const isDishAddedToCart = cartData
    .map((cart) => cart._id)
    ?.includes(dishInfo._id);
  const cartChefIds = cartData.map((a) => a.chefId);
  const allCartChefList =
    chefList
      ?.filter((a) => cartChefIds.includes(a._id))
      ?.map((c) => c.kitchenId) || [];
  const discountType = loggedIn
    ? user.maxDishes > user.totalDishesBooked
      ? "deepDiscount"
      : "normal"
    : "deepDiscount";

  const dispatch = useDispatch();
  const isVideoActiveOnScreen = swiperIndexSelector === exploreIndex;
  const isNext = swiperSlide.isNext;
  const isPrev =
    swiperIndexSelector > exploreIndex &&
    swiperIndexSelector <= exploreIndex + 10;

  const [currentPrice, setCurrentPrice] = useState(0);
  const currentPriceRef = useRef();
  const seekTimeRef = useRef(0);
  const [loaded, setLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isVideoReadyToPlay, setIsVideoReadyToPlay] = useState(false);
  const [seekTime, setSeekTime] = useState(0);

  const handleMenuClick = () => {
    dispatch(menuActions.setCurrentChefIdClicked(props.chefId));
    dispatch(menuActions.setChefInfoDefaultKey(1));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
    mixpanel.track("go-to-menu-clicked explore");
    // clevertap.event.push("go-to-menu-clicked explore");
  };

  const addToCard = (dishId, extraPrice = 0, customizeObj = []) => {
    const currentChef = chefList.find((chef) => chef._id === props.chefId);
    if (
      [...uniq(allCartChefList)].length &&
      ![...uniq(allCartChefList)].includes(currentChef.kitchenId)
    ) {
      //already added chef kitchen location
      toast.error("Please order from one restaurant at a time");
      return;
    }
    mixpanel.track(`explore add to cart clicked`);
    // clevertap.event.push(`explore add to cart clicked`);
    const isDishAdded = cartData.find((cart) => cart._id === dishId);
    if (isDishAdded && isDishAdded.isAddedOnDiscount) {
      toast.error("You can't add more portions for the discount price.");
      return;
    }
    if (!user.userid) {
      dispatch(bottomBarActions.loadTab({ tabName: "account" }));
    } else {
      let totalDiscountedDishesAdded = 0;
      cartData.forEach((cart) => {
        if (cart.isAddedOnDiscount == true) {
          totalDiscountedDishesAdded += 1;
        }
      });

      let isDiscountAllowed =
        user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;

      let isAddedOnDiscount =
        isDiscountAllowed && user.maxDishes !== 0 ? true : false;
      const payload = {
        cartPayload: {
          userId: user.userid,
          dishId: dishId,
          dealPrice: currentPrice + extraPrice,
          customizeObj: customizeObj,
          isAddedOnDiscount: isAddedOnDiscount,
          price: dishInfo.price,
          portion: currentDishCount + 1,
        },
        dish: { ...dishInfo, portion: currentDishCount + 1 },
        maxDishes: user.maxDishes,
        isFromCart: false,
        actionType: "ADD",
      };
      if (payload?.cartPayload?.portion < 1) {
        toast.error("Please add portion");
        return;
      }
      if (currentDishCount === 0) {
        dispatch(addDish(payload));
      } else {
        const cartDishInfo = cartData?.find((cart) => cart._id === dishId);
        payload.cartId = cartDishInfo.cartId;
        payload.cartPayload.dealPrice = menuCartDataInfo.dealPrice;
        dispatch(updatePortion(payload));
      }

      if (props.setShowAddedItemPopup) {
        props.setShowAddedItemPopup(true);
      }
    }
    mixpanel.track("added-to-cart");
    // clevertap.event.push("added-to-cart");
    ReactPixel.track("AddToCart");
  };

  const onRemoveDish = async (dishId) => {
    const payload = {
      userId: user.userid,
      dishId: dishId,
      isActive: false,
      maxDishes: user.maxDishes,
      dealPrice: menuCartDataInfo.dealPrice,
      isFromCart: false,
    };
    let totalDiscountedDishesAdded = 0;
    let isDiscountAllowed =
      user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;
    let isAddedOnDiscount =
      isDiscountAllowed && user.maxDishes !== 0 ? true : false;

    if (currentDishCount > 1) {
      const payload = {
        cartPayload: {
          userId: user.userid,
          dishId: dishId,
          dealPrice: currentPrice,
          isAddedOnDiscount: isAddedOnDiscount,
          price: dishInfo.price,
          portion: currentDishCount - 1,
        },
        dish: { ...dishInfo, portion: currentDishCount - 1 },
        maxDishes: user.maxDishes,
        isFromCart: false,
        actionType: "REMOVE",
      };
      const cartDishInfo = cartData?.find((cart) => cart._id === dishId);
      payload.cartId = cartDishInfo.cartId;
      payload.cartPayload.dealPrice = menuCartDataInfo.dealPrice;
      dispatch(updatePortion(payload));
    } else {
      payload.dealPrice = menuCartDataInfo.dealPrice;
      dispatch(removeDish(payload));
    }
    mixpanel.track("dish-removed");
    // clevertap.event.push("dish-removed");
  };

  const onChefNameClick = () => {
    dispatch(menuActions.setCurrentChefIdClicked(props.chefId));
    dispatch(menuActions.setChefInfoDefaultKey(2));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
  };

  useEffect(() => {
    if (!props.id) return;
    dispatch(fetchDishBand(props.id));
  }, [dispatch]);

  useEffect(() => {
    if (!loaded && isVideoActiveOnScreen) {
      action("pause");
    }
  }, [loaded, isVideoActiveOnScreen]);

  useEffect(() => {
    if (!isPaused && !loaded) {
      action("pause");
    }
  }, [isPaused, loaded]);

  useEffect(() => {
    if (loaded && isVideoActiveOnScreen) {
      action("play");
    }
  }, [loaded, isVideoActiveOnScreen]);

  useEffect(() => {
    if (!isVideoActiveOnScreen) {
      action("pause");
    }
  }, [isVideoActiveOnScreen]);

  const imageLoaded = (time = 0) => {
    setTimeout(() => {
      setLoaded(true);
    }, time);
  };

  const getStatusClassname = () => {
    if (!isServiceable) {
      return "chef-non-serviciable";
    } else if (todayChefSchedule?.online) {
      return "chef-online";
    } else {
      return "chef-away";
    }
  };
  const capitalize = (str) =>
    str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : "";
  const isScheduleOrder = !isScheduleNow && moment(scheduleDateTime).isValid();
  const currentPriceValue = calculateCurrentPrice(
    dishInfo,
    currentChefsTime,
    currentChefDetails,
    isScheduleOrder,
    discountType,
    dishSpecialType
  );

  useEffect(() => {
    const isScheduleOrder =
      !isScheduleNow && moment(scheduleDateTime).isValid();
    const currentPriceValue = calculateCurrentPrice(
      dishInfo,
      currentChefsTime,
      currentChefDetails,
      isScheduleOrder,
      discountType,
      dishSpecialType
    );
    if (!!currentPriceRef?.current) return;
    if (currentPriceValue === currentPriceRef?.current) return;
    setCurrentPrice(currentPriceValue);
    currentPriceRef.current = currentPriceValue;
  }, [
    isScheduleNow,
    scheduleDateTime,
    dishInfo,
    currentChefsTime,
    currentChefDetails,
    user.maxDishes,
    user.totalDishesBooked,
  ]);

  const todayChefSchedule = getTodayDateValue(
    currentChefDetails?.schedules || []
  );

  const textOutput = getDisplayChefTimeLabel(todayChefSchedule);
  const percentageDiscount =
    ((props.price - currentPriceValue) / props.price) * 100;

  const discountMessage = `Voila! You got ${Math.round(
    percentageDiscount
  )}% off`;

  const onReady = () => {
    imageLoaded(500);
  };

  const exploreImageLoaded = () => {
    setLoaded(true);
    setIsVideoReadyToPlay(true);
  };

  return (
    <div>
      <div
        style={{ position: "absolute", top: "0px", height: "100%" }}
        className="image-container"
      >
        {(isVideoActiveOnScreen || isNext || isPrev) && (
          <>
            {props.img.includes("mp4") ? (
              <VideoPlayer
                videoUrl={S3_MENU_FOLDER + props.img}
                isVideoActiveOnScreen={isVideoActiveOnScreen}
                swiper={swiper}
                action={action}
                loaded={loaded}
                isActive={isVideoActiveOnScreen}
                isPlaying={isVideoActiveOnScreen && !isPaused}
                onReady={onReady}
                imageLoaded={imageLoaded}
                seekTimeRef={seekTimeRef}
                seekTime={seekTime}
                setSeekTime={setSeekTime}
                isVideoReadyToPlay={isVideoReadyToPlay}
                setIsVideoReadyToPlay={setIsVideoReadyToPlay}
                exploreIndex={exploreIndex}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
              />
            ) : (
              <img
                src={S3_MENU_FOLDER + props.img}
                className="story-image-container"
                loading="lazy"
                onLoad={exploreImageLoaded}
              />
            )}
            {!!isVideoActiveOnScreen &&
              storyImages.map((img, index) => {
                return (
                  <React.Fragment key={index}>
                    {!!!img.includes("mp4") && (
                      <img
                        src={S3_MENU_FOLDER + img}
                        loading="lazy"
                        className="hide-story-image"
                      />
                    )}
                  </React.Fragment>
                );
              })}{" "}
          </>
        )}
      </div>
      {!loaded && (
        <ImageLoader className="loader_wrapper explore_loader_wrapper" />
      )}
      <div
        className={`exploreChefScreen ${isVideoReadyToPlay ? "show" : "hide"}`}
      >
        <div className="swiper_Top explore_swiper_Top">
          <div className="exploreSoundIcon">
            {isMuted ? <VolumeHighSvgIcon /> : <VolumeSlashSvgIcon />}
          </div>
        </div>
        {labelDesc?.storyType !== "DEMO" && (
          <div className="swiper_Top explore_swiper_Top">
            <div className="swiper_Title"></div>

            <div className="swiper_top_menu">
              <div className="swiper_top_back_btn"></div>
            </div>
          </div>
        )}

        <div style={{ padding: 0, bottom: 0 }} className="swipper_bottom">
          <div
            style={!!labelDesc?.demoLabel ? { height: 140 } : {}}
            className="explore-swipper_bottom"
          >
            {exploreIndex === 0 && dishSpecialType === "DEMO" && (
              <>
                {!!labelDesc.key && (
                  <Joyride
                    disableScrollParentFix={true}
                    continuous={true}
                    scrollToFirstStep={true}
                    showProgress={false}
                    showSkipButton={false}
                    steps={steps}
                    run={runJoyrideSelector}
                    disableOverlay={true}
                    hideBackButton={true}
                    disableScrolling={true}
                    styles={{
                      options: {
                        arrowColor: "#F8EDD5",
                        primaryColor: "#e8814d",
                        zIndex: 10000,
                        backgroundColor: "#F8EDD5",
                        overlayColor: "rgba(0, 0, 0, 0.6)",
                      },
                      tooltip: {
                        width: "350px",
                      },
                      tooltipTitle: {
                        fontSize: 16,
                        margin: "0 0 0px 0",
                        textAlign: "left",
                        paddingLeft: "10px",
                        color: "#670000",
                      },
                      tooltipContent: {
                        fontSize: 13,
                        padding: "10px 10px",
                        textAlign: "left",
                        fontWeight: 300,
                        color: "#670000",
                      },
                      buttonNext: {
                        fontSize: "13px",
                      },
                    }}
                  />
                )}
              </>
            )}
            <div className="exploreDishNameTitleWrapper">
              <span className="exploreDishNameTitle Satoshi">
                {headers || labelDesc?.dishNameHeader}
              </span>
              <span className="exploreDishNameTitleLabel Satoshi">
                ({name || labelDesc?.storyHeader})
              </span>
            </div>
            <div className="chef-info-name-wrapper">
              <div
                onClick={onChefNameClick}
                className="swiper_Title_img explore_swiper_Title_img"
              >
                <img
                  src={
                    currentChefDetails?.chefImgTh ||
                    "https://cuirato-images.s3.ap-south-1.amazonaws.com/display-pic/thumbnail/chef3-dp.jpg"
                  }
                  className="storyImg"
                />
              </div>
              <div>
                <p
                  onClick={onChefNameClick}
                  className="explore-chef-label Satoshi"
                >
                  Chef {chefName}
                </p>
                <div className="chef-time-wrapper">
                  <TimerSvgIcon />
                  <span className="explore-chef-timing-label">
                    {textOutput}
                  </span>
                </div>
              </div>
              <button
                onClick={handleMenuClick}
                className="explore-story-menu-button"
              >
                Menu
              </button>
            </div>
            <div className="ingrident-list-wrapper">
              {!!labelDesc.key && (
                <div className="ingrident-list-header-label">
                  {labels
                    .filter((a) => a.key)
                    .map((l, index) => {
                      return (
                        <div key={index}>
                          <span
                            className={
                              l.key === labelDesc.key &&
                              "ingrident-list-header-label-active Satoshi"
                            }
                          >
                            {capitalize(l.key)}
                          </span>
                          {index + 1 < labels.length && <StarFourIconSvg />}
                        </div>
                      );
                    })}
                </div>
              )}
              <div
                className={
                  labelDesc?.demoDesc
                    ? "label-desc demo Satoshi"
                    : "label-desc Satoshi"
                }
              >
                {labelDesc?.desc || labelDesc?.demoDesc}
              </div>
            </div>
            {(!!currentPriceValue || props.price) && (
              <div className="explore_swipper_bottom_first">
                <div>
                  <div className="items_value">
                    <div className="plus_taxes">
                      <h5 className="explore-deal-price-wrapper">
                        <span className="explore-deal-price Satoshi">
                          {`₹${
                            currentPriceValue > 0
                              ? currentPriceValue
                              : props.price
                          }`}
                        </span>
                        {currentPriceValue > 0 && (
                          <span className="price-strike explore-price-strike Satoshi">
                            {`₹${props.price}`}
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="explore-addcart_btn">
                  {!isDishAddedToCart ? (
                    <button
                      className="Satoshi"
                      onClick={() => addToCard(dishInfo._id)}
                    >
                      <AddCartIconSvg />
                      <span className="explore-addcart_btn_text">
                        Add to cart
                      </span>
                    </button>
                  ) : (
                    <button style={{ padding: "0px 8px 0px 8px" }}>
                      <span onClick={() => onRemoveDish(dishInfo._id)}>-</span>
                      <span style={{ fontSize: "18px", lineHeight: "22px" }}>
                        {currentDishCount}
                      </span>
                      <span onClick={() => addToCard(dishInfo._id)}>+</span>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
});

export default ExploreStory;
