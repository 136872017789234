import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./chef-cards.css";
import { EVENING_BREAK_SHIFT_END_TIME, EVENING_BREAK_SHIFT_START_TIME, EVENING_SHIFT_END_TIME, EVENING_SHIFT_START_TIME, MORNING_BREAK_SHIFT_END_TIME, MORNING_BREAK_SHIFT_START_TIME, MORNING_SHIFT_END_TIME, MORNING_SHIFT_START_TIME, PREP_TIME, WEEK_OFF } from "../../store/constants";
import moment from "moment";
import { getTodayDateValue, secondShiftApplies } from "../../utils/timeHelper";

const ChefName = (props) => {
  const { isSwitchOff, chefWaitTime, showOnlyDot = false, schedules = [], kitchenId } = props;
  // const delivery_duration = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  // );
  const deliveryDetails = useSelector(
    ({ auth }) => auth.deliveryDetails
  );
  const chefDeliveryDetails = deliveryDetails[kitchenId] || {};
  const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;

  const isServiceable = useSelector((state) => state.auth.isServiceable);

  let delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) : 10;

  const chefWaitTimeValue = Math.max(PREP_TIME, chefWaitTime || 0);
  const todayChefSchedule = getTodayDateValue(schedules);


  const getStatusLabel = () => {
    if (!isServiceable) {
      return "Pre-bookings only"
    } else if (isSwitchOff) {
      return "offline"
    } else if (!todayChefSchedule?.isAvailable) {
      const findNextDayIndex = schedules.findIndex((s) => s._id === todayChefSchedule._id);
      if (findNextDayIndex === (schedules.length - 1)) {
        const nextDayInfo = schedules[0];
        const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
        return `Opens tomorrow at ${startTime}`
      } else {
        const nextDayInfo = schedules[findNextDayIndex + 1];
        const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
        return `Opens tomorrow at ${startTime}`
      }
    } else if (todayChefSchedule?.online) {
      if (delivery_duration) {
        return `Delivery in ${delivery_Time + chefWaitTimeValue} mins`;
      } else {
        return "Open now";
      }
    } else if (todayChefSchedule?.isAvailable) {
      if (secondShiftApplies(todayChefSchedule) && todayChefSchedule?.startTime1 && todayChefSchedule?.endTime1) {
        return `Opens at ${moment(todayChefSchedule?.startTime1, 'h:mm A').format('h a')}`
      }
      return `Opens at ${moment(todayChefSchedule?.startTime, 'h:mm A').format('h a')}`
    }
  }

  const getStatusClassname = () => {
    if (!isServiceable) {
      return "chef-non-serviciable";
    } else if (todayChefSchedule?.online) {
      return "chef-online";
    } else {
      return "chef-away";
    }
  };

  if (showOnlyDot) {
    return <></>
    //  <div style={{ width: 12 }} className="chef-status-indicator">
    //   <div
    //     className={getStatusClassname()}
    //   />
    // </div>
  }

  return (
    <>
      <div className="chef-detail">
        <h4 className="chef-name">Chef {props.name}</h4>
        <div style={{ width: "134%", gap: "2px" }} className="chef-status-indicator">
          <div
            className={getStatusClassname()}
          />
          <p>
            <button className="chef-status-button">
              {getStatusLabel()}
            </button>
          </p>
        </div>
      </div>

    </>
  );
};

export default ChefName;
