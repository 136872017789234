import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import CloseModalIcon from "../../assets/ReactSvg/CloseModalIcon";
import VegSvgIcon from "../../assets/ReactSvg/VegSvgIcon";
import EggSvgIcon from "../../assets/ReactSvg/EggSvgIcon";
import NonVegSvgIcon from "../../assets/ReactSvg/NonVegSvgIcon";
import DailyMealSvgIcon from "../../assets/ReactSvg/DailyMealSvgIcon";
import WeeklyMealSvgIcon from "../../assets/ReactSvg/WeeklyMealSvgIcon";
import VeganSvgIcon from "../../assets/ReactSvg/VeganSvgIcon";
import SpicySvgIcon from "../../assets/ReactSvg/SpicySvgIcon";
import { Button } from "antd";
import CloseMaroonIconSvg from "../../assets/ReactSvg/CloseMaroonIconSvg";

const FilterBottomModal = ({
  isOpen,
  setOpen,
  defaultSelectedFilters,
  setDefaultSelectedFilters
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    veg: false,
    egg: false,
    nonVeg: false,
    dailyMeal: false,
    weeklyMeal: false,
    vegan: false,
    spicy: false,
  });

  const toggleFilter = (filter) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };

  const handleApplyClick = () =>{
    setDefaultSelectedFilters(selectedFilters);
    setOpen(false);
  }

  const handleClearClick = () =>{
    setDefaultSelectedFilters({
      veg: false,
      egg: false,
      nonVeg: false,
      dailyMeal: false,
      weeklyMeal: false,
      vegan: false,
      spicy: false,
    })
    setOpen(false);
  }

  useEffect(() =>{
    if(isOpen){
      setSelectedFilters(defaultSelectedFilters)
    }

  },[isOpen])
  return (
    <Sheet
      springConfig={{
        stiffness: 300,
        damping: 30,
        mass: 0.2,
      }}
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      detent="content-height"
      className="install-modal-popup customReactSheet filter-modal-popup"
    >
      <Sheet.Container>
        <Sheet.Content>
          <div
            onClick={() => {
              setOpen(false);
            }}
            className="closeIconInstallModal"
          >
            <CloseModalIcon />
          </div>
          <div className="filter-modal-wrapper">
            <div className="filter-modal-header-wrapper">
              <div className="Satoshi filter-modal-header">Filters</div>
            </div>
            <div className="foodChoicePreference">
              <div className="foodChoicePreferenceLabel Satoshi">
                Veg/Non-veg preference
              </div>
              <div style={{ marginTop: 12 }}>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.veg ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("veg")}
                >
                  <VegSvgIcon />
                  Veg
                  {selectedFilters.veg && <CloseMaroonIconSvg />}
                </button>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.egg ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("egg")}
                >
                  <EggSvgIcon />
                  Egg
                  {selectedFilters.egg && <CloseMaroonIconSvg />}
                </button>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.nonVeg ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("nonVeg")}
                >
                  <NonVegSvgIcon />
                  Non-veg
                  {selectedFilters.nonVeg && <CloseMaroonIconSvg />}
                </button>
              </div>
            </div>
            <div className="foodChoicePreference">
              <div className="foodChoicePreferenceLabel Satoshi">
                Offer type
              </div>
              <div style={{ marginTop: 12 }}>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.dailyMeal ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("dailyMeal")}
                >
                  <DailyMealSvgIcon />
                  Daily meals
                  {selectedFilters.dailyMeal && <CloseMaroonIconSvg />}
                </button>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.weeklyMeal ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("weeklyMeal")}
                >
                  <WeeklyMealSvgIcon />
                  Weekly special
                  {selectedFilters.weeklyMeal && <CloseMaroonIconSvg />}
                </button>
              </div>
            </div>
            <div className="foodChoicePreference">
              <div className="foodChoicePreferenceLabel Satoshi">
                Dietary preference
              </div>
              <div style={{ marginTop: 12 }}>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.vegan ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("vegan")}
                >
                  <VeganSvgIcon />
                  Vegan
                  {selectedFilters.vegan && <CloseMaroonIconSvg />}
                </button>
                <button
                  className={`transparentBtn filterTransparentBtn Satoshi ${
                    selectedFilters.spicy ? "activeFilterBtn" : ""
                  }`}
                  onClick={() => toggleFilter("spicy")}
                >
                  <SpicySvgIcon />
                  Spicy
                  {selectedFilters.spicy && <CloseMaroonIconSvg />}
                </button>
              </div>
            </div>
            <div className="filterModalButtonWrapper">
              <Button className="filterClearBtn Satoshi" onClick={handleClearClick}>Clear</Button>
              <Button className="filterApplyFilterBtn" onClick={handleApplyClick}>Apply filters</Button>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => {
          setOpen(false);
        }}
      />
    </Sheet>
  );
};

export default FilterBottomModal;
