import React from "react";
import WhiteBackBtnSvg from "../../assets/ReactSvg/WhiteBackBtnSvg";
import { useSelector } from "react-redux";
import RefreshIconSvg from "../../assets/ReactSvg/RefreshIconSvg";
import moment from "moment";

const OrderStatusScreenTopBar = ({
  uniqueChefInfoList,
  onBackClick,
  orderStatusValue,
  isOrderDelivered,
  deliveryTimeInDateObj,
  handleRefreshOrderClick,
  hideRiderInfo
}) => {
  const orderChefNameList = uniqueChefInfoList.map((c) => c.name).join(", ");
  const orderRemainingTimw = moment(deliveryTimeInDateObj).diff(
    moment(),
    "minutes"
  );
  const deliveryOnTimeLabel = orderRemainingTimw < 0 ? "Delayed" : "On time";

  return (
    <div className="order-status-screen-wrapper">
      <div
        style={
          orderRemainingTimw < 0 && !isOrderDelivered
            ? { background: "#FF6E1D" }
            : {}
        }
        className="order-status-topbar"
      >
        <div className="order-status-kitchen-name">
          <span style={{ height: 24 }}>
            <button
              onClick={onBackClick}
              type="button"
              className="btn back-btn orderStatusSummaryBackBtn"
            >
              <WhiteBackBtnSvg />
            </button>
          </span>
          <div className="orderSummaryStatusChefWrapper">
            <div className="orderSummaryStatusChefList Satoshi">
              {orderChefNameList}
            </div>
            <div className="orderSummaryOrderStatusWrapper">
              <div className={`orderSummaryOrderStatus Satoshi ${!isOrderDelivered && !hideRiderInfo ? "orderSummaryOrderStatusAfter" : ""}`}>
                {orderStatusValue}
              </div>
             {!isOrderDelivered && !hideRiderInfo  && <>
                <div
                  style={{ marginLeft: 8 }}
                  className="orderSummaryOrderStatus Satoshi"
                >
                  {orderRemainingTimw} mins I {deliveryOnTimeLabel}
                </div>
                <button
                  style={{ height: 16 }}
                  onClick={handleRefreshOrderClick}
                  className="transparentBtn Satoshi"
                >
                  <RefreshIconSvg />
                </button>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusScreenTopBar;
