import * as React from "react";

const LocationSVGComponent = ({ size = 12, strokeColor = "#851D1E", ...props }) => {
  const dimensions = size === 12 ? { width: 10, height: 6, viewBox: "0 0 10 6" } : { width: 20, height: 20, viewBox: "0 0 20 20" };

  const pathData =
    size === 12
      ? "M0.98877 1.20007L5.00013 5.20007L9.0115 1.20007"
      : "M3.31348 7.00012L9.99908 13.6668L16.6847 7.00012";

  return (
    <svg
      width={dimensions.width}
      height={dimensions.height}
      viewBox={dimensions.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d={pathData}
        stroke={strokeColor}
        strokeWidth={size === 12 ? 1.125 : 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationSVGComponent;
