import React, {
  useEffect,
  Suspense,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import BottomBar from "../BottomBar";
import Dish from "../menu/Dish";
import cartBg from "../../assets/cartBg.png";
import browseLogo from "../../assets/browseLogo.png";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { setCartData } from "../../store/cartActions";
import { cartAction } from "../../store/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import emailjs from "@emailjs/browser";
import "./cart.css";
import {
  API_TEST,
  APP_URL,
  DELIVERY_CHARGE_PER_KMS,
  EVENING_BREAK_SHIFT_END_TIME,
  EVENING_BREAK_SHIFT_START_TIME,
  EVENING_SHIFT_END_TIME,
  EVENING_SHIFT_START_TIME,
  FREE_DILIVERY_DISTANCE_RANGE,
  MORNING_BREAK_SHIFT_END_TIME,
  MORNING_BREAK_SHIFT_START_TIME,
  MORNING_SHIFT_END_TIME,
  MORNING_SHIFT_START_TIME,
  PREP_TIME,
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
  TEMP_EMAIL,
  WEEK_OFF,
} from "../../store/constants";
import { SuspenseImg } from "../util/SuspendImg";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ImageLoader from "../chef/ImageLoader";
import "../menu/browse-menu-button.css";
import { red } from "@material-ui/core/colors";
import ReactGA from "react-ga4";
import { checkout, checkoutUpdate, confirmOrder, updateOrder } from "../../store/loginActions";
import { referActions } from "../../store/referSlice";
import mixpanel from "mixpanel-browser";
import { ToastContainer, toast } from "react-toastify";
import { loginActions } from "../../store/loginSlice";
import EarlyUsers from "../userprofile/EarlyUsers";
import {
  BenefitsSkims,
  EarlyUsersBoxWrapper,
  PopBenifits,
} from "../userprofile/styled";
import ReactPixel from "react-facebook-pixel";
import { combineTheDate, countDiscountedAmount, extractPincode } from "../../utils/helper";
import ScheduleOrderButton from "../ScheduleOrderButton/Index";
import axios from "axios";
import moment from "moment";
// import clevertap from "clevertap-web-sdk";
import { getTodayDateValue } from "../../utils/timeHelper";
import { useSearchParams } from "react-router-dom";
import { uniq, uniqBy } from "lodash";
import { Button, Collapse } from "antd";
import CartMenuTopBar from "./CartMenuTopBar";
import AddCircleIconSvg from "../../assets/ReactSvg/AddCircleIconSvg";
import ArrowDownIconSvg from "../../assets/ReactSvg/ArrowDownIconSvg";
import { paymentOptionIcon } from "./cartHelper";
import CartPlaceOrderButton from "./CartPlaceOrderButton";

const GST_RATE = 5;
const MAX_ORDER_DISHES = 2;

const Cart = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromScreen = urlParams.get("fromScreen");
  const isCheckoutCancel = fromScreen === "checkoutCancel";
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "cart",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const {
    cartData,
    instructionText: instructionTextReducer,
    totalCost,
    isAddressOptionsVisible,
    scheduleTime,
    isScheduleNow,
  } = useSelector(({ cart }) => cart);
  const [chefWaitingTime, setChefWaitingTime] = useState([]);
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [prevDeliveryInfoText, setPrevDeliveryInfoText] = useState("");
  const chefList = useSelector((state) => state.chef.chefList);
  const isKitchenFull = useSelector((state) => state.chef.isKitchenFull);
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const addresses = user.addressLine1;
  const dispatch = useDispatch();
  const orderPriceIds = useRef([]);
  const ref = useRef();
  let maxDishesAllowed = user.maxDishes - user.totalDishesBooked;
  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const deliveryOptions = useSelector(
    (state) => state.auth.availableDeliveryOptions
  );
  const unserviceableModalOnCart = useSelector(
    (state) => state.auth.unserviceableModalOnCart
  );
  const farAwayModalOnCart = useSelector(
    (state) => state.auth.farAwayModalOnCart
  );
  const minimumAmount = useSelector((state) => state.auth.minimumAmount);
  const isMenuClicked = useSelector((state) => state.menu.isMenuClicked);
  // const delivery_distance = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_distance
  // );
  let user_location = useSelector((state) => state.auth.location);
  const categories = useSelector((state) => state.chef.categories);
  const cartChefIds = cartData.map((a) => a.chefId);
  const mergeDishes = (chefIds, chefData) => {
    const mergedDishes = chefIds.reduce((acc, chefId) => {
      if (chefData[chefId]) {
        const allMergedArray = chefData[chefId].map((c) =>
          c.dishes.map((dish) => ({ ...dish, dishSpecialType: c?.type }))
        );
        acc.push(...allMergedArray.flat());
      }
      return acc;
    }, []);
    return mergedDishes;
  };
  const mergedDishesArray = mergeDishes(cartChefIds, categories);
  const cartDishesIds = cartData.map((c) => c._id);
  const cartDishes = mergedDishesArray.filter((c) =>
    cartDishesIds.includes(c._id)
  );
  const hasDailySpecial = uniqBy(cartDishes, (obj) => obj._id)?.every(
    (dish) => dish.dishSpecialType === "daily"
  );

  const selectedOrder = useSelector((state) => state.auth.selectedOrder);
  const deliveryDetails = useSelector(({ auth }) => auth.deliveryDetails);
  const cartChefAddedKitchenId = chefList.find((a) =>
    cartChefIds.includes(a._id)
  )?.kitchenId;
  const chefDeliveryDetails = deliveryDetails[cartChefAddedKitchenId] || {};
  const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;
  const delivery_distance = chefDeliveryDetails?.delivery_distance;
  const kitchenLocation = chefDeliveryDetails?.kitchenLocation;
  const selectedDeliveryPortal = useSelector((state) => state.auth.selectedDeliveryPortal);

  const allCartChefList =
    chefList
      ?.filter((a) => cartChefIds.includes(a._id))
      ?.map((c) => c.kitchenId) || [];
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const currentSelectedKitchen =
    kitchensList.find((kitchen) => kitchen._id === allCartChefList[0]) || {};
  const kitchenDiscount = currentSelectedKitchen?.discounts || [];
  // const delivery_duration = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  // );

  const allChefsWaitingTime = chefWaitingTime?.map((chef) => {
    const currentChef =
      cartData
        .filter((a) => a.chefId === chef.chefId)
        .map((a) => a.portion)
        .reduce((partialSum, a) => partialSum + a, 0) * 5;
    return 0 < currentChef ? chef.chefWaitTime + currentChef : 0;
  });

  const preparationTime = Math.max(PREP_TIME, Math.max(...allChefsWaitingTime));
  let delivery_Time =
    preparationTime +
    (delivery_duration ? Math.round(delivery_duration / 60) : 10);

  const scheduleDateTime = combineTheDate(
    scheduleTime?.date,
    scheduleTime?.time
  );
  const [unAvailable, setUnAvailable] = useState(false);
  const [isSwitchedOffChef, setIsSwitchedOffChef] = useState(false);

  const [unAvailableChef, setUnAvailableChef] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState({});
  const [chefsLatestTime, setChefsLatestTime] = useState("");
  const [instructionText, setInstructionText] = useState(
    instructionTextReducer
  );
  const [paymentMode, setPaymentMode] = useState("online");
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [phonePeLoading, setPhonePeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const kitchenFull = isKitchenFull || false;

  useEffect(() => {
    dispatch(setCartData(user.userid, user.maxDishes));
  }, [dispatch, user.userid]);

  useEffect(() => {
    const chefIds = cartData.map((c) => c.chefId);
    const cartChefList = chefList.filter((chef) => chefIds.includes(chef._id));
    const allChefsStartTime = cartChefList.map(
      (c) =>
        getTodayDateValue(c.schedules).startTime ||
        getTodayDateValue(c.schedules).startTime1
    );
    const timeObjects = allChefsStartTime.map(
      (timeStr) => new Date(`2000-01-01 ${timeStr}`)
    );
    const latestTime = new Date(
      Math.max(...timeObjects.map((time) => time.getTime()))
    );
    const latestTimeStr = latestTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const momentObject = moment(latestTimeStr, "h:mm A");
    setChefsLatestTime(momentObject);
    const chefStatusList = cartChefList.map((chef) => ({
      chefId: chef._id,
      online: chef.online,
      status: chefStatus(chef),
      isSwitchOff: !!chef?.isSwitchOff,
    }));
    const offlineChefs = chefStatusList
      .filter((c) => !c.online)
      .map((c) => c.chefId);
    const switchedOffChefs = chefStatusList
      .filter((c) => c.isSwitchOff)
      .map((c) => c.chefId);
    setUnAvailableChef([...new Set([...offlineChefs, ...switchedOffChefs])]);
    setUnAvailable(!!offlineChefs.length);
    setIsSwitchedOffChef(!!switchedOffChefs.length);
  }, [cartData]);

  async function fetchChefTimeApiCall(chefId) {
    try {
      const response = await axios.get(
        `${API_TEST}getDeliveryDelayTimeByChef/${chefId}`
      );
      const responseData = response.data;
      return { chefWaitTime: responseData.data.chefWaitTime, chefId };
    } catch (error) {
      console.error("Error making API call:", error.message);
      throw error;
    }
  }

  const fetchChefTimeData = async () => {
    const uniqueChefIds = new Set();
    const uniqueChefArray = cartData
      .filter((item) => {
        if (!uniqueChefIds.has(item.chefId)) {
          uniqueChefIds.add(item.chefId);
          return true;
        }
        return false;
      })
      .map((chef) => chef.chefId);
    try {
      const apiChefCalls = uniqueChefArray.map((chefId) =>
        fetchChefTimeApiCall(chefId)
      );
      const results = await Promise.all(apiChefCalls);
      dispatch(cartAction.setChefWaitingTime({ time: results }));
      setChefWaitingTime(results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchChefTimeData();
  }, []);

  useLayoutEffect(() => {
    dispatch(cartAction.resetTotalCost());
  }, [dispatch]);

  const chefStatus = (chef) => {
    const today = isScheduleNow
      ? new Date().getDay()
      : new Date(scheduleDateTime).getDay();
    const now = isScheduleNow
      ? new Date().getHours()
      : new Date(scheduleDateTime).getHours();
    let isAvailable = "away";
    if (chef.leave) {
      isAvailable = "leave";
    } else if (chef.breakShift) {
      const now = isScheduleNow
        ? new Date().getHours()
        : new Date(scheduleDateTime).getHours();
      if (
        now >= MORNING_BREAK_SHIFT_START_TIME &&
        now < MORNING_BREAK_SHIFT_END_TIME
      ) {
        isAvailable = "online";
      } else if (
        now >= EVENING_BREAK_SHIFT_START_TIME &&
        now < EVENING_BREAK_SHIFT_END_TIME
      ) {
        isAvailable = "online";
      }
    } else {
      const now = isScheduleNow
        ? new Date().getHours()
        : new Date(scheduleDateTime).getHours();
      if (now >= MORNING_SHIFT_START_TIME && now < MORNING_SHIFT_END_TIME) {
        if (chef.shift) {
          isAvailable = "online";
        }
      } else if (
        now >= EVENING_SHIFT_START_TIME ||
        now < EVENING_SHIFT_END_TIME
      ) {
        if (!chef.shift) {
          isAvailable = "online";
        }
      }
    }
    if (
      (today == WEEK_OFF && now > EVENING_SHIFT_END_TIME) ||
      (today == WEEK_OFF + 1 && now < EVENING_SHIFT_END_TIME)
    ) {
      isAvailable = "away";
    }
    if (chef?.isSwitchOff) {
      isAvailable = "offline";
    }
    return isAvailable;
  };

  useEffect(() => {
    const handleKeyboardShow = () => {
      setKeyboardOpen(true);
      scrollToBottom();
    };

    const handleKeyboardHide = () => {
      setKeyboardOpen(false);
    };

    window.addEventListener("resize", handleKeyboardShow);
    window.addEventListener("blur", handleKeyboardHide);

    return () => {
      window.removeEventListener("resize", handleKeyboardShow);
      window.removeEventListener("blur", handleKeyboardHide);
    };
  }, []);

  const scrollToBottom = () => {
    const scrollTo = document.body.scrollHeight - window.innerHeight - 100;
    console.log(
      "scrollTo",
      scrollTo,
      document.body.scrollHeight,
      window.innerHeight
    );
    window.scrollTo({
      top: scrollTo,
      behavior: "smooth", // You can use 'auto' for instant scroll
    });
  };

  const onProceedToPay = () => {
    dispatch(cartAction.setInstructionText(instructionText));
    dispatch(bottomBarActions.loadTab({ tabName: "checkout" }));
    mixpanel.track("pay-now-clicked");
    // clevertap.event.push("pay-now-clicked");
    ReactPixel.track("InitiateCheckout");
  };

  useEffect(() => {
    if (isCheckoutCancel) {
      if (searchParams.has("fromScreen")) {
        const fromScreen = searchParams.get("fromScreen");
        if (fromScreen) {
          searchParams.delete("fromScreen");
          setSearchParams(searchParams);
        }
      }
      // onProceedToPay();
    }
  }, [isCheckoutCancel]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const showReferView = () => {
    dispatch(bottomBarActions.hideCart());
    dispatch(referActions.onReferClick({ from: "cart" }));
  };

  useEffect(() => {
    dispatch(loginActions.setSelectedOrder(null));
  }, []);

  const getDeliveryFee = () => {
    const pidgeDeliveryOptions = deliveryOptions?.PIDGE || [];
    const porterDeliveryOptions = deliveryOptions?.PORTER || [];

    if (deliveryOptions?.SHADOWFAX?.is_serviceable) {
      // SHADOWFAX delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("SHADOWFAX"));
      return parseInt(deliveryOptions?.SHADOWFAX.total_amount, 10);
    }

    if (
      delivery_distance &&
      isAreaServiceable &&
      porterDeliveryOptions.length
    ) {
      // PORTER delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("PORTER"));
      const number = porterDeliveryOptions[0]?.fare?.minor_amount;
      const formattedNumber = (number / 100).toFixed(2);
      return parseInt(formattedNumber, 10);
    }

    if (delivery_distance && isAreaServiceable && pidgeDeliveryOptions.length) {
      // PIDGE delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("PIDGE"));
      const lowestPriceNetwork = pidgeDeliveryOptions.reduce(
        (lowest, current) => {
          return current?.quote?.price < lowest?.quote?.price
            ? current
            : lowest;
        }
      )?.quote?.price;
      return parseInt(lowestPriceNetwork, 10);
    }

    dispatch(loginActions.setSelectedDeliveryPortal(""));
    return 0;
  };

  const getGSTAmount = () => {
    // Calculate 5% GST
    return (parseInt(totalCost) * GST_RATE) / 100;
  };

  const getPackagingAmount = () => {
    let packagingFee = 0;
    cartData.forEach((cart) => {
      if (cart.isAddedOnDiscount) {
        packagingFee = packagingFee + cart.packagingCost;
      }
    });
    return packagingFee;
  };

  const goToCheckout = (tabName) => {
    //TO-DO: Restriction - Remove first if for 2 dish restriction
    // if (cartData.length <= maxDishesAllowed && user.maxDishes !== 0) {
    const billingAmount = Math.round(
      totalCost + getGSTAmount() + getPackagingAmount()
    );
    let deliveryFee = getDeliveryFee();
    if (user.email) {
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      dispatch(
        checkoutUpdate(
          cartData,
          user,
          orderType,
          deliveryFee,
          billingAmount,
          getPackagingAmount(),
          getGSTAmount()
        )
      );
    } else {
      switchTab(tabName);
      // }
    }
    mixpanel.track("pay-later-clicked");
    // clevertap.event.push("pay-later-clicked");
    ReactPixel.track("InitiateCheckout");
  };

  const onBackClick = () => {
    isMenuClicked ? switchTab("menu") : switchTab("chef");
  };

  const deliveryInfoText =
    !isScheduleNow && moment(scheduleDateTime).isValid()
      ? `Delivery at ${moment(scheduleDateTime).format("MMM D, h:mm a")}`
      : delivery_Time + ` min to ${selectedAddress?.tag || ""}`;

  useEffect(() => {
    if (prevDeliveryInfoText !== deliveryInfoText) {
      orderPriceIds.current = [];
      dispatch(cartAction.resetTotalCost());
      setPrevDeliveryInfoText(deliveryInfoText);
    }
  }, [deliveryInfoText]);

  useEffect(() => {
    if (!isAreaServiceable && user.totalDishesBooked >= MAX_ORDER_DISHES) {
      mixpanel.track("showing error Refer your friends to get 3 more dishes!");
      // clevertap.event.push("showing error Refer your friends to get 3 more dishes!");
    } else if (!isAreaServiceable && user.totalDishesBooked === 0) {
      mixpanel.track("showing error Some Chefs are not online");
      // clevertap.event.push("showing error Some Chefs are not online");
    } else if (
      isAreaServiceable &&
      user.totalDishesBooked >= MAX_ORDER_DISHES
    ) {
      mixpanel.track("showing error You can schedule an order here.");
      // clevertap.event.push("showing error You can schedule an order here.");
    } else if (totalCost < minimumAmount) {
      mixpanel.track(`showing error Minimum amount should be ${minimumAmount}`);
      // clevertap.event.push(`showing error Minimum amount should be ${minimumAmount}`);
    } else if (kitchenFull) {
      mixpanel.track("showing error Kitchen full, try after some time.");
      // clevertap.event.push("showing error Kitchen full, try after some time.");
    }
  }, [
    isAreaServiceable,
    user?.totalDishesBooked,
    MAX_ORDER_DISHES,
    totalCost,
    minimumAmount,
    kitchenFull,
  ]);

  const chefOpenTimeWithDelivery = moment(chefsLatestTime)
    .add(delivery_Time, "minute")
    .subtract({ minute: 15 });
  const totalAmountCost = Math.round(
    totalCost + getGSTAmount() + getDeliveryFee() + getPackagingAmount()
  );

  // const totalAmount = selectedDiscount?.discountid ? countDiscountedAmount(totalAmountCost, selectedDiscount)  :  totalAmountCost;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const merchantTransactionId = urlParams.get('payment')
    const merchantUserId = urlParams.get('merchantUserId')
    if (!!merchantTransactionId && !!merchantUserId) {
      setPhonePeLoading(true);
      const xVerifyUrl = `/pg/v1/status/M1WKA4ZCTQM4/${merchantTransactionId}` + "642259ee-bbef-40d0-82a2-43909afecaf1";
      const xVerifyUrlData = new TextEncoder().encode(xVerifyUrl);

      window.crypto.subtle.digest('SHA-256', xVerifyUrlData)
        .then(hashBuffer => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const sha256Hash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

          const axiosOptions = {
            method: 'get',
            url: `${API_TEST}phonepe-payment-status?xVerify=${sha256Hash}&merchantTransactionId=${merchantTransactionId}&merchantUserId=${merchantUserId}`,
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            }
          };

          axios(axiosOptions)
            .then(response => {
              const { code, message } = response?.data?.message || {};
              if (code === "PAYMENT_SUCCESS") {
                if (response?.data?.message?.data?.transactionId) {
                  processOrder(response?.data?.message?.data?.transactionId);
                }
              } else {
                toast.error(message)
                deletePaymentParams()
                setPhonePeLoading(false)
              }
            })
            .catch(error => {
              toast.error("Error while verifying the payment status")
              console.error(error);
              setPhonePeLoading(false);
            });
        })
        .catch(error => {
          console.error(error);
          setPhonePeLoading(false);
        })

    } else {
      deletePaymentParams()
    }
  }, []);

  const handleOpenAddressModal = () =>{
    ref.current?.handleOpenAddressModal()
  }

  const deletePaymentParams = () => {
    dispatch(loginActions.setOpenPaymentToken(null));
    setConfirmDisabled(false);
    setLoading(false);
    if (searchParams.has("payment")) {
      const token = searchParams.get("payment");
      if (token) {
        searchParams.delete("payment");
        setSearchParams(searchParams);
      }
    }
    if (searchParams.has("merchantUserId")) {
      const merchantUserId = searchParams.get("merchantUserId");
      if (merchantUserId) {
        searchParams.delete("merchantUserId");
        setSearchParams(searchParams);
      }
    }
  }

  const sendEmailOnConfirm = (billingAmount) => {
    let dishBookedName = "";
    cartData.forEach((data) => {
      dishBookedName += data.name + ", ";
    });

    const { username, phonenumber } = user;

    const mobileNumber = "91" + phonenumber;
    let templateParams = {
      user_name: username,
      phone_number: mobileNumber,
      dish_name: dishBookedName,
      area: selectedAddress.area,
      houseNumber: selectedAddress.houseNumber,
      landmark: selectedAddress.landMark,
      locationName: selectedAddress.location.locationName,
      billingAmount: billingAmount,
      deliveryTime: delivery_Time,
      lat: selectedAddress.location.lat,
      lng: selectedAddress.location.lng,
      tag: selectedAddress.tag,
      to_email: TEMP_EMAIL,
    };
    dispatch(confirmOrder({ mobileNumber }));
    emailjs
      .send(
        "service_3t471gh", // replace with your service id
        "template_3x8ijk9", // replace with your template id
        templateParams,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then((res) => console.log(res.text))
      .catch((error) => console.log(error));
  };

  const processOrder = async (paymentId) => {
    if (!moment().isBefore(moment(scheduleDateTime)) && moment(scheduleDateTime).isValid()) {
      toast.error("Please select a valid date and time")
      return
    }
    try {
      setLoading(true);
      setPhonePeLoading(true)
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      let deliveryFee = getDeliveryFee();
      let packagingFee = getPackagingAmount(false);
      let gstFee = getGSTAmount(totalCost);
      let billingAmount = Math.round(
        totalCost + gstFee + deliveryFee + packagingFee
      );
      if (selectedOrder?.bookedDishes) {
        let scheduleDateTimeValue = moment(scheduleTime).isValid() ? new Date(scheduleTime) : "";
          if (scheduleDateTimeValue) {
            scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
            scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
          }
        const tempSelectedOrder = {...selectedOrder};
        tempSelectedOrder.scheduleTime = scheduleTime || "NOW";
        tempSelectedOrder.deliveryTime = delivery_Time;
        await dispatch(updateOrder(tempSelectedOrder, getDeliveryFee(true)));
      } else {
        if (paymentMode === "online") {
          if (!paymentId) {
            setLoading(false);
            setPhonePeLoading(false)
            toast.error("Error while verifying the payment status");
            return
          }
          const storedCheckoutData = localStorage.getItem("checkoutData");
          const {
            cartDataClone,
            user,
            email,
            address,
            orderType,
            deliveryFee,
            deliveryDistance,
            deliveryTime,
            totalCost,
            packagingFee,
            gstFee,
            instructionText,
            chefAddress,
            scheduleTime,
            billingAmount,
            paymentMode,
            pickup_details,
            drop_details,
            sender_detail,
            receiver_detail,
            discount_type,
            discount_total
          } = JSON.parse(storedCheckoutData);
          let scheduleDateTimeValue = moment(scheduleTime).isValid() ? new Date(scheduleTime) : "";
          if (scheduleDateTimeValue) {
            scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
            scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
          }
          await dispatch(
            checkout(
              cartDataClone,
              user,
              email,
              address,
              orderType,
              deliveryFee,
              deliveryDistance,
              deliveryTime,
              totalCost,
              packagingFee,
              gstFee,
              instructionText,
              chefAddress,
              scheduleDateTimeValue,
              billingAmount,
              paymentMode,
              paymentId,
              pickup_details,
              drop_details,
              sender_detail,
              receiver_detail,
              selectedDeliveryPortal,
              true,
              discount_type,
              discount_total
            )
          );
        } else {
          const cartDataClone = cartData.map((data) => {
            const {variationObj,variations, ...restDish} = data
          const customizeObj = data?.customizeObj.filter((customize) => customize.text) || [];
            return { ...restDish, customizeObj: customizeObj, itemid: data?.variationObj?.itemid || data.itemid || "", variation_id: data?.variationObj?.variationid || "" , variation_name: data?.variationObj?.name || "" };
          });
          let scheduleDateTimeValue = scheduleDateTime;
          scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
          scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
          const chefId = cartDataClone[0].chefId;
          const kitchenId = chefList.find((a) => a._id === chefId)?.kitchenId;
          const kitchenInfo = kitchensList.find((a) => a._id === kitchenId);
          const sender_detail = {
            address: {
              address_line_1: kitchenInfo?.address || "",
              address_line_2: "",
              label: "Kitchen",
              landmark: kitchenInfo.landmark,
              city: kitchenInfo.city || "Mumbai",
              state: kitchenInfo.state || "Maharashtra",
              country: "India",
              pincode: kitchenInfo.pincode || "400001",
              latitude: kitchenInfo.latitude || "19.116606", // sender latitude
              longitude: kitchenInfo.longitude || "72.8854982", // sender longitude
              instructions_to_reach: "Use Sender GPS"
            },
            name: kitchenInfo.name,
            mobile: kitchenInfo.phoneNumber,
            email: "cuirato@yopmail.com"
          };
          const receiver_detail = {
            address: {
              address_line_1: selectedAddress.location.locationName,
              label: selectedAddress.tag,
              landmark: selectedAddress.landMark || "Andheri",
              city: "Mumbai",
              state: "Maharashtra",
              country: "India",
              pincode: extractPincode(selectedAddress.location.locationName),
              latitude: selectedAddress.location.lat, // receiver latitude
              longitude: selectedAddress.location.lng, // receiver longitude
              instructions_to_reach: "Receiver GPS"
            },
            name: user.username,
            mobile: user.phonenumber,
            email: "receiver@email.com"
          }

          const pickup_details = {
            "name": kitchenInfo.name,
            "contact_number": kitchenInfo.phoneNumber,
            "address": kitchenInfo.address,
            "landmark": kitchenInfo.landmark,
          }
          const drop_details = {
            "name": user.username,
            "contact_number": user.phonenumber,
            "address": selectedAddress.location.locationName,
            "latitude": selectedAddress.location.lat,
            "longitude": selectedAddress.location.lng
          }
          const { discounttype : discount_type, discount : discount_total } = selectedDiscount
          await dispatch(
            checkout(
              cartDataClone,
              user,
              "",
              selectedAddress,
              orderType,
              deliveryFee,
              delivery_distance,
              delivery_Time,
              totalCost,
              packagingFee,
              gstFee,
              instructionText || "",
              kitchenLocation,
              scheduleDateTimeValue,
              billingAmount,
              paymentMode,
              paymentId,
              pickup_details,
              drop_details,
              sender_detail,
              receiver_detail,
              selectedDeliveryPortal,
              true,
              discount_type,
              discount_total
            )
          );
        }

      }
      sendEmailOnConfirm(billingAmount);
      mixpanel.track("checked-out");
      // clevertap.event.push("checked-out");
      ReactPixel.track("Purchase", { value: billingAmount, currency: "INR" });
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
      setPhonePeLoading(false)
      deletePaymentParams()
    }
  };

  const handlePhonePeClick = (billingAmount, phonenumber) => {
    const appUrlVal = APP_URL;
    const appUrl  = appUrlVal.replace('https://', 'https://www.').replace(/\/$/, '');
    const merchantTransactionId = 'MT' + Date.now();
    const merchantUserId = "MUID" + Date.now()
    if (billingAmount === 0) return
    const payload = {
      "merchantId": "M1WKA4ZCTQM4",
      "merchantTransactionId": merchantTransactionId,
      "merchantUserId": merchantUserId,
      "amount": billingAmount + "00",
      "redirectUrl": `${appUrl}?payment=${merchantTransactionId}&merchantUserId=${merchantUserId}`,
      "redirectMode": "REDIRECT",
      "callbackUrl": "",
      "mobileNumber": phonenumber,
      "paymentInstrument": {
        "type": "PAY_PAGE"
      }
    };
    const payloadString = JSON.stringify(payload);
    const base64Payload = btoa(payloadString);
    const concatenatedString = base64Payload + '/pg/v1/pay' + "642259ee-bbef-40d0-82a2-43909afecaf1";
    const data = new TextEncoder().encode(concatenatedString);
    window.crypto.subtle.digest('SHA-256', data)
      .then(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        const axiosOptions = {
          method: 'GET',
          url: `${API_TEST}phonepe-payment?xVerify=${sha256Hash}&request=${base64Payload}`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          }
        };
        setPhonePeLoading(true)
        axios(axiosOptions)
          .then(response => {
            const { data, message, statusCode } = response?.data
            if (statusCode === 200) {
              if (data?.instrumentResponse?.redirectInfo?.url) {
                const newUrl = data?.instrumentResponse?.redirectInfo?.url;
                setPhonePeLoading(false)
                // window.location.replace(newUrl, false);
                window.location.href = newUrl;
              }
            } else {
              setPhonePeLoading(false)
              toast.error(message)
            }
          })
          .catch(error => {
            setPhonePeLoading(false)
            console.error(error);
          });
      })
      .catch(error => {
        setPhonePeLoading(false)
        console.error(error);
        setLoading(false);
      })
  };
  

  const onConfirmHandler = () => {
    setConfirmDisabled(true);
    let deliveryFee = getDeliveryFee();
    let packagingFee = getPackagingAmount(false);
    let gstFee = getGSTAmount(totalCost);
    let billingAmount = Math.round(
      totalCost + gstFee + deliveryFee + packagingFee
    );
    switch (paymentMode) {
      case "online":
        // if(user.referral.toLowerCase() === "test"){
        const orderType = isAreaServiceable ? "order-now" : "pre-launch";
        const alreadyPreOrdered =
          !!selectedOrder && Object.keys(selectedOrder).length !== 0; //check whether order was already in pre-order so use that data
        const totalCostBill = alreadyPreOrdered
          ? selectedOrder.totalCost
          : totalCost;
        let deliveryFee = getDeliveryFee();
        let packagingFee = alreadyPreOrdered
          ? selectedOrder.packagingFee
          : getPackagingAmount(false);
        let gstFee = getGSTAmount(totalCostBill);
        let billingAmount = Math.round(
          totalCostBill + gstFee + deliveryFee + packagingFee
        );
        const dishesList = alreadyPreOrdered
          ? selectedOrder?.bookedDishes
          : cartData;
        const cartDataClone = dishesList?.map((data) => {
          const { variationObj, variations, ...restDish } = data;
          const customizeObj =
            data?.customizeObj.filter((customize) => customize.text) || [];
          return {
            ...restDish,
            customizeObj: customizeObj,
            itemid: data?.variationObj?.itemid || data.itemid || "",
            variation_id: data?.variationObj?.variationid || "",
            variation_name: data?.variationObj?.name || "",
          };
        });
        const chefId = dishesList[0]?.chefId || dishesList[0]?.dish?.chefId;
        const kitchenId = chefList.find((a) => a._id === chefId)?.kitchenId;
        const kitchenInfo = kitchensList.find((a) => a._id === kitchenId);
        const pickup_details = {
          name: kitchenInfo.name,
          contact_number: kitchenInfo.phoneNumber,
          address: kitchenInfo.address,
          landmark: kitchenInfo.landmark,
        };
        const drop_details = {
          name: user.username,
          contact_number: user.phonenumber,
          address: selectedAddress.location.locationName,
          latitude: selectedAddress.location.lat,
          longitude: selectedAddress.location.lng,
        };
        const { discounttype: discount_type, discount: discount_total } =
          selectedDiscount;
        const checkoutData = {
          cartDataClone,
          user,
          email: "",
          address: selectedAddress,
          orderType: orderType,
          deliveryFee: deliveryFee,
          deliveryDistance: delivery_distance,
          deliveryTime: delivery_Time,
          totalCost: totalCost,
          packagingFee: packagingFee,
          gstFee: gstFee,
          instructionText: instructionText || "",
          chefAddress: kitchenLocation,
          scheduleTime: scheduleDateTime,
          billingAmount: billingAmount,
          paymentMode: paymentMode,
          paymentId: "",
          pickup_details: pickup_details,
          drop_details: drop_details,
          discount_type: discount_type,
          discount_total: discount_total,
        };

        if (
          !moment().isBefore(moment(scheduleDateTime)) &&
          moment(scheduleDateTime).isValid()
        ) {
          toast.error("Please select a valid date and time for schedule time");
          return;
        }
        localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
        handlePhonePeClick(billingAmount, user.phonenumber);
        // }else{
        //   const paymentId = uuidv4();
        // dispatch(getOpenPaymentToken(paymentId, billingAmount, user.phonenumber));
        // }

        break;
      case "cash":
        processOrder();
        break;
      case "upi":
        processOrder();
        break;
    }
  };

  const billItems = [
    {
      key: "1",
      label: (
        <div className="totalCostLabelWrapper Satoshi">
          <span className="totalCostLabelText">Item total: </span>
          <span className="totalCostLabelTextValue">₹{totalAmountCost}</span>
        </div>
      ),
      children: (
        <div>
          <div className="cartBillingPriceBoxWrapper Satoshi">
            <div className="cartBillingPriceItem">
              <div className="cartBillingPriceTitle">Item total</div>
              <div className="cartBillingPriceValue">
                &#8377; {parseInt(totalCost)}
              </div>
            </div>
            <div className="cartBillingPriceItem">
              <div className="cartBillingPriceTitle">Delivery fees</div>
              <div className="cartBillingPriceValue">
                &#8377; {getDeliveryFee()}
              </div>
            </div>
            <div className="cartBillingPriceItem">
              <div className="cartBillingPriceTitle">Packaging</div>
              <div className="cartBillingPriceValue">
                &#8377; {getPackagingAmount()}
              </div>
            </div>
            <div className="cartBillingPriceItem">
              <div className="cartBillingPriceTitle">GST</div>
              <div className="cartBillingPriceValue">
                &#8377; {getGSTAmount()}
              </div>
            </div>
          </div>
          <div className="cartBillingPriceItemTotal">
            <div className="cartBillingPriceTitleTotal">Grand total</div>
            <div className="cartBillingPriceValueTotal">
              &#8377; {totalAmountCost}
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <AppContainer>
      <div className="cart-box-container">
        <div className="cart-wrapper">
          <div className="cart-top-bar">
            <CartMenuTopBar
              name={
                isAreaServiceable
                  ? delivery_duration + preparationTime
                    ? deliveryInfoText
                    : "Setup location"
                  : "Pre-order"
              }
              isAreaServiceable={isAreaServiceable}
              notMenu={true}
              onBackClick={onBackClick}
              chefList={chefList}
              cartData={cartData}
              ref={ref}
            />
          </div>
          <section className="section-food-accordian">
            {cartData.length > 0 ? (
              <div className="cartDishWrapper">
                <div>
                  {cartData.map((dish, i) => {
                    return (
                      <React.Fragment key={dish._id + i}>
                        <Dish
                          key={dish._id + i}
                          id={dish._id}
                          orderPriceIds={orderPriceIds}
                          name={dish.name}
                          category={{ type: dish.categoryType }}
                          price={dish.price}
                          type={dish.type ? dish.type.toUpperCase() : "VEG"}
                          img={
                            dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""
                          }
                          mainImg={dish.img ? S3_MENU_FOLDER + dish.img : ""}
                          description={dish.description}
                          dish={dish}
                          calculateTotal={true}
                          isFromCart={true}
                          parentComponent="cart"
                          isScheduleActive={true}
                          unAvailable={dish.unavailable}
                          isPriceBandVisible={true}
                          isAddedOnDiscount={dish.isAddedOnDiscount}
                          isFromOrderSummary={false}
                          unAvailableChef={unAvailableChef}
                        />
                      </React.Fragment>
                    );
                  })}
                  <div className="addMoreItemContainer">
                    <div className="addMoreItemLabel Satoshi">
                      Add more items
                    </div>
                    <div className="addMoreItemAddCircleIcon">
                      <AddCircleIconSvg />
                    </div>
                  </div>
                  <div
                    style={{ marginBottom: 13 }}
                    className="addMoreItemContainer"
                  >
                    <textarea
                      value={instructionText}
                      onChange={(e) => {
                        setInstructionText(e.target.value);
                      }}
                      placeholder="Add cooking request"
                      className="cookingInputValue"
                    />
                    <div className="addMoreItemAddCircleIcon">
                      <AddCircleIconSvg />
                    </div>
                  </div>
                </div>
              </div>
            ) : !isAreaServiceable &&
              user.totalDishesBooked >= MAX_ORDER_DISHES ? (
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>Thank you for pre-ordering.</h5>
                  <p>Refer your friends to get 3 more dishes!</p>
                  <button onClick={() => showReferView()}>
                    <img src={browseLogo} alt="browseLogo" />
                    Refer
                  </button>
                </div>
              </div>
            ) : !isAreaServiceable && user.totalDishesBooked == 0 ? (
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>Pre-order now, Pay later!</h5>
                  <p>
                    Currently, no chefs are serving in your location.
                    <br />
                    You can pre-order 2 dishes now and schedule your delivery
                    when chefs start serving in your location or if you're
                    closer to Andheri East.
                  </p>

                  <button onClick={() => switchTab("chef")}>
                    <img src={browseLogo} alt="browseLogo" />
                    Browse Chefs
                  </button>
                </div>
              </div>
            ) : !isAreaServiceable && user.totalDishesBooked > 0 ? (
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>Pre-order now, Pay later!</h5>
                  <p>
                    You can pre-order {maxDishesAllowed} more dishes. <br />
                    Schedule your delivery when chefs are serving in your
                    location or if you're closer to Andheri East.
                  </p>
                  <button onClick={() => switchTab("chef")}>
                    <img src={browseLogo} alt="browseLogo" />
                    Browse Chefs
                  </button>
                </div>
              </div>
            ) : isAreaServiceable &&
              user.totalDishesBooked >= MAX_ORDER_DISHES ? (
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>Fine-dine dishes at local prices</h5>
                  <p>
                    Dish prices increase as their popularity increases. <br />
                    Refer friends to get 3 more dishes at deep discounts.
                  </p>
                  <button onClick={() => showReferView()}>
                    <img src={browseLogo} alt="browseLogo" />
                    Refer
                  </button>
                </div>
              </div>
            ) : (
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>First 2 dishes at 40-99% off!</h5>
                  <p>
                    Dish prices increase as their popularity increases. <br />
                    Since you discovered us early, get 40-99% on your fav
                    dishes.
                  </p>
                  <button onClick={() => switchTab("chef")}>
                    <img src={browseLogo} alt="browseLogo" />
                    Browse Chefs
                  </button>
                </div>
              </div>
            )}

            {cartData.length > 0 && <div className="cartDishWrapper">
              <Collapse
                ghost
                items={billItems}
                expandIconPosition="end"
                className="cartBillTotalExpand"
                expandIcon={({ isActive }) => (
                  <ArrowDownIconSvg rotate={!isActive ? 90 : 0} />
                )}
              />
            </div>}
          </section>
        </div>

        {isAreaServiceable && cartData.length != 0 ? (
          <>
            <div className="billing-section mx-2">
              {/* <div className="billing-title">Apply Coupon</div> */}
              {/* <div className="discount-container">
              {[...kitchenDiscount, {discounttype: "2", discountid :"111", discount: "20"}].map((discount) => {
                return (
                  <div className="item-price-discount-wrapper">
                    <div className="items-price items item-price-discount-container">
                      <div className="price-title">
                        {+discount.discounttype === 2 ? "Fixed discount" : "Percentage discount"}{" "}
                      </div>
                      <div className="price-value">
                        {" "}
                        &#8377; {discount.discount}{" "}
                      </div>
                      <Button
                        onClick={() =>{
                          const discountObj = {
                            discounttype: discount.discounttype,
                            discountid: discount.discountid,
                            discount: discount.discount,
                          }
                          setSelectedDiscount(discountObj)
                          dispatch(cartAction.setSelectedDiscount(discountObj))
                        }}
                        style={{
                          marginLeft: 6,
                          padding: "4px 6px",
                          backgroundColor: "#f8edd5",
                          color: "#670000",
                        }}
                        className="discount-applied-button"
                        disabled={
                          selectedDiscount?.discountid === discount.discountid
                        }
                      >
                        {selectedDiscount?.discountid === discount.discountid
                          ? "Applied"
                          : "Apply"}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div> */}

              {user.maxDishes == 2 &&
                user.totalDishesBooked < user.maxDishes && (
                  <div className="not-serviceble-badge">
                    <p>
                      You can order {user.maxDishes - user.totalDishesBooked}{" "}
                      dishes at discounted price.
                    </p>
                  </div>
                )}

              {!user_location ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      dispatch(cartAction.setInstructionText(instructionText));
                      switchTab("locationSelector");
                      dispatch(bottomBarActions.updateFrom({ from: "cart" }));
                      mixpanel.track("cart-setup-location-clicked");
                      // clevertap.event.push("cart-setup-location-clicked");
                    }}
                  >
                    <span>SETUP LOCATION</span>
                  </div>
                </div>
              ) : isAddressOptionsVisible ? (
                <div className="select-address_container">
                  {addresses.length ||
                  (!!selectedAddress &&
                    Object.keys(selectedAddress).length !== 0) ? (
                    <>
                      <div
                        className="add-addres"
                        onClick={() => {
                          dispatch(
                            cartAction.setInstructionText(instructionText)
                          );
                          // switchTab("addressForm");
                          handleOpenAddressModal()
                          mixpanel.track("add-address-clicked");
                          // clevertap.event.push("add-address-clicked");
                          dispatch(loginActions.setSelectedOrder(null));
                        }}
                      >
                        <span>ADD ADDRESS</span>
                      </div>
                      <div
                        className="select-address"
                        onClick={() => {
                          dispatch(
                            cartAction.setInstructionText(instructionText)
                          );
                          // switchTab("address");
                          handleOpenAddressModal()
                          mixpanel.track("select-address-clicked");
                          // clevertap.event.push("select-address-clicked");
                        }}
                      >
                        <span>SELECT ADDRESS</span>
                      </div>
                    </>
                  ) : (
                    <div
                      className="add-addres"
                      onClick={() => {
                        dispatch(
                          cartAction.setInstructionText(instructionText)
                        );
                        // switchTab("addressForm");
                        handleOpenAddressModal()
                        mixpanel.track("add-address-clicked");
                        // clevertap.event.push("add-address-clicked");
                      }}
                    >
                      <span>ADD ADDRESS</span>
                    </div>
                  )}
                </div>
              ) : ((unAvailable || hasDailySpecial) && isScheduleNow) ||
                isSwitchedOffChef ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      if (isSwitchedOffChef) {
                        switchTab("chef");
                        mixpanel.track("chef-unavailable-clicked");
                        // clevertap.event.push("chef-unavailable-clicked");
                      } else {
                        // switchTab("chef");
                        mixpanel.track("schedule-button-clicked");
                        // clevertap.event.push("schedule-button-clicked");
                      }
                    }}
                  >
                    {isSwitchedOffChef ? (
                      <span>Some Chefs are not online</span>
                    ) : (
                      <ScheduleOrderButton
                        chefsLatestTime={chefOpenTimeWithDelivery}
                        customButton={
                          <span>You can schedule an order here.</span>
                        }
                      />
                    )}
                  </div>
                </div>
              ) : totalCost < minimumAmount ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      dispatch(cartAction.setInstructionText(instructionText));
                      switchTab("chef");
                      mixpanel.track("chef-unavailable-clicked");
                      // clevertap.event.push("chef-unavailable-clicked");
                    }}
                  >
                    <span>Minimum amount should be {minimumAmount}</span>
                  </div>
                </div>
              ) : kitchenFull ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      switchTab("chef");
                      mixpanel.track("chef-unavailable-clicked");
                      // clevertap.event.push("chef-unavailable-clicked");
                    }}
                  >
                    <span>Kitchen full, try after some time.</span>
                  </div>
                </div>
              ) : uniq(allCartChefList.length) > 1 ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      switchTab("chef");
                      mixpanel.track("chef-unavailable-clicked");
                      // clevertap.event.push("chef-unavailable-clicked");
                    }}
                  >
                    <span>Please order from one restaurant at a time</span>
                  </div>
                </div>
              ) : (
                <CartPlaceOrderButton
                  totalAmountCost={totalAmountCost}
                  paymentMode={paymentMode}
                  setPaymentMode={setPaymentMode}
                  onConfirmHandler={onConfirmHandler}
                  disablePlaceOrderBtn={confirmDisabled || loading || phonePeLoading}
                />
              )}
              {farAwayModalOnCart && (
                <PopBenifits>
                  <div className="modal-top-section">
                    {/* <div onClick={() => setUserBenefits(false)} className="colse-btn">
                <img src={CloseIcon} alt="CloseIcon" />
              </div> */}
                  </div>
                  <EarlyUsersBoxWrapper>
                    <div className="BenefitsSkimsTitle">
                      <p>You are ordering from a far away location</p>
                    </div>
                    <BenefitsSkims>
                      <div className="SkimItem">
                        <img src="./images/true.png" alt="" />
                        <p>
                          This can affect food quality, temprature and
                          consistency.
                        </p>
                      </div>
                      <div className="SkimItem">
                        <img src="./images/true.png" alt="" />
                        <p>
                          We'll try our best to deliver the best but food always
                          tastes best when hot.
                        </p>
                      </div>
                    </BenefitsSkims>
                    <div className="button-box">
                      <a
                        className="btn-login"
                        onClick={() => {
                          dispatch(loginActions.setFarAwayModalOnCart(false));
                          dispatch(
                            cartAction.setInstructionText(instructionText)
                          );
                        }}
                      >
                        <span>Got it</span>
                      </a>
                    </div>
                  </EarlyUsersBoxWrapper>
                </PopBenifits>
              )}
            </div>
          </>
        ) : (
          cartData.length != 0 && (
            <>
              <div className="billing-section mx-2">
                <div className="billing-title">Billing Details</div>
                <div className="price-box">
                  <div className="items-price items__first">
                    <div className="price-title">Items Price</div>
                    <div className="price-value">
                      {" "}
                      &#8377; {parseInt(totalCost)}{" "}
                    </div>
                  </div>
                  <div className="items-price items">
                    <div className="price-title">Delivery Fees</div>
                    <div className="price-value"> &#8377; 0 </div>
                  </div>
                  <div className="items-price items">
                    <div className="price-title">Packaging</div>
                    <div className="price-value">
                      {" "}
                      &#8377; {getPackagingAmount()}{" "}
                    </div>
                  </div>
                  <div className="items-price items">
                    <div className="price-title">GST</div>
                    <div className="price-value">
                      {" "}
                      &#8377; {getGSTAmount()}{" "}
                    </div>
                  </div>
                  <div className="items-price items__total">
                    <div className="price-title">Total</div>
                    <div className="price-value">
                      {" "}
                      &#8377; {totalAmountCost}{" "}
                    </div>
                  </div>
                </div>
                {/* //TO-DO: Restriction - Remove first part for 2 dish restriction */}
                {false ? (
                  <>
                    <div className="not-serviceble-badge">
                      <p>
                        You can pre-book a maximum of {maxDishesAllowed} dishes
                      </p>
                    </div>
                    <div className="cart-option bottom-box" id="cart_option">
                      <div className="cart-option-detail pay-box">
                        <a
                          className="menu-link m-auto"
                          onClick={() => showReferView()}
                        >
                          <span>Refer to pre-order more dishes</span>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="not-serviceble-badge">
                      <p>
                        Your location is not serviceable but you can pre-order
                      </p>
                    </div>
                    <div className="pay-box bottom-box">
                      <div className="view-bill">
                        <div>&#8377; {totalAmountCost} </div>
                        <div>
                          <a
                            className="view-bill-link"
                            href="javacsript:void(0)"
                          >
                            Total amount
                          </a>
                        </div>
                      </div>
                      <div
                        className="pay-bill"
                        onClick={() => {
                          dispatch(
                            cartAction.setInstructionText(instructionText)
                          );
                          goToCheckout("checkout");
                        }}
                      >
                        <span>Pre-order now and Pay Later</span>
                      </div>
                      {unserviceableModalOnCart && (
                        <PopBenifits>
                          <div className="modal-top-section">
                            {/* <div onClick={() => setUserBenefits(false)} className="colse-btn">
                        <img src={CloseIcon} alt="CloseIcon" />
                      </div> */}
                          </div>
                          <EarlyUsersBoxWrapper>
                            <div className="BenefitsSkimsTitle">
                              <p>Your location is not in delivery range</p>
                            </div>
                            <BenefitsSkims>
                              <div className="SkimItem">
                                <img src="./images/true.png" alt="" />
                                <p>
                                  You can pre-book the order now, no payments
                                  required.
                                </p>
                              </div>
                              <div className="SkimItem">
                                <img src="./images/true.png" alt="" />
                                <p>
                                  When you're closer to Andheri East, you can
                                  schedule the booking
                                </p>
                              </div>
                              <div className="SkimItem">
                                <img src="./images/true.png" alt="" />
                                <p>
                                  Or wait for some chefs to setup shop near you!
                                </p>
                              </div>
                            </BenefitsSkims>
                            <div className="button-box">
                              <a
                                className="btn-login"
                                onClick={() =>
                                  dispatch(
                                    loginActions.setUnserviceableModalOnCart(
                                      false
                                    )
                                  )
                                }
                              >
                                <span>Got it</span>
                              </a>
                            </div>
                          </EarlyUsersBoxWrapper>
                        </PopBenifits>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )
        )}
        {cartData.length == 0 && <BottomBar />}
        <ToastContainer limit={1} />
      </div>
    </AppContainer>
  );
};
export default Cart;
