import * as React from "react";
const CallIconSvg = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6473 12.22C14.6473 12.46 14.594 12.7067 14.4806 12.9467C14.3673 13.1867 14.2207 13.4134 14.0273 13.6267C13.7007 13.9867 13.3407 14.2467 12.934 14.4134C12.534 14.58 12.1007 14.6667 11.634 14.6667C10.954 14.6667 10.2273 14.5067 9.46065 14.18C8.69398 13.8534 7.92732 13.4134 7.16732 12.86C6.40065 12.3 5.67398 11.68 4.98065 10.9934C4.29398 10.3 3.67398 9.57337 3.12065 8.81337C2.57398 8.05337 2.13398 7.29337 1.81398 6.54004C1.49398 5.78004 1.33398 5.05337 1.33398 4.36004C1.33398 3.90671 1.41398 3.47337 1.57398 3.07337C1.73398 2.66671 1.98732 2.29337 2.34065 1.96004C2.76732 1.54004 3.23398 1.33337 3.72732 1.33337C3.91398 1.33337 4.10065 1.37337 4.26732 1.45337C4.44065 1.53337 4.59398 1.65337 4.71398 1.82671L6.26065 4.00671C6.38065 4.17337 6.46732 4.32671 6.52732 4.47337C6.58732 4.61337 6.62065 4.75337 6.62065 4.88004C6.62065 5.04004 6.57398 5.20004 6.48065 5.35337C6.39398 5.50671 6.26732 5.66671 6.10732 5.82671L5.60065 6.35337C5.52732 6.42671 5.49398 6.51337 5.49398 6.62004C5.49398 6.67337 5.50065 6.72004 5.51398 6.77337C5.53398 6.82671 5.55398 6.86671 5.56732 6.90671C5.68732 7.12671 5.89398 7.41337 6.18732 7.76004C6.48732 8.10671 6.80732 8.46004 7.15398 8.81337C7.51398 9.16671 7.86065 9.49337 8.21398 9.79337C8.56065 10.0867 8.84732 10.2867 9.07398 10.4067C9.10732 10.42 9.14732 10.44 9.19398 10.46C9.24732 10.48 9.30065 10.4867 9.36065 10.4867C9.47398 10.4867 9.56065 10.4467 9.63398 10.3734L10.1407 9.87337C10.3073 9.70671 10.4673 9.58004 10.6207 9.50004C10.774 9.40671 10.9273 9.36004 11.094 9.36004C11.2207 9.36004 11.354 9.38671 11.5007 9.44671C11.6473 9.50671 11.8007 9.59337 11.9673 9.70671L14.174 11.2734C14.3473 11.3934 14.4673 11.5334 14.5406 11.7C14.6073 11.8667 14.6473 12.0334 14.6473 12.22Z"
      stroke="#851D1E"
      strokeMiterlimit={10}
    />
    <path
      d="M12.3343 6.00008C12.3343 5.60008 12.021 4.98675 11.5543 4.48675C11.1276 4.02675 10.561 3.66675 10.001 3.66675"
      stroke="#851D1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6676 6.00004C14.6676 3.42004 12.581 1.33337 10.001 1.33337"
      stroke="#851D1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CallIconSvg;
