import React, { useState } from "react";
import "./cartDishContainer.css";
import VegSvgIcon from "../../assets/ReactSvg/VegSvgIcon";
import EggSvgIcon from "../../assets/ReactSvg/EggSvgIcon";
import NonVegSvgIcon from "../../assets/ReactSvg/NonVegSvgIcon";
import MinusIconSvg from "../../assets/ReactSvg/MinusIconSvg";
import AddIconSvg from "../../assets/ReactSvg/AddIconSvg";
import { useSelector } from "react-redux";
import { uniq } from "lodash";
import { toast } from "react-toastify";

const CartDishContainer = ({ dish, onRemoveDish, setShowAdd, setShowAdded, onAddDish }) => {
  const {
    type,
    name,
    dealPrice,
    unAvailable,
    portion,
    customizeObj,
    variations,
    cartId,
    chefId
  } = dish;

  const chefList = useSelector((state) => state.chef.chefList);
  const { cartData } = useSelector(({ cart }) => cart);
  const cartChefIds = cartData.map((a) => a.chefId);

  const allCartChefList =
    chefList
      ?.filter((a) => cartChefIds.includes(a._id))
      ?.map((c) => c.kitchenId) || [];

  const dishTypeIcon = (type) => {
    return (
      <React.Fragment style={{ marginTop: 3 }}>
        {type?.toLowerCase() === "VEG".toLowerCase() && <VegSvgIcon />}
        {type?.toLowerCase() === "EGG".toLowerCase() && <EggSvgIcon />}
        {type?.toLowerCase() === "NON VEG".toLowerCase() && <NonVegSvgIcon />}
      </React.Fragment>
    );
  };

  const onRemoveDishClick = () =>{
    onRemoveDish(
      dish.id ||dish._id,
      setShowAdd,
      setShowAdded,
      portion,
      cartId
    );
  }

  const handleAddToCartClick = () => {
    const currentChef = chefList.find((chef) => chef._id === chefId);
    if (
      [...uniq(allCartChefList)].length &&
      ![...uniq(allCartChefList)].includes(currentChef.kitchenId)
    ) {
      //already added chef kitchen location
      toast.error("Please order from one restaurant at a time");
    } else {
      onAddDish(dish._id, 0, [], cartId);
    }
  };

  const isValidCustomizeObj = !!customizeObj?.length;
  const isValidVariations =
    !!variations?.groupName && !!variations?.options?.length;
  const customizeValue = customizeObj
    .map((c) => c.text)
    .filter((c) => c)
    .join(" and ");
  const customizeText = isValidCustomizeObj ? `with ${customizeValue}` : "";

  return (
    <div className="cartDishBoxWrapper">
      <div className="cartDishBoxLeftContainer">
        {dishTypeIcon(type)}
        <div className="cartDishNameLabel Satoshi">
          <div className="cartDishNameLabelContent Satoshi">
            {name + customizeText}
          </div>
          {(isValidCustomizeObj || isValidVariations) && (
            <div className="cartDishNameLabelCustomisable Satoshi">
              Customisable
            </div>
          )}
        </div>
      </div>
      <div className="cartDishBoxRightContainer">
        <div className="cartPortionEditBtn">
          <button
            className="dishCartAddToCartBtn transparentBtn"
            onClick={onRemoveDishClick}
            disabled={unAvailable}
          >
            <MinusIconSvg />
          </button>
          <div className="cartTotalCartCountBtnLabel Satoshi">{portion}</div>
          <button
            className="dishCartRemoveToCartBtn transparentBtn"
            onClick={() => handleAddToCartClick(dish.id)}
            disabled={unAvailable}
          >
            <AddIconSvg />
          </button>
        </div>
        <div className="cartDishPriceLabel Satoshi">₹{dealPrice}</div>
      </div>
    </div>
  );
};

export default CartDishContainer;
