import * as React from "react";

const SearchSVGComponent = ({ size = 20, fill = "#851D1E", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.58268 17.5001C13.9549 17.5001 17.4993 13.9557 17.4993 9.58341C17.4993 5.21116 13.9549 1.66675 9.58268 1.66675C5.21043 1.66675 1.66602 5.21116 1.66602 9.58341C1.66602 13.9557 5.21043 17.5001 9.58268 17.5001Z"
      stroke={fill}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3327 18.3334L16.666 16.6667"
      stroke={fill}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchSVGComponent;
