import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import { dateFormatter, timeFormatter } from "../util/DateTimeFormatter";
import "./order-history.css";
import { getFoodTypeIcon, getOrderStatusLabel } from "../../utils/helper";
import ExpandIconSvg from "../../assets/ReactSvg/ExpandIconSvg";
import GoIconSvg from "../../assets/ReactSvg/GoIconSvg";
import VegSvgIcon from "../../assets/ReactSvg/VegSvgIcon";
import EggSvgIcon from "../../assets/ReactSvg/EggSvgIcon";
import NonVegSvgIcon from "../../assets/ReactSvg/NonVegSvgIcon";
import RightArrowSvg from "../../assets/ReactSvg/RightArrowSvg";
import ReOrderSvg from "../../assets/ReactSvg/ReOrderSvg";
import moment from "moment";
import { menuActions } from "../../store/menuSlice";
import { useEffect } from "react";
import { fetchChefList } from "../../store/chefActions";
import { ToastContainer, toast } from "react-toastify";
import { addDish } from "../../store/cartActions";
import mixpanel from "mixpanel-browser";

const GST_RATE = 5;

const OrderHistory = ({ data, isSchedule }) => {
  const chefList = useSelector((state) => state.chef.chefList);
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const userid = useSelector((state) => state.auth.user.userid);
  const dispatch = useDispatch();
  // const {
  //   cartData,
  //   totalCost,
  // } = useSelector(({ cart }) => cart);
  // const switchTab = (tabName) => {
  //   dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  // };
  // const selectedOrder = useSelector((state) => state.auth.selectedOrder);
  // const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  // const chefList = useSelector((state) => state.chef.chefList);
  // const deliveryDetails = useSelector(
  //   ({ auth }) => auth.deliveryDetails
  // );
  // const cartChefIds = cartData.map((a) => a.chefId);
  // const cartChefAddedKitchenId = chefList.find((a) => cartChefIds.includes(a._id))?.kitchenId;
  // const deliveryOptions = useSelector((state) => state.auth.availableDeliveryOptions);
  // const chefDeliveryDetails = deliveryDetails[cartChefAddedKitchenId] || {};
  // const delivery_distance = chefDeliveryDetails?.delivery_distance;
  const onOrderHistoryClick = () => {
    dispatch(loginActions.setSingleOrderHistoryDetails(data));
    dispatch(bottomBarActions.updateFrom({ from: "orderHistory" }));
    dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }))
  }

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    mixpanel.track("tab-clicked-" + tabName);
    // clevertap.event.push("tab-clicked-" + tabName);
  };

  // const { createdAt, status, reviewType, reviewText } = data;
  // const reviewTypeString =
  //   reviewType?.charAt(0) + reviewType?.slice(1).toLowerCase();
  // let orderedDate = new Date(createdAt);
  // let totalPrice = 0;
  // let orderedDishes = "";
  // let chefDetails = "";
  // data.bookedDishes.forEach((dishes, i) => {
  //   dishes.isAddedOnDiscount || data.preOrdered
  //     ? (totalPrice += dishes.dealPrice)
  //     : (totalPrice += dishes.price);
  //   if (data.bookedDishes.length == 1 || i == data.bookedDishes.length - 1) {
  //     if (dishes.dish) {
  //       orderedDishes += dishes.dish.name;
  //       if (!chefDetails.includes(dishes.dish.chefName)) {
  //         chefDetails += "Chef " + dishes.dish.chefName + ".";
  //       }
  //     }
  //   } else {
  //     if (dishes.dish) {
  //       orderedDishes += dishes.dish.name + ", ";
  //       if (!chefDetails.includes(dishes.dish.chefName)) {
  //         chefDetails += "Chef " + dishes.dish.chefName + ", ";
  //       }
  //     }
  //   }
  // });

  // const onReviewLinkClick = () => {
  //   if(reviewType) return
  //   dispatch(
  //     loginActions.setOrderReviewWriteInfo({
  //       orderReviewWriteInfo: { ...data, chefName: chefDetails },
  //     })
  //   );
  //   dispatch(bottomBarActions.loadTab({ tabName: "orderReviewWrite" }));
  // };

  // const getReviewColor = () => {
  //   switch (reviewType) {
  //     case "YES":
  //       return "green";
  //     case "OTHER":
  //       return "#dd9d4a";
  //     case "NO":
  //       return "red";
  //   }
  // };

  // const getDeliveryFee = () => {
  //   const pidgeDeliveryOptions = deliveryOptions?.PIDGE || [];
  //   const porterDeliveryOptions = deliveryOptions?.PORTER || [];

  //   if (deliveryOptions?.SHADOWFAX?.is_serviceable) {
  //     // SHADOWFAX delivery available
  //     dispatch(loginActions.setSelectedDeliveryPortal("SHADOWFAX"));
  //     return parseInt(deliveryOptions?.SHADOWFAX.total_amount, 10);
  //   }

  //   if (delivery_distance && isAreaServiceable && porterDeliveryOptions.length) {
  //     // PORTER delivery available
  //     dispatch(loginActions.setSelectedDeliveryPortal("PORTER"));
  //     const number = porterDeliveryOptions[0]?.fare?.minor_amount;
  //     return parseInt((number / 100).toFixed(2), 10);
  //   }

  //   if (delivery_distance && isAreaServiceable && pidgeDeliveryOptions.length) {
  //     // PIDGE delivery available
  //     dispatch(loginActions.setSelectedDeliveryPortal("PIDGE"));
  //     const lowestPriceNetwork = pidgeDeliveryOptions.reduce((lowest, current) => {
  //       return current?.quote?.price < lowest?.quote?.price ? current : lowest;
  //     })?.quote?.price;
  //     return parseInt(lowestPriceNetwork, 10);
  //   }

  //   dispatch(loginActions.setSelectedDeliveryPortal(""));
  //   return 0;
  // };

  // const getGSTAmount = (total) => {
  //   // Calculate 5% GST
  //   return (parseInt(total) * GST_RATE) / 100;
  // };

  // const getPackagingAmount = (schedule) => {
  //   let packagingFee = 0;
  //   if (schedule) {
  //     selectedOrder.bookedDishes.forEach((cart) => {
  //       if (cart.isAddedOnDiscount) {
  //         packagingFee = packagingFee + cart.dish.packagingCost;
  //       }
  //     });
  //   } else {
  //     cartData.forEach((cart) => {
  //       if (cart.isAddedOnDiscount) {
  //         packagingFee = packagingFee + cart.packagingCost;
  //       }
  //     });
  //   }
  //   return packagingFee;
  // };

  // const billingAmount = Math.round(data.totalCost + data.gstFee + data.packagingFee)

  function transformDateWithMoment(dateString) {
    return `Order placed on ${moment(dateString).format("DD MMM, YYYY")}`;
  }

  function isPaymentSuccessful(order) {
    const { paymentMode, paymentId } = order;

    if (paymentMode === "cash" || paymentMode === "upi") {
      return true;
    }

    if (paymentMode === "online") {
      return !!paymentId;
    }

    return false; // Default to false for other cases
  }

  const chefId = data?.bookedDishes[0].dish?.chefId;
  const chefInfo = chefList.find((c) => c._id === chefId);
  const kitchenInfo = kitchensList.find(
    (kitchen) => kitchen?._id === chefInfo?.kitchenId
  );

  const transformCartData = (cartObj) => {
    const {
      dishId,
      price,
      dealPrice,
      customizeObj,
      portion,
      dish,
      variationObj
    } = cartObj;
    
    const cartData = {
      cartPayload: {
        userId: userid, 
        dishId,
        dealPrice,
        customizeObj,
        variationObj: variationObj || {}, 
        isAddedOnDiscount: cartObj.isAddedOnDiscount || false,
        price,
        portion,
      },
      dish: {
        ...dish,
        portion
      },
      maxDishes: 3,
      isFromCart: false,
      actionType: "ADD",
    };
  
    return cartData;
  };

  const handleReorderClick = () =>{
    const bookedDishes = data.bookedDishes;
    bookedDishes.map((dish) =>{
      const cartValue = transformCartData(dish)
      dispatch(addDish(cartValue));
    })
    switchTab("cart");
  }

  const onChefClick = () => {
    if(!chefInfo){
      toast.error("currently chef info not available")
      return
    }
    const chefId = chefInfo._id;
    dispatch(menuActions.setCurrentChefIdClicked(chefId));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
  };

  return (
    <div className="order-card Satoshi">
      {/* Header Section */}
      <div className="order-card-header">
        <div className="order-card-header-user">
          <img
            src={data.bookedDishes[0].dish.chefImg}
            alt={data.bookedDishes[0].dish.chefName}
            className="order-card-avatar"
          />
          <div className="order-card-user-details">
            <div className="order-card-user-name Satoshi">
              {data.bookedDishes[0].dish.chefName}
            </div>
            <p className="order-card-user-location Satoshi">
              {kitchenInfo?.address
                ? kitchenInfo?.address.length >= 40
                  ? kitchenInfo?.address.slice(0, 40) + "..."
                  : kitchenInfo?.address
                : ""}
            </p>
          </div>
        </div>
        <button onClick={onChefClick} className="order-card-view-menu Satoshi transparentBtn">
          View menu <GoIconSvg />
        </button>
      </div>

      <div className="order-card-items">
        {data.bookedDishes.map((item, index) => (
          <div key={index} className="order-card-item Satoshi">
            {getFoodTypeIcon(item.dish.type)}
            <span>{item.portion} x {item.dish.name}</span>
          </div>
        ))}
      </div>

      <div className="order-card-info" onClick={onOrderHistoryClick}>
        <p className="order-card-date Satoshi">
          {transformDateWithMoment(data.createdAt)}
        </p>
        <button className="order-card-price Satoshi transparentBtn" onClick={onOrderHistoryClick}>
          <span>₹{data.billingAmount}</span>
          <RightArrowSvg />
        </button>
      </div>

      <div className="order-card-footer">
        {!isPaymentSuccessful(data) && (
          <p className="order-card-payment-error Satoshi">
            <span className="order-card-payment-error-icon Satoshi">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00065 1.33331C4.32732 1.33331 1.33398 4.32665 1.33398 7.99998C1.33398 11.6733 4.32732 14.6666 8.00065 14.6666C11.674 14.6666 14.6673 11.6733 14.6673 7.99998C14.6673 4.32665 11.674 1.33331 8.00065 1.33331ZM7.50065 5.33331C7.50065 5.05998 7.72732 4.83331 8.00065 4.83331C8.27398 4.83331 8.50065 5.05998 8.50065 5.33331V8.66665C8.50065 8.93998 8.27398 9.16665 8.00065 9.16665C7.72732 9.16665 7.50065 8.93998 7.50065 8.66665V5.33331ZM8.61398 10.92C8.58065 11.0066 8.53398 11.0733 8.47398 11.14C8.40732 11.2 8.33398 11.2466 8.25398 11.28C8.17398 11.3133 8.08732 11.3333 8.00065 11.3333C7.91398 11.3333 7.82732 11.3133 7.74732 11.28C7.66732 11.2466 7.59398 11.2 7.52732 11.14C7.46732 11.0733 7.42065 11.0066 7.38732 10.92C7.35398 10.84 7.33398 10.7533 7.33398 10.6666C7.33398 10.58 7.35398 10.4933 7.38732 10.4133C7.42065 10.3333 7.46732 10.26 7.52732 10.1933C7.59398 10.1333 7.66732 10.0866 7.74732 10.0533C7.90732 9.98665 8.09398 9.98665 8.25398 10.0533C8.33398 10.0866 8.40732 10.1333 8.47398 10.1933C8.53398 10.26 8.58065 10.3333 8.61398 10.4133C8.64732 10.4933 8.66732 10.58 8.66732 10.6666C8.66732 10.7533 8.64732 10.84 8.61398 10.92Z"
                  fill="#EE1616"
                />
              </svg>
            </span>{" "}
            Payment failed!
          </p>
        )}
        {/* Reorder Button */}
        <button className="order-card-reorder-btn Satoshi" onClick={handleReorderClick}>
          <span className="order-card-reorder-icon">
            <ReOrderSvg />
          </span>{" "}
          Reorder
        </button>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default OrderHistory;
