
import * as React from "react";
const CloseMaroonIconSvg = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 9L9 3"
      stroke="#851D1E"
      strokeWidth={0.857143}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9L3 3"
      stroke="#851D1E"
      strokeWidth={0.857143}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CloseMaroonIconSvg;
