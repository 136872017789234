import * as React from "react";

const HomeSvgIcon = ({ size = 20, fill = "#181C22", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3577 6.6751L11.8993 2.30843C10.8327 1.45843 9.16598 1.4501 8.10765 2.3001L2.64932 6.6751C1.86598 7.3001 1.39098 8.5501 1.55765 9.53343L2.60765 15.8168C2.84932 17.2251 4.15765 18.3334 5.58265 18.3334H14.416C15.8243 18.3334 17.1577 17.2001 17.3993 15.8084L18.4493 9.5251C18.5993 8.5501 18.1243 7.3001 17.3577 6.6751ZM10.6243 15.0001C10.6243 15.3418 10.341 15.6251 9.99932 15.6251C9.65765 15.6251 9.37432 15.3418 9.37432 15.0001V12.5001C9.37432 12.1584 9.65765 11.8751 9.99932 11.8751C10.341 11.8751 10.6243 12.1584 10.6243 12.5001V15.0001Z"
      fill={fill}
    />
  </svg>
);

export default HomeSvgIcon;
