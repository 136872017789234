import * as React from "react";
const VolumeSlashSvgIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.9999 13.9584C14.8666 13.9584 14.7416 13.9167 14.6249 13.8334C14.3499 13.625 14.2916 13.2334 14.4999 12.9167C15.5499 11.5584 15.7749 9.7 15.0999 8.09168C14.9666 7.77501 15.1166 7.41668 15.4333 7.27501C15.7499 7.13334 16.1083 7.33334 16.2499 7.65001C17.0999 9.62501 16.8083 11.9667 15.4999 13.7167C15.3749 13.875 15.1916 13.9584 14.9999 13.9584Z" fill="#404548"/>
  <path d="M16.5249 16.0417C16.3999 16.0417 16.2749 16 16.1499 15.9167C15.8749 15.7084 15.8166 15.3167 16.0249 15C17.8083 12.6667 18.1999 9.48334 17.0499 6.74168C16.9166 6.42501 17.0666 6.06668 17.3833 5.92501C17.6999 5.78334 18.0583 5.98334 18.1999 6.30001C19.5249 9.41668 19.0749 12.9584 16.5249 16C16.3999 16.1584 16.2166 16.0417 16.5249 16.0417Z" fill="#404548"/>
  <path d="M11.6999 10.8C12.2249 10.275 13.125 10.65 13.125 11.3917V13.8334C13.125 15.2667 12.6083 16.3417 11.6833 16.8334C11.3083 17.0417 10.8916 17.1667 10.4583 17.1667C9.79159 17.1667 9.07491 16.9417 8.34158 16.4834L7.80825 16.15C7.35825 15.8667 7.28325 15.2334 7.65825 14.8334L11.6999 10.8Z" fill="#404548"/>
  <path d="M18.1416 1.85834C17.8916 1.60834 17.4833 1.60834 17.2333 1.85834L13.1083 5.98334C13.0583 4.65 12.5583 3.65 11.6749 3.15834C10.7416 2.64168 9.54991 2.77501 8.33325 3.53334L5.90825 5.05C5.74158 5.15 5.54991 5.20834 5.35825 5.20834H4.58325H4.16658C2.14991 5.20834 0.833252 6.52501 0.833252 8.54168V11.6667C0.833252 13.6833 2.14991 15 4.16658 15H4.29991L1.84991 17.45C1.59991 17.7 1.59991 18.1083 1.84991 18.3583C1.99991 18.5083 2.19158 18.5833 2.38325 18.5833C2.57491 18.5833 2.76658 18.5083 2.91658 18.3583L18.1416 3.13334C18.3916 2.88334 18.3916 2.47501 18.1416 2.22501Z" fill="#404548"/>
</svg>

);
export default VolumeSlashSvgIcon;

