import * as React from "react";
const StarFourIconSvg = (props) => (
  <svg
    width={8}
    height={9}
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.44184 0.915229C3.6416 0.408742 4.3584 0.408742 4.55816 0.915229L5.31861 2.84337C5.37959 2.998 5.502 3.12041 5.65663 3.18139L7.58477 3.94184C8.09126 4.1416 8.09126 4.8584 7.58477 5.05816L5.65663 5.81861C5.502 5.87959 5.37959 6.002 5.31861 6.15663L4.55816 8.08477C4.3584 8.59126 3.6416 8.59126 3.44184 8.08477L2.68139 6.15663C2.62041 6.002 2.498 5.87959 2.34337 5.81861L0.415229 5.05816C-0.0912583 4.8584 -0.0912581 4.1416 0.415229 3.94184L2.34337 3.18139C2.498 3.12041 2.62041 2.998 2.68139 2.84337L3.44184 0.915229Z"
      fill="#676C6F"
    />
  </svg>
);
export default StarFourIconSvg;
