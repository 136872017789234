import React from "react";
import { useDispatch } from "react-redux";
import "./location-icon.css";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
// import clevertap from "clevertap-web-sdk";

const LocationIcon = () => {
  const dispatch = useDispatch();
  const switchTab = (tabName) => {
    console.log("loc-click");
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    mixpanel.track("location-clicked");
    // clevertap.event.push("location-clicked");
  };
  return (
    <li
      className="select-location-img"
      onClick={() => switchTab("locationSelector")}
    >
      <span className="span-loc-img">
        <img src="images/location1.png" alt="location" />
      </span>
    </li>
    // <li className={styles["select-location-img"]}>
    //   <span className={styles["span-loc-img"]}>
    //     <img src="images/location.svg" alt="location" />
    //   </span>
    // </li>
  );
};

export default LocationIcon;
