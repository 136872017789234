import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { cartAction } from "../../store/cartSlice";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import "./address.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useEffect, useState } from "react";
import { API_TEST, FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN, PIDGE_API_TOKEN, PIDGE_API_URL, YOUR_GOOGLE_MAPS_API_KEY } from "../../store/constants";
import { loginActions } from "../../store/loginSlice";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import Script from "react-load-script";
import { useRef } from "react";
import { uniqBy } from "lodash";
import axios from "axios";
import { extractPincode } from "../../utils/helper";
// import clevertap from "clevertap-web-sdk";

const Address = () => {
  const chefList = useSelector((state) => state.chef.chefList);

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/home" });
    ReactGA.event({
      category: "tab",
      action: "address",
    });

    mixpanel.track("select-address");
    // clevertap.event.push("select-address");
  }, []);

  const dispatch = useDispatch();
  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("cart");
  };

  const [selectedAddress, setSelectedAddress] = useState({});

  const user = useSelector((state) => state.auth.user);
  const addresses = user.addressLine1;
  const distanceMatrixRef = useRef(null);

  const checkDeliveryServiceable = async (requestBody) => {
    try {
      const token = PIDGE_API_TOKEN ; 
      const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/quote`;
  
      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
  
      const response = await axios.post(apiUrl, requestBody, config);
      const deliveryOptions = response.data.data.items.filter(response =>
        response.pickup_now &&
        response.quote.eta.pickup &&
        response.quote.eta.drop
      );
      
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PIDGE", payload: deliveryOptions} ));
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PIDGE", payload: []}));
    }
  };

  const checkShadowfaxDeliveryServiceable = async (requestBody) => {
    try {
      const apiUrl = `${API_TEST}shadowfax/serviceability`;
      const response = await axios.post(apiUrl, requestBody);
      const deliveryValue = response.data;
      if(deliveryValue.is_serviceable){
        dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: deliveryValue} ));
      }else{
        dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: {}} ));
      }
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: {}} ));
    }
  };

  const checkPorterDeliveryServiceable = async (porterBody) => {
    try {
      const apiUrl = `${API_TEST}porter/orders/get_quote`;
  
      const response = await axios.post(apiUrl, porterBody);
      const deliveryOptions = response.data.data.vehicles.filter(response =>
        response.type === "2 Wheeler"
      );;
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PORTER", payload: deliveryOptions} ));
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PORTER", payload: []}));
    }
  };

  const onSelectAddressHandler = (data) => {
    const uniqKitchenData = uniqBy(chefList, obj => obj.kitchenId);
    uniqKitchenData.filter((a) => a.kitchenId).map((kitchen) =>{
      const kitchenId = kitchen.kitchenId;
      const chefLocation = kitchen.chefLocation;
      distanceMatrixRef.current = new window.google.maps.DistanceMatrixService();
      const chefLocationObj = {lat : +chefLocation?.lat, lng: +chefLocation?.lng};
      distanceMatrixRef.current.getDistanceMatrix(
        {
          origins: [chefLocationObj],
          destinations: [
            {
              lat: data?.location?.lat,
              lng: data?.location?.lng,
            },
          ],
          travelMode: "DRIVING",
        },
        (res, status) => {
          if (status !== "OK") {
            console.log("Unable to fetch...");
          } else {
            let delivery_distance = res.rows[0].elements[0].distance.value;
            let delivery_duration = res.rows[0].elements[0].duration;
            let deliveryObject = {
              delivery_distance,
              delivery_duration,
              kitchenLocation: chefLocation
            };
            
            dispatch(loginActions.setDeliveryDetails(deliveryObject));
            dispatch(loginActions.setDeliveryDetails({kitchenId, payload: deliveryObject}));
            let deliveryDistanceInKMS = Math.round(delivery_distance / 1000);
           
            const requestBody = {
              "pickup": {
                "coordinates": {
                  "latitude": chefLocation.lat,
                  "longitude": chefLocation.lng
                },
                "pincode": extractPincode(res.originAddresses[0])
              },
              "drop": [
                {
                  "ref": "4324324",
                  "location": {
                    "coordinates": {
                      "latitude": data?.location?.lat,
                      "longitude": data?.location?.lng
                    },
                    "pincode": extractPincode(res.destinationAddresses[0])
                  },
                  "attributes": {
                    "cod_amount": 0,
                    "weight": 500,
                    "volumetric_weight": 225
                  }
                }
              ]
            }
            const porterBody = {
              "pickup_details": {
                "lat": chefLocation.lat,
                "lng": chefLocation.lng
              },
              "drop_details": {
                "lat": data?.location?.lat,
                "lng": data?.location?.lng
              },
              "customer": {
                "name": "Ashwin",
                "mobile": {
                  "country_code": "+91",
                  "number": "9664257767"
                }
              }
            }

            const shadowfaxrequestBody = {
              pickup_details: {
                building_name: "",
                latitude: chefLocation.lat,
                longitude: chefLocation.lng,
                address: res.originAddresses[0]
              },
              drop_details: {
                building_name: "",
                latitude: data?.location?.lat,
                longitude: data?.location?.lng,
                address: res.destinationAddresses[0]
              }
            };
            
            checkDeliveryServiceable(requestBody)
            checkShadowfaxDeliveryServiceable(shadowfaxrequestBody)
            checkPorterDeliveryServiceable(porterBody)
            if (deliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(false));
              dispatch(loginActions.setUnserviceableModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(0));
            } else if (deliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN && 
              deliveryDistanceInKMS < ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setFarAwayModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
            } else if (deliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE && 
              deliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
                dispatch(loginActions.setIsServiceable(true));
                dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
            } else {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setMinimumAmount(0));
            }
            dispatch(loginActions.setSelectedAddress(data));
            dispatch(cartAction.setIsAddressOptionsVisible(false));
            switchTab("cart");
          }
        }
      );
    })

    
  };

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${YOUR_GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={onSelectAddressHandler}
      />
      <AppContainer>
        <div className="address-wrapper">
          <div className="address-top-bar">
            <MenuTopBar
              name="Select Delivery Address"
              notMenu={true}
              onBackClick={onBackClick}
            />
          </div>
          <div className="address-card_wrapper">
            {addresses.length
              ? addresses.map((data) => {
                  return (
                    <>
                      <div
                        key={data._id}
                        className="address_card"
                        onClick={() => {
                          onSelectAddressHandler(data);
                          setSelectedAddress(data);
                        }}
                      >
                        <div className="tag_container">
                          <h6>{data.tag}</h6>
                          <LocationOnIcon
                            className={
                              selectedAddress?._id == data._id ? "selected" : ""
                            }
                          />
                        </div>
                        <p style={{ width: "90%" }}>
                          {data.location.locationName}
                        </p>
                      </div>
                    </>
                  );
                })
              : "No Saved Addresses"}
          </div>

          <div className="select_btn">
            {!addresses.length && (
              // (
              //   <button
              //     onClick={() => {
              //       dispatch(cartAction.setIsAddressOptionsVisible(false));
              //       switchTab("cart");
              //     }}
              //     className="address-form_actionbtn"
              //   >
              //     CONTINUE
              //   </button>
              // ) :

              <button
                onClick={() => {
                  dispatch(cartAction.setIsAddressOptionsVisible(false));
                  switchTab("addressForm");
                }}
                className="address-form_actionbtn"
              >
                ADD ONE
              </button>
            )}
          </div>
        </div>
      </AppContainer>
    </>
  );
};

export default Address;
