import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import CloseModalIcon from "../../assets/ReactSvg/CloseModalIcon";
import { getLocationIcon } from "../../utils/helper";
import "./SaveAddressBottomModal.css";
import { TextField } from "@material-ui/core";
import classNames from "classnames";
import { Input } from "antd";

const SaveAddressBottomModal = ({
  isOpen,
  setOpen,
  setPinMapScreenVisible,
  editAddress,
  setEditAddress,
}) => {
  const [editAddressLocal, setEditAddressLocal] = useState({});
  const { houseNumber, area, landMark, tag } = editAddressLocal || {};
  const [addressType, setAddressType] = useState(tag || "home");

  useState(() => {
    if (isOpen) {
      setEditAddressLocal(editAddress);
      setAddressType(editAddress?.tag || "home");
    } else {
      setEditAddressLocal({});
    }
  }, [isOpen, editAddress]);

  const handleChangeAddress = (e) => {
    const { value, id } = e.target;
    setEditAddressLocal({ ...editAddressLocal, [id]: value });
    if (id === "tag") {
      setAddressType(value);
    }
  };

  const onSaveClick = () => {
    setEditAddress(editAddressLocal);
    setOpen(false);
  };

  const formattedAddress = `
  ${editAddressLocal?.houseNumber || ""}
  ${editAddressLocal?.area ? `, ${editAddressLocal.area}` : ""}
  ${editAddressLocal?.landMark ? `, ${editAddressLocal.landMark}` : ""}
  ${
    editAddressLocal?.location?.locationName
      ? `, ${editAddressLocal.location.locationName}`
      : ""
  }
`.trim();

  return (
    <Sheet
      springConfig={{
        stiffness: 300,
        damping: 30,
        mass: 0.2,
      }}
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      detent="content-height"
      className="install-modal-popup customReactSheet filter-modal-popup saveAddressPopup"
    >
      <Sheet.Container>
        <Sheet.Content>
          <div
            onClick={() => {
              setOpen(false);
            }}
            className="closeIconInstallModal"
          >
            <CloseModalIcon />
          </div>
          <div className="edit-location-modal-wrapper">
            <div className="confirmLocationBottomHeaderPopup saveLocationHeaderPopup">
              <div style={{ display: "inline-flex" }}>
                <span style={{ marginTop: 2 }}>
                  {getLocationIcon(editAddressLocal?.tag)}
                </span>
                <div className="confirmLocationPopupDescWrapper">
                  <div className="confirmLocationPopupTag Satoshi">
                    {editAddressLocal?.tag}
                  </div>
                  <div>
                    {formattedAddress.length >= 70
                      ? `${formattedAddress.slice(0, 70)}...`
                      : formattedAddress}
                  </div>
                </div>
              </div>
              <button className="transparentBtn changeLocationBtn Satoshi">
                Change
              </button>
            </div>
            <div className="saveAddressFieldWrapper">
              <TextField
                id="houseNumber"
                label="House / Flat / Floor no.*"
                className="saveAddressTextInputField Satoshi"
                value={houseNumber}
                onChange={handleChangeAddress}
              />
              <TextField
                id="area"
                label="Apartment / Road / Area*"
                className="saveAddressTextInputField Satoshi"
                value={area}
                onChange={handleChangeAddress}
              />
              <TextField
                id="landMark"
                label="Nearby landmark (optional)*"
                className="saveAddressTextInputField Satoshi"
                value={landMark}
                onChange={handleChangeAddress}
              />
            </div>
            <div className="saveAddressIconsWrapper">
              <div className="saveAddressIconsLabel Satoshi">
                Save address as*
              </div>
              <div className="saveAddressIconsButtonContainer">
                <button
                  onClick={() => setAddressType("home")}
                  className={classNames("transparentBtn", "Satoshi", {
                    saveAddressIconsActiveButton: addressType === "home",
                  })}
                >
                  {getLocationIcon(
                    "home",
                    13,
                    addressType === "home" ? "#851D1E" : "#404548"
                  )}
                  Home
                </button>
                <button
                  onClick={() => setAddressType("work")}
                  className={classNames("transparentBtn", "Satoshi", {
                    saveAddressIconsActiveButton: addressType === "work",
                  })}
                >
                  {getLocationIcon(
                    "work",
                    13,
                    addressType === "work" ? "#851D1E" : "#404548"
                  )}
                  Work
                </button>
                <button
                  onClick={() => setAddressType("")}
                  className={classNames("transparentBtn", "Satoshi", {
                    saveAddressIconsActiveButton: !["work", "home"].includes(
                      addressType
                    ),
                  })}
                >
                  {getLocationIcon(
                    "",
                    13,
                    addressType === "" ? "#851D1E" : "#404548"
                  )}
                  Other
                </button>
              </div>
              {!["work", "home"].includes(addressType) && (
                <TextField
                  id="tag"
                  label="address tag"
                  className="saveAddressTextInputField Satoshi"
                  value={addressType}
                  onChange={handleChangeAddress}
                />
              )}
            </div>
            <div>
              <button
                onClick={onSaveClick}
                className="saveAddressMainBtn Satoshi"
              >
                Save address
              </button>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => {
          setOpen(false);
        }}
      />
    </Sheet>
  );
};

export default SaveAddressBottomModal;
