
import * as React from "react";
const EditSvgIcon = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.33301 1.83331H5.99967C2.66634 1.83331 1.33301 3.16665 1.33301 6.49998V10.5C1.33301 13.8333 2.66634 15.1666 5.99967 15.1666H9.99967C13.333 15.1666 14.6663 13.8333 14.6663 10.5V9.16665"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6923 2.51338L5.43894 7.76671C5.23894 7.96671 5.03894 8.36005 4.99894 8.64671L4.71228 10.6534C4.60561 11.38 5.11894 11.8867 5.84561 11.7867L7.85228 11.5C8.13228 11.46 8.52561 11.26 8.73228 11.06L13.9856 5.80671C14.8923 4.90005 15.3189 3.84671 13.9856 2.51338C12.6523 1.18005 11.5989 1.60671 10.6923 2.51338Z"
      stroke="#404548"
      strokeWidth={0.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.93945 3.26672C10.3861 4.86006 11.6328 6.10672 13.2328 6.56006"
      stroke="#404548"
      strokeWidth={0.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditSvgIcon;
