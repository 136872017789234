import * as React from "react";
const BackBtnSvg = (props) => {
  const { fill = "" } = props;

  if (fill) {
    return (
      <svg
        fill={fill}
        height="20px"
        width="20px"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="5.104 6.045 11.657 9.826"
        xmlSpace="preserve"
        {...props}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          <g>
            <path d="M9.486 15.651a0.75 0.75 0 0 0 1.061 0 0.75 0.75 0 0 0 0 -1.061l-2.882 -2.882 8.346 -0.001a0.75 0.75 0 0 0 0 -1.5l-8.346 0.001 2.883 -2.883a0.75 0.75 0 0 0 -1.061 -1.061l-4.163 4.163a0.749 0.749 0 0 0 -0.22 0.53 0.751 0.751 0 0 0 0.22 0.531l4.163 4.163z" />
          </g>
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.57 5.93005L3.5 12.0001L9.57 18.0701"
          stroke="#676C6F"
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4999 12H3.66992"
          stroke="#676C6F"
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};
export default BackBtnSvg;
