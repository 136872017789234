import * as React from "react";
const MinusIconSvg = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.25 6H9.75"
      stroke="#670001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MinusIconSvg;
