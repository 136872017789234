import * as React from "react";

const ChefSvgIcon = ({ color = "white", ...props }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_958_781)">
      <path
        d="M8.21482 8.3186C8.0131 8.3186 7.84131 8.46716 7.84131 8.6417V13.0766C7.84131 13.2576 8.0131 13.4 8.21482 13.4C8.42395 13.4 8.58833 13.2576 8.58833 13.0766V8.6417C8.58833 8.46716 8.42395 8.3186 8.21482 8.3186Z"
        fill={color}
        stroke={color}
        strokeWidth={0.16}
        strokeLinecap="round"
      />
      <path
        d="M11.1885 8.3186C10.9867 8.3186 10.8149 8.46716 10.8149 8.6417V13.0766C10.8149 13.2576 10.9867 13.4 11.1885 13.4C11.3976 13.4 11.5618 13.2576 11.5618 13.0766V8.6417C11.5618 8.46716 11.3976 8.3186 11.1885 8.3186Z"
        fill={color}
        stroke={color}
        strokeWidth={0.16}
        strokeLinecap="round"
      />
      <path
        d="M14.1616 8.3186C13.9599 8.3186 13.7881 8.46716 13.7881 8.6417V13.0766C13.7881 13.2576 13.9599 13.4 14.1616 13.4C14.3707 13.4 14.535 13.2576 14.535 13.0766V8.6417C14.535 8.46716 14.3707 8.3186 14.1616 8.3186Z"
        fill={color}
        stroke={color}
        strokeWidth={0.16}
        strokeLinecap="round"
      />
      <path
        d="M15.34 5.50485C14.7563 4.68714 13.8272 4.18048 12.8158 4.18048C12.5246 4.18048 12.2374 4.22295 11.9622 4.3067L11.7198 4.38051L11.5575 4.17725C10.7568 3.17494 9.58118 2.59998 8.33211 2.59998C6.60737 2.59998 5.08786 3.71534 4.43904 5.3309C4.40631 5.41261 4.37737 5.49624 4.349 5.58034C4.16243 5.54876 3.97149 5.53189 3.77688 5.53189C3.32079 5.53189 2.87309 5.62437 2.45008 5.80059C2.04373 5.97693 1.67047 6.22039 1.35546 6.53958C1.04032 6.85027 0.7916 7.21959 0.617437 7.62264C0.44327 8.04246 0.352051 8.48748 0.352051 8.94942C0.352051 9.40284 0.43488 9.84774 0.617437 10.2676C0.7916 10.679 1.03206 11.0484 1.34719 11.3591C1.66231 11.6782 2.02719 11.9216 2.42527 12.098C2.82618 12.2732 3.24966 12.3641 3.68838 12.3734C3.69429 12.3737 3.69984 12.3752 3.70587 12.3752H5.87834C6.07801 12.3752 6.24156 12.2096 6.24156 12.0074V11.9114C6.24156 11.7093 6.07801 11.5437 5.87834 11.5437H3.80867C3.79319 11.5389 3.77735 11.5355 3.76046 11.5355C3.07206 11.5355 2.42527 11.2584 1.93597 10.7631C1.69552 10.5279 1.51296 10.2507 1.38027 9.9402C1.2477 9.62106 1.18141 9.29346 1.18141 8.94942C1.18141 8.60508 1.2477 8.26926 1.38027 7.95858C1.51296 7.64788 1.70378 7.37081 1.93597 7.13572C2.17642 6.89215 2.45835 6.70743 2.76521 6.57308C3.08861 6.43873 3.42867 6.37161 3.77688 6.37161C4.34086 6.37161 4.86335 6.54795 5.3029 6.89215C5.47695 7.0265 5.74233 6.99288 5.88342 6.80828C6.01608 6.62356 5.9829 6.36312 5.80047 6.22039C5.5742 6.04369 5.32972 5.90121 5.07214 5.79174C5.56013 4.39559 6.83433 3.39591 8.3285 3.39591C9.52245 3.39591 10.619 4.03082 11.2618 5.09426C11.3666 5.26965 11.5756 5.33174 11.7485 5.2407C12.0871 5.06495 12.4455 4.9763 12.8158 4.9763C13.5198 4.9763 14.1533 5.29441 14.5946 5.82141C14.8833 6.1662 15.5364 6.08903 15.34 5.50485Z"
        fill={color}
        stroke={color}
        strokeWidth={0.16}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_958_781">
        <rect width={16} height={12} fill="white" transform="translate(0 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default ChefSvgIcon;
