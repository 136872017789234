import * as React from "react";

const OtherLocationSvgIcon = ({ size = 20, fill = "#181C22", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1829 7.04171C16.3079 3.19171 12.9496 1.45837 9.99959 1.45837C9.99959 1.45837 9.99959 1.45837 9.99126 1.45837C7.04959 1.45837 3.68292 3.18337 2.80792 7.03337C1.83292 11.3334 4.46626 14.975 6.84959 17.2667C7.73292 18.1167 8.86626 18.5417 9.99959 18.5417C11.1329 18.5417 12.2663 18.1167 13.1413 17.2667C15.5246 14.975 18.1579 11.3417 17.1829 7.04171ZM9.99959 11.2167C8.54959 11.2167 7.37459 10.0417 7.37459 8.59171C7.37459 7.14171 8.54959 5.96671 9.99959 5.96671C11.4496 5.96671 12.6246 7.14171 12.6246 8.59171C12.6246 10.0417 11.4496 11.2167 9.99959 11.2167Z"
      fill={fill}
    />
  </svg>
);

export default OtherLocationSvgIcon;
