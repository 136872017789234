import React from 'react';
import { Input } from "antd";
import classNames from 'classnames';

const AntdCustomInput = ({ className = "", ...rest}) => {
  return (
    <Input  className={classNames("ant-input", className)} {...rest} />
  )
}

export default AntdCustomInput