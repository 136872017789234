import * as React from "react";

const ExpandIconSvg = ({ rotate }) => {
  return (
    <svg
      width={12}
      height={8}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${!rotate ? 180 : 0}deg)`,
        transition: "transform 0.3s ease-in-out", // Smooth rotation
      }}
    >
      <path
        d="M10.9326 7.18347H5.74097H1.06597C0.265969 7.18347 -0.134031 6.2168 0.432635 5.65014L4.7493 1.33347C5.44097 0.641805 6.56597 0.641805 7.25764 1.33347L8.8993 2.97514L11.5743 5.65014C12.1326 6.2168 11.7326 7.18347 10.9326 7.18347Z"
        fill="#851D1E"
      />
    </svg>
  );
};

export default ExpandIconSvg;
