import * as React from "react";
const WeeklyMealSvgIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3135 12V12.6666C14.3135 12.9469 14.3129 13.1865 14.285 13.4C14.2575 13.6105 14.2062 13.7684 14.1224 13.8899C13.9685 14.1131 13.6147 14.3333 12.6468 14.3333H3.31348C2.34556 14.3333 1.99183 14.1131 1.83788 13.8899C1.7541 13.7684 1.70276 13.6105 1.67525 13.4C1.64735 13.1865 1.64681 12.9469 1.64681 12.6666V12C1.64681 11.8174 1.79757 11.6666 1.98014 11.6666H13.9801C14.1627 11.6666 14.3135 11.8174 14.3135 12Z"
      fill="#676C6F"
      stroke="#676C6F"
      strokeWidth={0.666667}
    />
    <path
      d="M9.28297 3.37246L9.19948 3.70642L9.53596 3.77912C11.7928 4.26674 13.4797 6.26723 13.4797 8.66664V9.66664C13.4797 9.84921 13.3289 9.99997 13.1464 9.99997H2.84635C2.66378 9.99997 2.51302 9.84921 2.51302 9.66664V8.66664C2.51302 6.26718 4.20653 4.26051 6.46256 3.7793L6.80738 3.70575L6.71461 3.3656C6.68247 3.24776 6.66635 3.1306 6.66635 2.99997C6.66635 2.1475 7.47595 1.47439 8.37832 1.72147L8.38011 1.72195C8.9663 1.87897 9.34799 2.41221 9.33322 3.0411C9.32752 3.15578 9.31118 3.25964 9.28297 3.37246ZM5.99969 8.16664H9.99969C10.4571 8.16664 10.833 7.79073 10.833 7.3333C10.833 6.87588 10.4571 6.49997 9.99969 6.49997H5.99969C5.54226 6.49997 5.16635 6.87588 5.16635 7.3333C5.16635 7.79073 5.54226 8.16664 5.99969 8.16664Z"
      fill="#676C6F"
      stroke="#676C6F"
      strokeWidth={0.666667}
    />
  </svg>
);
export default WeeklyMealSvgIcon;
