import axios from 'axios';
import { YOUR_GOOGLE_MAPS_API_KEY } from '../../store/constants';



export const getLatLngArray = (data) => {
  const locationSteps = data.map((direction) => ({
    lat: direction.start_location.lat,
    lng: direction.start_location.lng,
  }));
  const lastIndexValue = data[data.length - 1];
  locationSteps.push({
    lat: lastIndexValue.end_location.lat,
    lng: lastIndexValue.end_location.lng,
  });
  return locationSteps;
};

export const getNextDirection = (response) =>{
  const distance = response.data.data.routes[0].legs[0].distance.value
  console.log('response', )
  return distance
}

export const fetchPincode = async (lat, lng) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${YOUR_GOOGLE_MAPS_API_KEY}`
    );

    if (response.data.results.length > 0) {
      const addressComponents = response.data.results[0].address_components;
      const postalCodeComponent = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      return postalCodeComponent ? postalCodeComponent.long_name : 'Pincode not found';
    } else {
      return 'Pincode not found';
    }
  } catch (error) {
    console.error('Error fetching pincode:', error);
    return 'Error fetching pincode';
  }
};

export const getAddressFromLatLng = async (lat, lng) => {
  const apiKey = YOUR_GOOGLE_MAPS_API_KEY; // Replace with your API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === "OK" && data.results.length > 0) {
      return data.results[0].formatted_address; // Return the first address
    } else {
      throw new Error("Address not found");
    }
  } catch (error) {
    console.error("Error fetching address:", error);
    return "Failed to fetch address";
  }
};