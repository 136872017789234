import "./GPS-Locator.css";
import MyLocation from "@material-ui/icons/MyLocation";
import AddIcon from '@material-ui/icons/Add';
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/loginSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { locationActions } from "../../store/locationSlice";
import {
  API_TEST,
  FREE_DILIVERY_DISTANCE_RANGE,
  MINIMUM_AMOUNT_MAX_RANGE,
  MINIMUM_AMOUNT_MIN_RANGE,
  ORDER_DISTANCE_RANGE,
  ORDER_DISTANCE_RANGE_MIN,
  PIDGE_API_TOKEN,
  PIDGE_API_URL,
  YOUR_GOOGLE_MAPS_API_KEY
} from "../../store/constants";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import Script from "react-load-script";
import { useRef } from "react";
import { uniqBy } from "lodash";
import {

  extractPincode,
} from "../../utils/helper";
import { addTempUser } from "../../store/loginActions";
// import clevertap from "clevertap-web-sdk";

const GPSLocator = (props) => {
  const {
    setLoading,
    locationSelector,
    showContentInModal,
    initialRender,
    handleCurrentLocationDetected,
    isLocationManuallySelected,
    setIsLocationManuallySelected,
    handleAddNewAddress
  } = props;
  const dispatch = useDispatch();
  const { isAddAddressActive } = useSelector((state) => state.location);
  const chefList = useSelector((state) => state.chef.chefList);
  const defaultChefLocation = chefList.find(
    (c) => c?.chefLocation
  )?.chefLocation;
  const user = useSelector((state) => state.auth.user);
  const addresses = user.addressLine1;
  const displayExplore = useSelector((state) => state.bottomBar.explore);
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });
  const webviewLocationObjValue =
    JSON.parse(localStorage.getItem("webviewLocationObj")) || {};
  const [webviewLocationObj, setWebviewLocationObj] = useState(
    webviewLocationObjValue
  );

  const sourceLocation = defaultChefLocation || {
    lat: 19.116606,
    lng: 72.8854982,
  };

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" &&
          mutation.target.classList.contains("store_location_click_data")
        ) {
          const newTextContent = mutation.target.textContent;
          try {
            const newData = JSON.parse(newTextContent);
            if (newData.type === "ADD_LOCATION") {
              const { type, ...locationObj } = newData;
              setWebviewLocationObj(locationObj);
              onSuccess({
                coords: {
                  longitude: locationObj?.lng,
                  latitude: locationObj?.lat,
                },
              });
              localStorage.setItem(
                "webviewLocationObj",
                JSON.stringify(locationObj)
              );
            }
            // setNotificationData(newData);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        }
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const showError = (error) => {
    toast.error(error, {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "info1",
    });
  };

  const getNearbyLocations = (currentLat, currentLng, locations) => {
    const threshold = 0.1; // Define your threshold distance in kilometers
    const nearbyLocations = [];

    function getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    }

    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }

    locations.forEach((location) => {
      const distance = getDistance(
        currentLat,
        currentLng,
        location.location.lat,
        location.location.lng
      );
      if (distance <= threshold) {
        nearbyLocations.push(location);
      }
    });

    return nearbyLocations;
  };

  const onSuccess = (location) => {
    const nearbyLocations = getNearbyLocations(
      location.coords.latitude,
      location.coords.longitude,
      addresses
    );
    if (nearbyLocations?.length) {
      handleCurrentLocationDetected(nearbyLocations[0]);
    } else {
      setLocation({
        loaded: true,
        coordinates: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        },
      });
      dispatch(
        loginActions.setCoords({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        })
      );
      const tempUser = {
        mpUserId: localStorage.getItem("mpUserId"),
        location: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
          locationName: location.coords?.area,
        },
      };
      dispatch(addTempUser(tempUser));
    }
    const tempUser = {
      mpUserId: localStorage.getItem("mpUserId"),
      location: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
        locationName: location.coords?.area,
      },
    };
    dispatch(addTempUser(tempUser));
    setLoading(false);
  };

  const setLocationError = (error) => {
    setLocation({
      loaded: false,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  const onError = (error) => {
    if (Object.keys(webviewLocationObj).length !== 0) {
      try {
        // Parse the parameter into a JSON object
        onSuccess({
          coords: {
            longitude: webviewLocationObj?.lng,
            latitude: webviewLocationObj?.lat,
          },
        });
        console.log(webviewLocationObj);
      } catch (error) {
        setLocationError(error);
        console.error("Error parsing JSON:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLocationError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const onGeoLocationClickHandler = (manualClick) => {
    setIsLocationManuallySelected(true);
    mixpanel.track("geolocation-clicked");
    // clevertap.event.push("geolocation-clicked");
    setLoading(true);

    if (
      manualClick &&
      Object.keys(webviewLocationObj).length === 0 &&
      window.ReactNativeWebView
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "ASK_LOCATION_PERMISSION" })
      );
      setLoading(false);
      // location permission
    } else if (
      Object.keys(webviewLocationObj).length !== 0 &&
      window.ReactNativeWebView
    ) {
      try {
        onSuccess({
          coords: {
            longitude: webviewLocationObj?.lng,
            latitude: webviewLocationObj?.lat,
          },
        });
      } catch (error) {
        setLocationError(error);
        console.error("Error parsing JSON:", error);
      } finally {
        setLoading(false);
      }
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
      });
    } else {
      setLoading(false);
      showError("Geolocation not supported");
    }
  };

  const switchTab = (tabName) => {
    if (tabName === "chef") {
      if (!!locationSelector) {
        dispatch(bottomBarActions.loadTab({ tabName: tabName }));
      }
    } else {
      dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    }

    if (props.from) {
      dispatch(bottomBarActions.updateFrom({ from: "" }));
    }
  };

  const distanceMatrixRef = useRef(null);

  const checkShadowfaxDeliveryServiceable = async (requestBody) => {
    try {
      const apiUrl = `${API_TEST}shadowfax/serviceability`;
      const response = await axios.post(apiUrl, requestBody);
      const deliveryValue = response.data;
      if (deliveryValue.is_serviceable) {
        dispatch(
          loginActions.setAvailableDeliveryOptions({
            type: "SHADOWFAX",
            payload: deliveryValue,
          })
        );
      } else {
        dispatch(
          loginActions.setAvailableDeliveryOptions({
            type: "SHADOWFAX",
            payload: {},
          })
        );
      }
    } catch (error) {
      dispatch(
        loginActions.setAvailableDeliveryOptions({
          type: "SHADOWFAX",
          payload: {},
        })
      );
    }
  };

  const checkDeliveryServiceable = async (requestBody) => {
    try {
      const token = PIDGE_API_TOKEN; // Your token
      const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/quote`;

      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      const response = await axios.post(apiUrl, requestBody, config);
      const deliveryOptions = response.data.data.items.filter(
        (response) =>
          response.pickup_now &&
          response.quote.eta.pickup &&
          response.quote.eta.drop
      );
      dispatch(
        loginActions.setAvailableDeliveryOptions({
          type: "PIDGE",
          payload: deliveryOptions,
        })
      );
    } catch (error) {
      dispatch(
        loginActions.setAvailableDeliveryOptions({ type: "PIDGE", payload: [] })
      );
    }
  };

  const checkPorterDeliveryServiceable = async (porterBody) => {
    try {
      const apiUrl = `${API_TEST}porter/orders/get_quote`;

      const response = await axios.post(apiUrl, porterBody);
      const deliveryOptions = response.data.data.vehicles.filter(
        (response) => response.type === "2 Wheeler"
      );
      dispatch(
        loginActions.setAvailableDeliveryOptions({
          type: "PORTER",
          payload: deliveryOptions,
        })
      );
    } catch (error) {
      dispatch(
        loginActions.setAvailableDeliveryOptions({
          type: "PORTER",
          payload: [],
        })
      );
    }
  };

  const GPSHandler = async () => {
    if (location.loaded == true) {
      const uniqKitchenData = uniqBy(chefList, (obj) => obj.kitchenId);

      uniqKitchenData
        .filter((a) => a.kitchenId)
        .map((kitchen) => {
          const kitchenId = kitchen.kitchenId;
          const chefLocation = kitchen?.chefLocation;
          distanceMatrixRef.current =
            new window.google.maps.DistanceMatrixService();
          const chefLocationObj = {lat : +chefLocation?.lat, lng: +chefLocation?.lng}
          distanceMatrixRef.current.getDistanceMatrix(
            {
              origins: [chefLocationObj],
              destinations: [
                {
                  lat: location.coordinates.lat,
                  lng: location.coordinates.lng,
                },
              ],
              travelMode: "DRIVING",
            },
            (res, status) => {
              if (status !== "OK") {
                console.log("Unable to fetch...");
              } else {
                let delivery_distance = res.rows[0].elements[0].distance.value;
                let delivery_duration = res.rows[0].elements[0].duration;
                let delivery_destination = res.destinationAddresses[0];
                let deliveryObject = {
                  delivery_distance,
                  delivery_duration,
                  kitchenLocation: chefLocation,
                };

                dispatch(
                  loginActions.setDeliveryDetails({
                    kitchenId,
                    payload: deliveryObject,
                  })
                );
                let deliverydeliveryDistanceInKMS = Math.round(
                  delivery_distance / 1000
                );

                const requestBody = {
                  pickup: {
                    coordinates: {
                      latitude: chefLocation.lat,
                      longitude: chefLocation.lng,
                    },
                    pincode: extractPincode(res.originAddresses[0]),
                  },
                  drop: [
                    {
                      ref: "4324324",
                      location: {
                        coordinates: {
                          latitude: location.coordinates.lat,
                          longitude: location.coordinates.lng,
                        },
                        pincode: extractPincode(res.destinationAddresses[0]),
                      },
                      attributes: {
                        cod_amount: 0,
                        weight: 500,
                        volumetric_weight: 225,
                      },
                    },
                  ],
                };
                const porterBody = {
                  pickup_details: {
                    lat: chefLocation.lat,
                    lng: chefLocation.lng,
                  },
                  drop_details: {
                    lat: location.coordinates.lat,
                    lng: location.coordinates.lng,
                  },
                  customer: {
                    name: "Ashwin",
                    mobile: {
                      country_code: "+91",
                      number: "9664257767",
                    },
                  },
                };

                const srequestBody = {
                  pickup_details: {
                    building_name: "",
                    latitude: chefLocation.lat,
                    longitude: chefLocation.lng,
                    address: res.originAddresses[0],
                  },
                  drop_details: {
                    building_name: "",
                    latitude: location.coordinates.lat,
                    longitude: location.coordinates.lng,
                    address: res.destinationAddresses[0],
                  },
                };

                checkDeliveryServiceable(requestBody);
                checkShadowfaxDeliveryServiceable(
                  srequestBody
                );
                checkPorterDeliveryServiceable(porterBody);
                if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
                  dispatch(loginActions.setIsServiceable(false));
                  dispatch(loginActions.setSelectedAddress(null));
                  dispatch(loginActions.setIsServiceableAlert(true));
                  dispatch(loginActions.setUnserviceableModalOnCart(true));
                  dispatch(loginActions.setMinimumAmount(0));
                } else if (
                  deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN &&
                  deliverydeliveryDistanceInKMS <= ORDER_DISTANCE_RANGE
                ) {
                  dispatch(loginActions.setIsServiceable(true));
                  dispatch(loginActions.setSelectedAddress(null));
                  dispatch(loginActions.setIsServiceableAlert(false));
                  dispatch(loginActions.setFarAwayModalOnCart(true));
                  dispatch(
                    loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE)
                  );
                } else if (
                  deliverydeliveryDistanceInKMS >
                    FREE_DILIVERY_DISTANCE_RANGE &&
                  deliverydeliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN
                ) {
                  dispatch(loginActions.setIsServiceable(true));
                  dispatch(loginActions.setSelectedAddress(null));
                  dispatch(loginActions.setIsServiceableAlert(false));
                  dispatch(
                    loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE)
                  );
                } else {
                  dispatch(loginActions.setIsServiceable(true));
                  dispatch(loginActions.setSelectedAddress(null));
                  dispatch(loginActions.setIsServiceableAlert(false));
                  dispatch(loginActions.setMinimumAmount(0));
                }

                dispatch(loginActions.setLocation(delivery_destination));
                props.from
                  ? switchTab(props.from)
                  : isAddAddressActive
                  ? switchTab("addressForm")
                  : switchTab("chef");
                dispatch(locationActions.setIsAddAddressActive(false));
              }
            }
          );
        });
    } else {
      if (
        location?.error?.message &&
        Object.keys(webviewLocationObj).length === 0
      ) {
        showError("Need location permission.");
      }
    }
  };

  useEffect(() => {
    GPSHandler();
  }, [location]);

  useEffect(() => {
    if (window.ReactNativeWebView) return;
    if (initialRender && !isLocationManuallySelected) {
      // onGeoLocationClickHandler(false)
    }
  }, []);

  useEffect(() => {
    // for webview
    if (
      initialRender &&
      !isLocationManuallySelected &&
      window.ReactNativeWebView &&
      chefList?.length &&
      addresses?.length
    ) {
      // onGeoLocationClickHandler(false)
    }
  }, [chefList, addresses]);

  if (!locationSelector)
    return (
      <>
        <div
          style={{ display: "none" }}
          className="store_location_click_data"
        ></div>
      </>
    );

  return (
    <>
      <div
        style={{ display: "none" }}
        className="store_location_click_data"
      ></div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${YOUR_GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={GPSHandler}
      />
      <div style={showContentInModal ? { padding: 0, marginBottom: 16}:{}} className="locationSearchInputContainer">
        <div className="locationSearchInputWrapper">
          {!showContentInModal && <>
          <div
            className="current_location_button gps_wrapper"
            onClick={() => onGeoLocationClickHandler(true)}
          >
            <MyLocation style={{ color: "#851D1E" }} />
            <div className="gps_content cartMenuPaymentModalAddressLabel Satoshi">Use your current location</div>
          </div>
          <div className="location_button_centered-border"></div>
          </>}
          <div
            className="current_location_button gps_wrapper"
            onClick={handleAddNewAddress}
          >
            <AddIcon style={{ color: "#851D1E" }} />
            <div className="gps_content cartMenuPaymentModalAddressLabel Satoshi">{showContentInModal ? "Add new address" :  "Add address"}</div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  );
};

export default GPSLocator;
