import * as React from "react";

const WorkSvgIcon = ({ size = 20, fill = "#181C22", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3327 17.7083H1.66602C1.32435 17.7083 1.04102 17.9916 1.04102 18.3333C1.04102 18.6749 1.32435 18.9583 1.66602 18.9583H18.3327C18.6743 18.9583 18.9577 18.6749 18.9577 18.3333C18.9577 17.9916 18.6743 17.7083 18.3327 17.7083Z"
      fill={fill}
    />
    <path
      d="M14.1667 1.66675H5.83333C3.33333 1.66675 2.5 3.15841 2.5 5.00008V18.3334H17.5V5.00008C17.5 3.15841 16.6667 1.66675 14.1667 1.66675ZM8.33333 14.3751H5.83333C5.49167 14.3751 5.20833 14.0917 5.20833 13.7501C5.20833 13.4084 5.49167 13.1251 5.83333 13.1251H8.33333C8.675 13.1251 8.95833 13.4084 8.95833 13.7501C8.95833 14.0917 8.675 14.3751 8.33333 14.3751ZM8.33333 10.6251H5.83333C5.49167 10.6251 5.20833 10.3417 5.20833 10.0001C5.20833 9.65841 5.49167 9.37508 5.83333 9.37508H8.33333C8.675 9.37508 8.95833 9.65841 8.95833 10.0001C8.95833 10.3417 8.675 10.6251 8.33333 10.6251ZM8.33333 6.87508H5.83333C5.49167 6.87508 5.20833 6.59175 5.20833 6.25008C5.20833 5.90841 5.49167 5.62508 5.83333 5.62508H8.33333C8.675 5.62508 8.95833 5.90841 8.95833 6.25008C8.95833 6.59175 8.675 6.87508 8.33333 6.87508ZM14.1667 14.3751H11.6667C11.325 14.3751 11.0417 14.0917 11.0417 13.7501C11.0417 13.4084 11.325 13.1251 11.6667 13.1251H14.1667C14.5083 13.1251 14.7917 13.4084 14.7917 13.7501C14.7917 14.0917 14.5083 14.3751 14.1667 14.3751ZM14.1667 10.6251H11.6667C11.325 10.6251 11.0417 10.3417 11.0417 10.0001C11.0417 9.65841 11.325 9.37508 11.6667 9.37508H14.1667C14.5083 9.37508 14.7917 9.65841 14.7917 10.0001C14.7917 10.3417 14.5083 10.6251 14.1667 10.6251ZM14.1667 6.87508H11.6667C11.325 6.87508 11.0417 6.59175 11.0417 6.25008C11.0417 5.90841 11.325 5.62508 11.6667 5.62508H14.1667C14.5083 5.62508 14.7917 5.90841 14.7917 6.25008C14.7917 6.59175 14.5083 6.87508 14.1667 6.87508Z"
      fill={fill}
    />
  </svg>
);

export default WorkSvgIcon;
