import React from "react";
import Sheet from "react-modal-sheet";
import CloseModalIcon from "../../assets/ReactSvg/CloseModalIcon";
import { getLocationIcon } from "../../utils/helper";
import EditSvgIcon from "../../assets/ReactSvg/EditSvgIcon";
import ShareSvgIcon from "../../assets/ReactSvg/ShareSvgIcon";
import DeleteSvgIcon from "../../assets/ReactSvg/DeleteSvgIcon";
import { useSelector } from "react-redux";

const EditLocationBottomModal = ({
  isOpen,
  setOpen,
  selectedAddress,
  setPinMapScreenVisible,
  setEditAddress,
  onDeleteLocationClick
}) => {
  const isAddressUpdateLoading = useSelector(({ auth }) => auth.isAddressUpdateLoading);
  const formattedAddress = `
  ${selectedAddress?.houseNumber || ""}
  ${selectedAddress?.area ? `, ${selectedAddress.area}` : ""}
  ${selectedAddress?.landMark ? `, ${selectedAddress.landMark}` : ""}
  ${selectedAddress?.location?.locationName ? `, ${selectedAddress.location.locationName}` : ""}
`.trim();

  return (
    <Sheet
      springConfig={{
        stiffness: 300,
        damping: 30,
        mass: 0.2,
      }}
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      detent="content-height"
      className="install-modal-popup customReactSheet filter-modal-popup"
    >
      <Sheet.Container>
        <Sheet.Content>
          <div
            onClick={() => {
              setOpen(false);
            }}
            className="closeIconInstallModal"
          >
            <CloseModalIcon />
          </div>
          <div className="edit-location-modal-wrapper">
            <div className="editLocationModalHeader">
              <span>{getLocationIcon(selectedAddress?.tag)}</span>
              <div className="recent-address_container">
                <div className="savedAddressTagName Satoshi">
                  {selectedAddress?.tag}
                </div>
                <p className="savedAddressDescription Satoshi">
                  {formattedAddress.length >= 70
                    ? `${formattedAddress.slice(
                        0,
                        70
                      )}...`
                    : formattedAddress}
                </p>
              </div>
            </div>

            <div className="editLocationActiveButtonWrapper">
              <button
                onClick={() => {
                  setPinMapScreenVisible(true);
                  setEditAddress(selectedAddress)
                }}
                className="transparentBtn editLocationPopupBtn"
              >
                <EditSvgIcon />
                <span className="Satoshi">Edit</span>
              </button>
              <button className="transparentBtn editLocationPopupBtn">
                <ShareSvgIcon />
                <span className="Satoshi">Share</span>
              </button>
              <button disabled={isAddressUpdateLoading} className="transparentBtn editLocationPopupBtn" onClick={onDeleteLocationClick}>
                <DeleteSvgIcon />
                <span className="Satoshi">Delete</span>
              </button>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => {
          setOpen(false);
        }}
      />
    </Sheet>
  );
};

export default EditLocationBottomModal;
