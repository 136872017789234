import React, { useMemo, useRef, useState } from "react";
import { Modal } from "antd";
import SwipeLeftIconSvg from "../../assets/ReactSvg/SwipeLeftIconSvg";
import SwipeRightIconSvg from "../../assets/ReactSvg/SwipeRightIconSvg";
import TinderCard from "react-tinder-card";
import SoupThumbnail from "../../assets/soup_thumbnail.png";
import { useDispatch, useSelector } from "react-redux";
import { updateFoodChoiceInfo } from "../../store/loginActions";

const QUESTION_LIST = [
  {
    title: "Do you believe that a simple, plain Kulcha can still steal the show?",
  },
  {
    title: "Do you love the buttery bliss of a Kulcha brushed with ghee?",
  },
  {
    title: "Is a plate of spicy Chole the perfect partner for your Kulcha?",
  },
  {
    title: "Do stuffed Kulchas, like aloo or paneer, make your heart skip a beat?",
  },
];

const RecommendationModal = ({ isModalOpen, handleCancel }) => {
  const [currentIndex, setCurrentIndex] = useState(QUESTION_LIST.length - 1);
  const [lastDirection, setLastDirection] = useState();
  const [isStartPageVisible, setIsStartPageVisible] = useState(true);
  const [questionList, setQuestionList] = useState([...QUESTION_LIST]);
  const currentIndexRef = useRef(currentIndex);
  const userid = useSelector(({ auth }) => auth.user.userid);
  const dispatch = useDispatch();

  const childRefs = useMemo(
    () =>
      Array(QUESTION_LIST.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const outOfFrame = (title, idx) => {
    console.log(`${title} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
    const currentIndex = questionList.findIndex((q) => q.title === nameToDelete);
    const isInterested = direction === "right";
    const dummyQuestionList = [...questionList];
    dummyQuestionList[currentIndex].isInterested = isInterested;
    setQuestionList(dummyQuestionList)
    if(index === 0){
      handleCancel();
      if(userid){
        const payload = { foodChoice: dummyQuestionList };
        dispatch(updateFoodChoiceInfo(userid, payload))
      }
    }
  };

  const canSwipe = currentIndex >= 0;

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < QUESTION_LIST.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  return (
    <Modal
      className="recommendationModal"
      title="Find your favourite soup"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="swipableCardsWrapper">
        <div className="swipableCardsContainer">
          <div className="tinderCardsContainer">
            {isStartPageVisible ? (
              <div className="tinderMainThumbImageWrapper">
                <div className="tinderMainThumbImageHeader Satoshi">
                  Let's figure out what type of Kulcha person are you.
                </div>
                <img src={SoupThumbnail} className="tinderMainThumbImage" />
              </div>
            ) : (
              <>
                {QUESTION_LIST.map((character, index) => (
                  <TinderCard
                    ref={childRefs[index]}
                    className="tinderCardsSwipe"
                    key={character.title}
                    onSwipe={(dir) => swiped(dir, character.title, index)}
                    onCardLeftScreen={() => outOfFrame(character.title, index)}
                  >
                    <div
                      className="tinderCard"
                    >
                      <div className="tinderCardHeader Satoshi">
                        {character.title}
                      </div>
                      <span className="tinderCardHeaderCountLabel">{QUESTION_LIST.length - index}/{QUESTION_LIST.length}</span>
                    </div>
                  </TinderCard>
                ))}
                <div style={{transform: "translate3d(0px, 0px, 0px) rotate(4deg)"}} className="tinderCardsSwipeDummy"></div>
                <div style={{transform: "translate3d(0px, 0px, 0px) rotate(-4deg)"}} className="tinderCardsSwipeDummy"></div>
              </>
            )}
          </div>
        </div>
      </div>
      {isStartPageVisible ? (
        <div className="tinderSwipeButtonsMainThumb">
          <button
            className="recommendedAnimatedBtnMainThumb Satoshi"
            onClick={() => setIsStartPageVisible(false)}
          >
            Let's Go!
          </button>
        </div>
      ) : (
        <div className="tinderSwipeButtons">
          <div className="recommendedAnimatedBtnWrapper">
            <button
              className="recommendedAnimatedBtn"
              onClick={() => swipe("left")}
            >
              <SwipeLeftIconSvg />
            </button>
            <div className="recommendedAnimatedTxt Satoshi">Swipe Left</div>
          </div>
          <div className="recommendedAnimatedBtnWrapper">
            <button
              className="recommendedAnimatedBtn"
              onClick={() => swipe("right")}
            >
              <SwipeRightIconSvg />
            </button>
            <div className="recommendedAnimatedTxt Satoshi">Swipe Right</div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RecommendationModal;
