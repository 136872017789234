
import * as React from "react";
const AddCircleIconSvg = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99967 14.6667C11.6663 14.6667 14.6663 11.6667 14.6663 8.00004C14.6663 4.33337 11.6663 1.33337 7.99967 1.33337C4.33301 1.33337 1.33301 4.33337 1.33301 8.00004C1.33301 11.6667 4.33301 14.6667 7.99967 14.6667Z"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 8H10.6663"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.6667V5.33337"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AddCircleIconSvg;
