import { useDispatch, useSelector } from "react-redux";
import AppContainer from "../util/AppContainer";
import "./locationSelector.css";
import GPSLocator from "./GPSLocator";
import RecentSearches from "./RecentSearches";
import LocationSearch from "./LocationSearch";
import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import PinMapScreen from "./PinMapScreen";
import { addNewAddress, deleteUserAddress, updateUserAddress } from "../../store/loginActions";
import { loginActions } from "../../store/loginSlice";

const LocationSelector = (props) => {
  const dispatch = useDispatch();
  const { locationSelector, showContentInModal = false, isAddressSelectedSuccessfully = () =>{} } = props;
  const isAddressUpdateSuccess = useSelector(
    ({ auth }) => auth.isAddressUpdateSuccess
  );
  const userid = useSelector(({ auth }) => auth.user.userid);
  const recentSearchesRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isLocationManuallySelected, setIsLocationManuallySelected] =
    useState(false);
  const [isOpen, setOpen] = useState(false);
  const [pinMapScreenVisible, setPinMapScreenVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [editAddress, setEditAddress] = useState({});
  const [showSaveLocationPopup, setShowSaveLocationPopup] = useState(false);
  const isAddAddressActive = useSelector(
    (state) => state.location.isAddAddressActive
  );

  const handleCurrentLocationDetected = (location) => {
    recentSearchesRef.current.autoAdressSelect(location);
  };

  const onConfirmLocationClick = () => {
    if(editAddress?._id){
      dispatch(updateUserAddress(userid, editAddress._id, editAddress));
    }else{
      dispatch(addNewAddress(userid, editAddress));
    }
  };

  const onDeleteLocationClick = () => {
    dispatch(deleteUserAddress(userid, selectedAddress._id));
  };

  const handleAddNewAddress = () =>{
    setPinMapScreenVisible(true);
    setShowSaveLocationPopup(true);
    setEditAddress({})
  }

  useEffect(() => {
    if (isAddressUpdateSuccess) {
      setPinMapScreenVisible(false);
      dispatch(loginActions.setAddressUpdateSuccess(false));
      setSelectedAddress({});
      setOpen(false);
      setEditAddress({})
    }
  }, [isAddressUpdateSuccess]);

  if (!locationSelector) {
    return (
      <>
        <LocationSearch
          from={props.from}
          locationSelector={locationSelector}
          initialRender={true}
        />
        <GPSLocator
          from={props.from}
          locationSelector={locationSelector}
          initialRender={true}
          setIsLocationManuallySelected={setIsLocationManuallySelected}
          isLocationManuallySelected={isLocationManuallySelected}
          setLoading={setLoading}
          handleCurrentLocationDetected={handleCurrentLocationDetected}
          setPinMapScreenVisible={setPinMapScreenVisible}
        />
        <RecentSearches
          initialRender={true}
          ref={recentSearchesRef}
          from={props.from}
          setLoading={setLoading}
          setIsLocationManuallySelected={setIsLocationManuallySelected}
          isOpen={isOpen}
          setOpen={setOpen}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setPinMapScreenVisible={setPinMapScreenVisible}
          setEditAddress={setEditAddress}
          onDeleteLocationClick={onDeleteLocationClick}
          isAddressSelectedSuccessfully={isAddressSelectedSuccessfully}
        />
      </>
    );
  }

  return (
    <AppContainer
      className={showContentInModal ? "locationSelectorBottomModal" : ""}
    >
      <div style={{ position: "relative", height: "100%" }}>
        {loading && (
          <div className="loading-overlay">
            <div className="loading-spinner">
              <Spin />
            </div>
          </div>
        )}
        {pinMapScreenVisible ? (
          <PinMapScreen
            setPinMapScreenVisible={setPinMapScreenVisible}
            selectedAddress={selectedAddress}
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            onConfirmLocationClick={onConfirmLocationClick}
            showSaveLocationPopup={showSaveLocationPopup}
            setShowSaveLocationPopup={setShowSaveLocationPopup}
          />
        ) : (
          <div>
            <LocationSearch
              from={props.from}
              locationSelector={locationSelector}
              showContentInModal={showContentInModal}
              initialRender={false}
            />
            <GPSLocator
              from={props.from}
              locationSelector={locationSelector}
              showContentInModal={showContentInModal}
              initialRender={false}
              setLoading={setLoading}
              setIsLocationManuallySelected={setIsLocationManuallySelected}
              handleCurrentLocationDetected={handleCurrentLocationDetected}
              handleAddNewAddress={handleAddNewAddress}
            />
            {!isAddAddressActive && (
              <RecentSearches
                initialRender={false}
                ref={recentSearchesRef}
                from={props.from}
                setLoading={setLoading}
                setIsLocationManuallySelected={setIsLocationManuallySelected}
                isOpen={isOpen}
                setOpen={setOpen}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                setPinMapScreenVisible={setPinMapScreenVisible}
                setEditAddress={setEditAddress}
                showContentInModal={showContentInModal}
                onDeleteLocationClick={onDeleteLocationClick}
                isAddressSelectedSuccessfully={isAddressSelectedSuccessfully}
              />
            )}
          </div>
        )}
      </div>
    </AppContainer>
  );
};

export default LocationSelector;
