import React from "react";
import "./chefSearchListComponent.css";
import { useDispatch, useSelector } from "react-redux";
import DefaultChefImage from "../../assets/ChefDefaultIcon.svg";
import SearchSVGComponent from "../jsxsvg/SearchSVGComponent";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";

const ChefSearchListComponent = ({ chefSearchText }) => {
  const dispatch = useDispatch();
  const savedChefNames = JSON.parse(localStorage.getItem("chefNames")) || [];
  const chefList = useSelector((state) => state.chef.chefList);
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const enrichedChefList = chefList.map((chef) => ({
    ...chef,
    chefImage: chef?.profileImg?.includes("amazonaws.com")
      ? chef.profileImg
      : S3_MENU_THUMBNAIL_FOLDER + chef.profileImg,
    kitchenInfo: kitchensList.find(
      (kitchen) => kitchen?._id === chef?.kitchenId
    ),
  }));

  const handleSearch = (text) => {
    const lowerText = text.toLowerCase();

    const filtered = enrichedChefList.filter((chef) => {
      const isMatch = (field) =>
        field && field.toLowerCase().includes(lowerText);

      return (
        isMatch(chef.name) 
        // ||
        // isMatch(chef?.kitchenInfo?.address)
      );
    });
    return filtered
  };

  const onChefClick = (chefInfo) => {
    dispatch(menuActions.setCurrentChefIdClicked(chefInfo._id));
    savedChefNames.push(chefInfo.name);
    localStorage.setItem("chefNames", JSON.stringify(savedChefNames));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
  };

  const searchedEnrichedChefList = handleSearch(chefSearchText);

  return (
    <div className="chefHomeSearchBoxContainer">
      {searchedEnrichedChefList.map((chef) => (
        <div className="chefSearchResultContainer" onClick={() => onChefClick(chef)}>
          <img
            src={chef?.profileImg ? chef.chefImage : DefaultChefImage}
            alt="Chef image"
            className="orderSummaryUserAvatar"
          />
          <div className="orderSummaryUserDetails">
            <div className="orderSummaryUserName Satoshi">{chef.name}</div>
            <div className="orderSummaryUserLocation Satoshi">
              {chef.kitchenInfo?.address
                ? chef.kitchenInfo.address.length >= 40
                  ? chef.kitchenInfo.address.slice(0, 40) + "..."
                  : chef.kitchenInfo.address
                : ""}
            </div>
          </div>
          <SearchSVGComponent size={16} fill="#676C6F" />
        </div>
      ))}
    </div>
  );
};

export default ChefSearchListComponent;
