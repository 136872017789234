import * as React from "react";

const VegSvgIcon = ({ size = 12, ...props }) => {
  const strokeWidth = size / 15; // Adjust strokeWidth based on size
  const rectRx = size / 4; // Adjust rx for the rectangle
  const circleRadius = size / 4; // Adjust radius for the circle

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={size / 30}
        y={size / 30}
        width={size - size / 15}
        height={size - size / 15}
        rx={rectRx}
        fill="white"
      />
      <rect
        x={size / 30}
        y={size / 30}
        width={size - size / 15}
        height={size - size / 15}
        rx={rectRx}
        stroke="#00BB6D"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={circleRadius}
        fill="#00BB6D"
      />
    </svg>
  );
};

export default VegSvgIcon;
