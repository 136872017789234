import React, { useEffect } from "react";
import "./bottom-bar.css";
import { bottomBarActions } from "../store/bottomBarSlice";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { menuActions } from "../store/menuSlice";
import OrderStatusBar from "./OrderStatusBar";
import { capitalizeFirstLetter } from "../utils/helper";
import ChefIconSvg from "../assets/ReactSvg/ChefIconSvg";
import DishesIconSvg from "../assets/ReactSvg/DishesIconSvg";
import ExploreIconSvg from "../assets/ReactSvg/ExploreIconSvg";
import CartIconSvg from "../assets/ReactSvg/CartIconSvg";
import ProfileIconSvg from "../assets/ReactSvg/ProfileIconSvg";
// import clevertap from "clevertap-web-sdk";

const BottomBarTab = ({ tabName, active }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromScreen = urlParams.get('fromScreen');
  const isCheckoutCancel = fromScreen === "checkoutCancel"
  
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    mixpanel.track("tab-clicked-" + tabName);
    // clevertap.event.push("tab-clicked-" + tabName);
  };

  const handleEvent = () => {
    switch (tabName) {
      case "chef":
        switchTab("chef");
        break;
      case "dishes":
        switchTab("dishes");
        break;
      case "cart":
        dispatch(menuActions.setIsMenuClicked(false));
        switchTab("cart");
        break;
      case "account":
        switchTab(user.username ? "profile" : "account");
        break;
      case "explore":
        switchTab("explore");
        break;
      default:
        break;
    }
  };

  useEffect(() =>{
    if(isCheckoutCancel){
      dispatch(menuActions.setIsMenuClicked(false));
      switchTab("cart");
    }
  },[isCheckoutCancel])

  const getBottomBarIcon = (tabName, active) =>{
    switch(tabName) {
      case "chef":
        return <ChefIconSvg active={active} />
      case "dishes":
        return <DishesIconSvg active={active} />
      case "explore":
        return <ExploreIconSvg active={active} />
      case "cart":
        return <CartIconSvg active={active} />
      case "account":
        return <ProfileIconSvg active={active} />
      default:
        // code block
    }
  };

  return (
    <li onClick={() => handleEvent()} className="dishes-bottom-menu">
      <figure className={active ? "animation-bar" : "active-opacity"}>
        {getBottomBarIcon(tabName, active)}
        <figcaption className="Satoshi">{capitalizeFirstLetter(tabName)}</figcaption>
      </figure>
    </li>
  );
};

const BottomBar = ({ isExplore = false }) => {
  const chef = useSelector((state) => state.bottomBar.chef);
  const dishes = useSelector((state) => state.bottomBar.dishes);
  const cart = useSelector((state) => state.bottomBar.cart);
  const account = useSelector((state) => state.bottomBar.account);
  const profile = useSelector((state) => state.bottomBar.profile);
  const explore = useSelector((state) => state.bottomBar.explore);

  const bottombardisplay = useSelector(
    (state) => state.bottomBar.bottombardisplay
  );

  return (
    <>
      {bottombardisplay ? (
        <section style={isExplore ? { zIndex: 1 } : {}} className="footer-wrapper">
          {/* {orderSummary && <OrderStatusBar />} */}
          <ul className="footer-links">
            <BottomBarTab tabName="chef" active={chef} />
            <BottomBarTab tabName="dishes" active={dishes} />
            <BottomBarTab tabName="explore" active={explore} />
            <BottomBarTab tabName="cart" active={cart} />
            <BottomBarTab tabName="account" active={account || profile} />
          </ul>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default BottomBar;
