import React, { useEffect, useState } from "react";
import MenuCategories from "../menu/MenuCategories";
import ImageLoader from "../chef/ImageLoader";
import AntdCustomInput from "../AntdCustomInput/Index";
import SearchSVGComponent from "../jsxsvg/SearchSVGComponent";
import "./chefMenuComponent.css";
import VegSvgIcon from "../../assets/ReactSvg/VegSvgIcon";
import EggSvgIcon from "../../assets/ReactSvg/EggSvgIcon";
import NonVegSvgIcon from "../../assets/ReactSvg/NonVegSvgIcon";
import mixpanel from "mixpanel-browser";
import CloseMaroonIconSvg from "../../assets/ReactSvg/CloseMaroonIconSvg";

const ChefMenuComponent = ({
  menuLoaded,
  categoriesdata,
  onCheckPrice,
  setShowAddedItemPopup
}) => {
  const [dishSearchText, setDishSearchText] = useState("");
  const [filCategoriesdata, setFilCategoriesdata] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    veg: false,
    egg: false,
    nonVeg: false,
  });

  const toggleFilter = (filter) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };

  useEffect(() => {
    setFilCategoriesdata([...categoriesdata]);
  }, [categoriesdata]);

  const checkDishFilterApplied = (dishInfo, selectedFilters) => {
    const { veg, egg, nonVeg } = selectedFilters;
    const isVegMatch = dishInfo?.type === "veg" && veg;
    const isEggMatch = dishInfo?.type === "egg" && egg;
    const isNonVegMatch = dishInfo?.type === "non veg" && nonVeg;
    return isVegMatch || isEggMatch || isNonVegMatch;
  };

  const searchMenu = (menuArray, searchText) => {
    const lowercasedValue = searchText.toLowerCase();
    if (!lowercasedValue) {
      return menuArray;
    }
    const filteredMenu = menuArray
      .map((category) => {
        const isCategoryMatch = category.name
          .toLowerCase()
          .includes(lowercasedValue);

        const filteredDishes = category.dishes.filter((dish) => {
          return (
            dish.name?.toLowerCase().includes(lowercasedValue) ||
            dish.description?.toLowerCase().includes(lowercasedValue)
          );
        });

        if (isCategoryMatch || filteredDishes.length > 0) {
          return {
            ...category,
            dishes: filteredDishes,
          };
        }
      })
      .filter((category) => !!category);

    return filteredMenu;
  };

  const searchFoodType = (menuArray) => {
    const { veg, egg, nonVeg } = selectedFilters;
    if (!veg && !egg && !nonVeg) return menuArray;
    const filteredDishes = menuArray
      .filter((category) => {
        const filteredDishes = category.dishes.filter((dish) => {
          return checkDishFilterApplied(dish, selectedFilters);
        });
        if (filteredDishes.length > 0) {
          return {
            ...category,
            dishes: filteredDishes,
          };
        }
      })
      .filter((category) => !!category);
    return filteredDishes;
  };

  const handleFilterMenu = () => {
    const lowercasedValue = dishSearchText?.toLowerCase().trim();

    const filteredData = searchMenu(categoriesdata, lowercasedValue);

    const filterDish = searchFoodType(filteredData);

    if (filterDish.length === 0) {
      setFilCategoriesdata([]);
    } else {
      setFilCategoriesdata(filterDish);
    }

    // Track the search event
    mixpanel.track("menu-searched");
    // clevertap.event.push("menu-searched");
  };

  useEffect(() => {
    handleFilterMenu();
  }, [
    selectedFilters.veg,
    selectedFilters.egg,
    selectedFilters.nonVeg,
    dishSearchText,
  ]);

  const handleChange = (e) => {
    const value = e.target.value;
    setDishSearchText(value);
  };

  return (
    <div className="menuCategoriesWrapper">
      <AntdCustomInput
        className="menuCategoriesSearchInput Satoshi"
        size="large"
        placeholder="Search for dishes"
        prefix={<SearchSVGComponent />}
        value={dishSearchText}
        onChange={handleChange}
      />
      <div className="menuButtonTopBox">
        <div>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.veg ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("veg")}
          >
            <VegSvgIcon />
            Veg
            {selectedFilters.veg && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.egg ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("egg")}
          >
            <EggSvgIcon />
            Egg
            {selectedFilters.egg && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.nonVeg ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("nonVeg")}
          >
            <NonVegSvgIcon />
            Non-veg
            {selectedFilters.nonVeg && <CloseMaroonIconSvg />}
          </button>
        </div>
        <div>
        </div>
      </div>
      {menuLoaded ? (
        <MenuCategories
          categories={filCategoriesdata}
          saff={false}
          onCheckPrice={onCheckPrice}
          setShowAddedItemPopup={setShowAddedItemPopup}
          parentComponent="chef"
        />
      ) : (
        <ImageLoader className="loader_wrapper" />
      )}
    </div>
  );
};

export default ChefMenuComponent;
