import * as React from "react";
const ClockIconSvg = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.375 6.625C10.375 9.04 8.415 11 6 11C3.585 11 1.625 9.04 1.625 6.625C1.625 4.21 3.585 2.25 6 2.25C8.415 2.25 10.375 4.21 10.375 6.625Z"
      stroke="#404548"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4V6.5"
      stroke="#404548"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 1H7.5"
      stroke="#404548"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ClockIconSvg;
