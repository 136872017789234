import * as React from "react";

const NonVegSvgIcon = ({ size = 12, ...props }) => {
  const rectStrokeWidth = size / 15; // Adjust strokeWidth based on size
  const rectRx = size / 4; // Adjust rx for the rectangle
  const pathScale = size / 12; // Scale the path for the larger icon

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={size / 30}
        y={size / 30}
        width={size - size / 15}
        height={size - size / 15}
        rx={rectRx}
        fill="white"
      />
      <rect
        x={size / 30}
        y={size / 30}
        width={size - size / 15}
        height={size - size / 15}
        rx={rectRx}
        stroke="#EE1616"
        strokeWidth={rectStrokeWidth}
      />
      <path
        d={`M${5.35048 * pathScale} ${3.375 * pathScale}C${5.63916 * pathScale} ${
          2.875 * pathScale
        } ${6.36084 * pathScale} ${2.875 * pathScale} ${6.64952 * pathScale} ${
          3.375 * pathScale
        }L${9.2476 * pathScale} ${7.875 * pathScale}C${9.53627 * pathScale} ${
          8.375 * pathScale
        } ${9.17543 * pathScale} ${9 * pathScale} ${8.59808 * pathScale} ${
          9 * pathScale
        }H${3.40192 * pathScale}C${2.82457 * pathScale} ${9 * pathScale} ${
          2.46373 * pathScale
        } ${8.375 * pathScale} ${2.7524 * pathScale} ${
          7.875 * pathScale
        }L${5.35048 * pathScale} ${3.375 * pathScale}Z`}
        fill="#EE1616"
      />
    </svg>
  );
};

export default NonVegSvgIcon;
