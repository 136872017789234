
import * as React from "react";
const CallFilledIconSvg = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6463 12.72C14.6463 12.96 14.593 13.2066 14.4797 13.4466C14.3663 13.6866 14.2197 13.9133 14.0263 14.1266C13.6997 14.4866 13.3397 14.7466 12.933 14.9133C12.533 15.08 12.0997 15.1666 11.633 15.1666C10.953 15.1666 10.2263 15.0066 9.45967 14.68C8.69301 14.3533 7.92634 13.9133 7.16634 13.36C6.39967 12.8 5.67301 12.18 4.97967 11.4933C4.29301 10.8 3.67301 10.0733 3.11967 9.31331C2.57301 8.55331 2.13301 7.79331 1.81301 7.03998C1.49301 6.27998 1.33301 5.55331 1.33301 4.85998C1.33301 4.40665 1.41301 3.97331 1.57301 3.57331C1.73301 3.16665 1.98634 2.79331 2.33967 2.45998C2.76634 2.03998 3.23301 1.83331 3.72634 1.83331C3.91301 1.83331 4.09967 1.87331 4.26634 1.95331C4.43967 2.03331 4.59301 2.15331 4.71301 2.32665L6.25967 4.50665C6.37967 4.67331 6.46634 4.82665 6.52634 4.97331C6.58634 5.11331 6.61967 5.25331 6.61967 5.37998C6.61967 5.53998 6.57301 5.69998 6.47967 5.85331C6.39301 6.00665 6.26634 6.16665 6.10634 6.32665L5.59967 6.85331C5.52634 6.92665 5.49301 7.01331 5.49301 7.11998C5.49301 7.17331 5.49967 7.21998 5.51301 7.27331C5.53301 7.32665 5.55301 7.36665 5.56634 7.40665C5.68634 7.62665 5.89301 7.91331 6.18634 8.25998C6.48634 8.60665 6.80634 8.95998 7.15301 9.31331C7.51301 9.66665 7.85967 9.99331 8.21301 10.2933C8.55967 10.5866 8.84634 10.7866 9.07301 10.9066C9.10634 10.92 9.14634 10.94 9.19301 10.96C9.24634 10.98 9.29967 10.9866 9.35967 10.9866C9.47301 10.9866 9.55967 10.9466 9.63301 10.8733L10.1397 10.3733C10.3063 10.2066 10.4663 10.08 10.6197 9.99998C10.773 9.90665 10.9263 9.85998 11.093 9.85998C11.2197 9.85998 11.353 9.88665 11.4997 9.94665C11.6463 10.0066 11.7997 10.0933 11.9663 10.2066L14.173 11.7733C14.3463 11.8933 14.4663 12.0333 14.5397 12.2C14.6063 12.3666 14.6463 12.5333 14.6463 12.72Z"
      fill="#404548"
    />
  </svg>
);
export default CallFilledIconSvg;
