import * as React from "react";
const ExploreIconSvg = (props) => {
  const fillColor = props.active ? "#851D1E" : "#676C6F";
  return <svg
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <path
    d="M6.375 1V4.75"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M6.375 8.875V19"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M9 1L9.75 5.5C9.75 6.39511 9.39442 7.25355 8.76148 7.88648C8.12855 8.51942 7.27011 8.875 6.375 8.875C5.47989 8.875 4.62145 8.51942 3.98851 7.88648C3.35558 7.25355 3 6.39511 3 5.5L3.75 1"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.625 13H12.375C12.375 13 13.5 2.5 17.625 1V19"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
};
export default ExploreIconSvg;
