import * as React from "react";
const VolumeHighSvgIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.66699 8.33332V11.6667C1.66699 13.3333 2.50033 14.1667 4.16699 14.1667H5.35866C5.66699 14.1667 5.97533 14.2583 6.24199 14.4167L8.67533 15.9417C10.7753 17.2583 12.5003 16.3 12.5003 13.825V6.17499C12.5003 3.69165 10.7753 2.74165 8.67533 4.05832L6.24199 5.58332C5.97533 5.74165 5.66699 5.83332 5.35866 5.83332H4.16699C2.50033 5.83332 1.66699 6.66665 1.66699 8.33332Z"
      stroke="#676C6F"
    />
    <path
      d="M15 6.66663C16.4833 8.64163 16.4833 11.3583 15 13.3333"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5254 4.58337C18.9337 7.79171 18.9337 12.2084 16.5254 15.4167"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default VolumeHighSvgIcon;
