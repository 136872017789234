import * as React from "react";
const MenuSvgIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 7H12.0001"
      stroke="#851D1E"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M12 12H12.0001"
      stroke="#851D1E"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M12 17H12.0001"
      stroke="#851D1E"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default MenuSvgIcon;
