import * as React from "react";
const OnlineUpiPaymentIconSvg = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.3} y={0.3} width={31.4} height={31.4} rx={6.1} fill="white" />
    <rect
      x={0.3}
      y={0.3}
      width={31.4}
      height={31.4}
      rx={6.1}
      stroke="#D5D1C9"
      strokeWidth={0.6}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5742 26L22.0567 16.3108C22.2556 16.1282 22.3067 15.8325 22.1834 15.5928L17.2465 6L16.4089 8.95157C16.3189 9.2697 16.5022 9.60236 16.8177 9.69309C17.1343 9.78382 17.4632 9.60012 17.5543 9.28088L17.5687 9.23159L20.9169 15.7375L13.706 22.4011C13.6405 22.2331 13.5005 22.0964 13.315 22.0438C12.9995 21.9519 12.6695 22.1368 12.5795 22.4549L11.5742 26ZM9.75 24.1215L18.6786 15.8684L14.4371 7.61532L9.75 24.1215Z"
      fill="#676C6F"
    />
  </svg>
);
export default OnlineUpiPaymentIconSvg;
