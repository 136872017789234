import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import BottomBar from '../BottomBar';
import "./explore.css"
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";

import { FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN, S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { EffectFade } from 'swiper/modules';
// import SwiperCore from "swiper/core";

import 'swiper/css';
import { useEffect, useState } from "react";
import { updateExploreDishes, updatePausedDishesInfo } from '../../store/loginActions';
import ReactInstaStories from '../../components/InstaStoriesPackage/index.tsx';
import ExploreStory from './exploreStory';
import InstallModal from '../InstallModal/Index';
import VegChoiceModal from '../VegChoiceModal/Index';
import { exploreDemoStory, flatten2DArray, getLabelsArray, getLabelsArrayValue, getNextStoryImages, isWebApp, sortedDishArray } from '../../utils/helper';
import mixpanel from 'mixpanel-browser';
import { loginActions } from '../../store/loginSlice';
import { toast } from 'react-toastify';
import { uniqBy } from 'lodash';
import { cartAction } from '../../store/cartSlice';
import LocationChoiceModal from '../VegChoiceModal/LocationChoiceModal';
import { bottomBarActions } from '../../store/bottomBarSlice';
import { storieAction } from '../../store/storieSlice';
import RecommendationModal from '../ChefListScreen/RecommendationModal';
// import clevertap from 'clevertap-web-sdk';

// SwiperCore.use([EffectFade]);


const Explore = (props) => {
  const [showInstallModal, setShowInstallModal] = useState(false)
  const [showInstallModalAnswered, setShowInstallModalAnswered] = useState(false)
  const showInstallModalAnsweredRef = useRef();
  const isAutoStoryEndRef = useRef();
  const distanceMatrixRef = useRef(null);
  const swiperIndexRef = useRef();
  const [showChoiceModal, setShowChoiceModal] = useState(false);
  const foodChoiceSelection = useSelector(({ auth }) => auth.user.foodChoice) || [];
  const [isModalOpen, setIsModalOpen] = useState(!foodChoiceSelection.length);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adjustedHeight, setAdjustedHeight] = useState(window.innerHeight - 60);
  const webviewLocationObjValue = JSON.parse(localStorage.getItem('webviewLocationObj')) || {};
  const [webviewLocationObj, setWebviewLocationObj] = useState(webviewLocationObjValue);
  const foodChoice = localStorage.getItem("foodChoice");
  const chefList = useSelector((state) => state.chef.chefList);
  const [foodChoiceState, setFoodChoiceState] = useState(foodChoice || "");
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const viewportHeight = window.innerHeight;
  const screenHeight = viewportHeight - 60;
  const categories = useSelector((state) => state.chef.categories);
  const user = useSelector(({ auth }) => auth.user);
  const coordsSelector = useSelector(({ auth }) => auth.coords);
  const addresses = user.addressLine1;
  const startTimestampRef = useRef(null);
  const isWebview = window.location.search.includes('?webview=true');
  const isAppInstalled = isWebview || /wv/i.test(navigator.userAgent) || !isWebApp();
  const isIosAppInstalled = isWebview || (navigator.userAgent.includes('Mobile/') && !navigator.userAgent.includes('Safari/')) || !isWebApp();
  const dispatch = useDispatch();

  const catName = useSelector((state) => state.chef.categoryName);

  // const chefId = props.dishes.map((item) => item?.chefId);

  const [swiper, setSwiper] = useState(null);
  let storiesList = [];
  const merged = [].concat.apply([], Object.values(categories));
  const weeklyDishes = merged?.filter((a) => a.type === "weekly") || [];
  const dailyDishes = merged?.filter((a) => a.type === "daily") || [];
  const categoriesWithClass = [...dailyDishes, ...weeklyDishes].filter((category) => {
    if (category.img)
      return {
        ...category,
        ringClass: "ring-" + category.color,
      };
  });
  const exploreDishes = flatten2DArray(categoriesWithClass)

  let storyNum = categoriesWithClass?.findIndex(
    (category) => category.name === catName
  );

  let storySlideNumber = 0;

  const [my_swiper, set_my_swiper] = useState({});

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(storyNum)
    }
  }, [swiper])


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const getStories = (isActive, dish, imageSrc, isNext, isPrev, index, dishStories) => {
  //   try {

  //     labelsArray.forEach((label, i) => {
  //       if (label) {
  //         storyContent.push()
  //       }

  //     });
  //     storiesList = storyContent;
  //     return storyContent;
  //   } catch (error) {
  //     console.log("Error");
  //   }
  // };

  const handleShowModal = () => {
    showInstallModalAnsweredRef.current = true;
    setShowInstallModalAnswered(true)
    if (!!foodChoiceState && isAutoStoryEndRef.current) {
      const canSwipe = swipeToNextSlide();
      if (canSwipe) {
        storySlideNumber = 0;
        my_swiper.slideNext();
      } else {
        dispatch(chefActions.onStoryBackClick());
      }
    }
  }

  const handleFoodChoiceState = (value) => {
    const canSwipe = swipeToNextSlide();
    if (canSwipe) {
      storySlideNumber = 0;
      my_swiper.slideNext();
    } else {
      dispatch(chefActions.onStoryBackClick());
    }
    setFoodChoiceState(value)
  }

  const exploreDishesApi = (dish) => {
    const dishId = dish._id;
    const exploredDishesIdsLocal = JSON.parse(localStorage.getItem("@user") || "{}")["exploredDishesIds"] || [];
    const userexploredDishesIds = user.exploredDishesIds || [];
    const allDishesIds = [...exploredDishesIdsLocal, ...userexploredDishesIds, dishId];
    const allDishesIdsUniqueArray = [...new Set(allDishesIds)];
    const payload = { exploredDishesIds: allDishesIdsUniqueArray };
    // dispatch(updateExploreDishes(user.userid, payload));
  }


  const selectedFoodChoice = foodChoiceState === "VEG" ? ["veg"] : foodChoiceState === "NONVEG" ? ["non veg", "veg", "egg"] : ["non veg", "veg", "egg"];

  const allExploreDishes = exploreDishes.filter((a) => selectedFoodChoice.includes(a.type)) || [];

  const filteredExploreDishes = sortedDishArray(allExploreDishes);

  const sortedDailyFirstExploredDishes = [...getNextStoryImages([exploreDemoStory, ...filteredExploreDishes, exploreDemoStory])];

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.target.classList.contains('store_location_click_data')) {
          const newTextContent = mutation.target.textContent;
          try {
            const newData = JSON.parse(newTextContent);
            if (newData.type === "ADD_LOCATION") {
              const { type, ...locationObj } = newData;
              setWebviewLocationObj(locationObj)
              onSuccess({ coords: { longitude: locationObj?.lng, latitude: locationObj?.lat } })
              localStorage.setItem('webviewLocationObj', JSON.stringify(locationObj));
            }
            // setNotificationData(newData);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const getNearbyLocations = (currentLat, currentLng, locations) => {
    const threshold = 0.1; // Define your threshold distance in kilometers
    const nearbyLocations = [];

    function getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    }

    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }

    locations.forEach(location => {
      const distance = getDistance(currentLat, currentLng, location.location.lat, location.location.lng);
      if (distance <= threshold) {
        nearbyLocations.push(location);
      }
    });

    return nearbyLocations;
  }

  const onSelectAddressHandler = (data, autoDetect) => {
    const uniqKitchenData = uniqBy(chefList, obj => obj.kitchenId);
    uniqKitchenData.filter((a) => a.kitchenId).map((kitchen) => {
      const kitchenId = kitchen.kitchenId;
      const chefLocation = kitchen.chefLocation;
      distanceMatrixRef.current = new window.google.maps.DistanceMatrixService();
      const chefLocationObj = {lat : +chefLocation?.lat, lng: +chefLocation?.lng};
      distanceMatrixRef.current.getDistanceMatrix(
        {
          origins: [chefLocationObj],
          destinations: [
            {
              lat: data?.location.lat,
              lng: data?.location.lng,
            },
          ],
          travelMode: "DRIVING",
        },
        (res, status) => {
          if (status !== "OK") {
            console.log("Unable to fetch...");
          } else {
            let delivery_distance = res.rows[0].elements[0].distance.value;
            let delivery_duration = res.rows[0].elements[0].duration;
            let delivery_destination = res.destinationAddresses[0];
            let deliveryObject = {
              delivery_distance,
              delivery_duration,
              kitchenLocation: chefLocation
            };

            dispatch(loginActions.setDeliveryDetails({ kitchenId, payload: deliveryObject }));
            let deliverydeliveryDistanceInKMS = Math.round(
              delivery_distance / 1000
            );
            // const requestBody = {
            //   pickup_details: {
            //     building_name: "",
            //     latitude: chefLocation.lat,
            //     longitude: chefLocation.lng,
            //     address: res.originAddresses[0]
            //   },
            //   drop_details: {
            //     building_name: "",
            //     latitude: data?.location.lat,
            //     longitude: data?.location.lng,
            //     address: res.destinationAddresses[0]
            //   }
            // };

            const requestBody = {
              "pickup_details": {
                "building_name": "NP Home Foods",
                "latitude": 12.933562657282067,
                "longitude": 77.61429533708163,
                "address": "Apex Building 93/A Ground Floor, A Wing, 4th B Cross, 5th Block, Koramangala, Bengaluru, Karnataka 560095"
              },
              "drop_details": {
                "building_name": "NP Sweet Home",
                "latitude": 12.93373883438634,
                "longitude": 77.61620254160374,
                "address": "JNC, Hosur Rd, KHB Colony, 5th Block, Koramangala, Bengaluru, Karnataka 560095"
              }
            }

            // checkDeliveryServiceable(requestBody)
            if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(false));
              dispatch(loginActions.setIsServiceableAlert(true));
              dispatch(loginActions.setUnserviceableModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(0));
            } else if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN &&
              deliverydeliveryDistanceInKMS <= ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setFarAwayModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
            } else if (deliverydeliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE &&
              deliverydeliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
            } else {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(0));
            }

            let coords = { lat: data.location.lat, lng: data.location.lng };
            dispatch(loginActions.setSelectedAddress(data));
            dispatch(loginActions.setLocation(data.location.locationName));
            dispatch(cartAction.setIsAddressOptionsVisible(false));
            dispatch(loginActions.setCoords(coords));
            dispatch(loginActions.setLocation(delivery_destination));
          }
        }
      );
    })
  };

  const onSuccess = (location) => {
    const nearbyLocations = getNearbyLocations(location.coords.latitude, location.coords.longitude, addresses);
    if (nearbyLocations?.length) {
      onSelectAddressHandler(nearbyLocations[0], false)
    } else {
      setLocation({
        loaded: true,
        coordinates: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        },
      });
      onSelectAddressHandler({
        location: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        }
      }, false)
      dispatch(
        loginActions.setCoords({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        })
      );
    }
    setLoading(false);
  };

  const showError = (error) => {
    toast.error(error, {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "info1",
    });
  };

  const onError = (error) => {
    if (Object.keys(webviewLocationObj).length !== 0) {
      try {
        // Parse the parameter into a JSON object
        onSuccess({ coords: { longitude: webviewLocationObj?.lng, latitude: webviewLocationObj?.lat } })
        console.log(webviewLocationObj);
      } catch (error) {
        setLocationError(error)
        console.error('Error parsing JSON:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setLocationError(error)
      setLoading(false);
    }
    setLoading(false);
  };

  const setLocationError = (error) => {
    setLocation({
      loaded: false,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  }

  const swipeToNextSlide = () => {
    if (swiper) {
      const curInd = swiper.activeIndex;
      if ((curInd === sortedDailyFirstExploredDishes?.length - 1)) {
        return false;
      } else {
        return true;
      }
    }
  }

  const onGeoLocationClickHandler = (manualClick) => {
    mixpanel.track("geolocation-clicked");
    // clevertap.event.push("geolocation-clicked");
    setLoading(true)
    if (manualClick && Object.keys(webviewLocationObj).length === 0 && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "ASK_LOCATION_PERMISSION" }))
      setLoading(false)
    }
    else if (Object.keys(webviewLocationObj).length !== 0 && window.ReactNativeWebView) {
      try {
        onSuccess({ coords: { longitude: webviewLocationObj?.lng, latitude: webviewLocationObj?.lat } })
      } catch (error) {
        setLocationError(error)
        console.error('Error parsing JSON:', error);
      } finally {
        setLoading(false);
      }
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        enableHighAccuracy: true
      });
    } else {
      setLoading(false);
      showError("Geolocation not supported");
    }
  };

  const manualLocationClick = () => {
    setShowLocationModal(false)
    dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    dispatch(bottomBarActions.loadTab({ tabName: "locationSelector" }));
    mixpanel.track("location-clicked");
  }

  useEffect(() => {
    const handleResize = () => {
      setAdjustedHeight(window.innerHeight - 60);
    };

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isValidLocation = !!location?.coordinates?.lat && !!location?.coordinates?.lng && location.loaded;
  const isValidLocationSelector = !!coordsSelector?.lat && !!coordsSelector?.lng;
  const isLocationValid = isValidLocation || isValidLocationSelector;

  const isDishesLoading = !sortedDailyFirstExploredDishes || !sortedDailyFirstExploredDishes.length;
  if (isDishesLoading) return (<div className="splash-screen">
    <img src="cuirato-main.png" alt="loading..." />
  </div>)

  const exploreVideos = [{ url: "901/Copy_of_biryani_master.mp4", videoLength: 17000 },
  { url: "902/Copy_of_butter_chicken_master_2.mp4", videoLength: 15000 },
  { url: "903/Copy_of_pasta_master.mp4", videoLength: 18000 },
  { url: "904/Copy_of_narogi_master.mp4", videoLength: 18000 },
  { url: "905/Copy_of_seekh_kebab_master.mp4", videoLength: 16000 },
  { url: "906/Copy_of_chicken_tandoori_master.mp4", videoLength: 18000 },
  { url: "907/Copy_of_chicken_bacon_wrapped_master.mp4", videoLength: 20000 },
  ]

  return (
    <div>
      <Swiper
        direction={'vertical'}
        modules={[EffectFade]}
        pagination={{
          clickable: true,
        }}
        height={screenHeight}
        onInit={(ev) => {
          dispatch(storieAction.setSwiperIndex(ev.activeIndex));
          swiperIndexRef.current = ev.activeIndex;
          set_my_swiper(ev)
        }}
        onSlideChange={(s) => {
          if (s.activeIndex === sortedDailyFirstExploredDishes.length - 1) {
            dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
          }
          dispatch(storieAction.setSwiperIndex(s.activeIndex));
          swiperIndexRef.current = s.activeIndex;
          dispatch(storieAction.setRunJoyride(false));
          setSwiper(s)
          mixpanel.track(`explore dish change`);
          // clevertap.event.push(`explore dish change`);
          if (s.activeIndex > 0) {
            const prevDish = sortedDailyFirstExploredDishes[s.activeIndex - 1];
            exploreDishesApi(prevDish)
          }
          if (s.activeIndex === 1) {
            if (!isLocationValid) {
              setShowLocationModal(true)
            }
            console.log('slide change')
          }
          if (!showInstallModalAnsweredRef.current && s.activeIndex === 2) {
            if (!isAppInstalled && !isIosAppInstalled) {
              setShowInstallModal(true)
            } else if (!foodChoiceState) {
              setShowChoiceModal(true)
            }
            console.log('slide change')
          }
        }}
        style={{ height: `${adjustedHeight}px`, background: "#000" }}
        onSwiper={(s) => setSwiper(s)}
        className='explore-swiper-wrapper'
      >
        {sortedDailyFirstExploredDishes.map((dish, index) => {
          const labelsArrayValue = getLabelsArrayValue(dish, exploreVideos[index]);
          const descriptions = dish.stories.map((item) => item?.description);
          const headers = dish?.name;
          const price = dish?.price
          const chefId = dish?.chefId
          const description = dish?.description
          const chefImgs = dish?.chefImg;
          const chefNames = dish?.chefName;
          const labels = dish.stories.map((d) => ({ key: d.header, desc: d.caption, img: d.img, videoLength: d?.videoLength, demoLabel: d?.demoLabel, demoDesc: d?.demoDesc, storyType: d?.storyType, storyHeader: d?.storyHeader, dishNameHeader: d?.dishNameHeader }));
          const labelsArray = getLabelsArray(dish, exploreVideos[index], labels);
          const storiesArrayData = labelsArray.map((label, i) => ({
            content: ({ action, isPaused }) => {
              // if (!isActive) {
              //   setTimeout(() => {
              //     action('pause');
              //   }, 0);
              // }
              // else if (!isPaused) {
              //   setTimeout(() => {
              //     action('play');
              //   }, 0);
              // }
              if (showInstallModal || showChoiceModal) {
                action('pause');
                // mixpanel.track(`explore story paused ${dish._id}`);
                // clevertap.event.push(`explore story paused ${dish._id}`);
              }
              return (
                <ExploreStory
                  key={dish._id}
                  dishKey={i}
                  img={label.img || dish.stories[0].img}
                  action={action}
                  labels={labels}
                  labelDesc={label}
                  dishInfo={dish}
                  isPaused={isPaused}
                  // isActive={isActive}
                  // isNext={isNext}
                  // isPrev={isPrev}
                  swiper={swiper}
                  headers={headers}
                  exploreIndex={index}
                  name={dish?.categoryHeader}
                  categoryImg={dish.categoryImg ? S3_MENU_THUMBNAIL_FOLDER + dish.categoryImg : ""}
                  price={price}
                  dishSpecialType={dish?.dishSpecialType}
                  chefId={chefId}
                  description={descriptions[i] || description}
                  storyImages={dish.storyImages}
                  chefImg={chefImgs}
                  chefName={chefNames}
                  categoryId={dish.categoryId}
                />
              );
            },
            preloadResource: true,
            duration: label?.videoLength + 1000 || 5000
          }))
          return (
            <SwiperSlide key={index}>
              <ReactInstaStories
                key={index}
                isPaused={true}
                loop
                stories={storiesArrayData}
                defaultInterval={5000}
                width="100%"
                preloadCount={2}
                height={screenHeight}
                progressContainerStyles={{ top: '2px' }}
                onStoryStart={(s) => {
                  if (index === swiperIndexRef.current) {
                    startTimestampRef.current = performance.now();
                    storySlideNumber = s;
                  }
                }}
                onStoryEnd={(s) => {
                  mixpanel.track(`explore story end`);
                  // clevertap.event.push(`explore story end`);
                  isAutoStoryEndRef.current = true;
                  exploreDishesApi(dish)
                  if (index === swiperIndexRef.current) {
                    storySlideNumber = s;
                  }
                  if (startTimestampRef.current !== null) {
                    const endTimestamp = performance.now();
                    const timeDifference = endTimestamp - startTimestampRef.current;
                    if (timeDifference > 5300) {
                      const pausedDishesIds = user.pausedDishesIds || [];
                      const allDishesPausedIds = [...new Set([...pausedDishesIds, dish._id])]
                      const payload = { pausedDishesIds: allDishesPausedIds };
                      // dispatch(updatePausedDishesInfo(user.userid, payload));
                    }
                    startTimestampRef.current = performance.now();
                  }
                  // if (s === dish.stories.length + 1 - 1) {
                  if (s === labelsArrayValue.length - 1) {
                    if (showInstallModalAnswered || isAppInstalled || isIosAppInstalled) {
                      const canSwipe = swipeToNextSlide();
                      if (canSwipe) {
                        storySlideNumber = 0;
                        my_swiper.slideNext();
                      } else {
                        dispatch(chefActions.onStoryBackClick());
                      }
                    } else {
                      if (!isAppInstalled && !isIosAppInstalled) {
                        setShowInstallModal(true)
                      } else if (!foodChoiceState) {
                        setShowChoiceModal(true)
                      } else {
                        const canSwipe = swipeToNextSlide();
                        if (canSwipe) {
                          storySlideNumber = 0;
                          my_swiper.slideNext();
                        } else {
                          dispatch(chefActions.onStoryBackClick());
                        }
                      }
                    }
                  }
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <RecommendationModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
      <InstallModal isOpen={showInstallModal} setOpen={setShowInstallModal} setShowChoiceModal={setShowChoiceModal} foodChoiceState={foodChoiceState} setShowInstallModalAnswered={handleShowModal} />
      <VegChoiceModal isOpen={showChoiceModal} setOpen={setShowChoiceModal} setFoodChoiceState={handleFoodChoiceState} />
      <LocationChoiceModal isOpen={showLocationModal} setOpen={setShowLocationModal} loading={loading} isLocationValid={isLocationValid} manualLocationClick={manualLocationClick} onGeoLocationClickHandler={onGeoLocationClickHandler} />
      <BottomBar isExplore />
    </div>
  )
}

export default Explore
