import React, { useState } from "react";
import "./location-selection.css";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import LocationSVGComponent from "../jsxsvg/LocationSVGComponent";
// import clevertap from "clevertap-web-sdk";
// import useGeoLocation from "../../hooks/useGeoLocation";

const LocationSelection = () => {
  // const city = useGeoLocation();
  const dispatch = useDispatch();

  const switchTab = (event) => {
    // event.preventDefault();
    console.log("loc-click");
    dispatch(bottomBarActions.loadTab({ tabName: "locationSelector" }));
    mixpanel.track("location-clicked");
    // clevertap.event.push("location-clicked");
    return false;
  };

  const location = useSelector(({ auth }) => auth.location);
  const selectedAddress = useSelector(({ auth }) => auth.selectedAddress);

  return (
    <li
      onClick={() => switchTab("locationSelector")}
      className="select-location"
    >
      {selectedAddress?.tag ? (
        <span>
        <span className="form-select Satoshi">{selectedAddress.tag.length >= 15 ? selectedAddress.tag.slice(0, 12) + "..." : selectedAddress.tag}
        <LocationSVGComponent /> </span> </span>
      ) : (
        <span>
        <span className="form-select Satoshi">
          {location
            ? location.length >= 15
              ? location.slice(0, 15) + "..."
              : location
            : "Mumbai"}
            <LocationSVGComponent />
        </span>
        </span>
      )}
      {selectedAddress?.tag && location && (
        <span className="locationName Satoshi" onClick={() => switchTab("locationSelector")}>
          {location
            ? location.length >= 20
              ? location.slice(0, 20) + "..."
              : location
            : "Mumbai"}
        </span>
      )}
    </li>
  );
};

export default LocationSelection;
