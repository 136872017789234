import * as React from "react";
const StarIconSvg = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.91225 1.50897L7.83763 3.37213C7.96232 3.6298 8.29703 3.87426 8.57924 3.92712L10.2528 4.20461C11.3226 4.383 11.5719 5.16261 10.8041 5.94223L9.49805 7.25702C9.28147 7.47505 9.15678 7.9045 9.22897 8.21502L9.60306 9.84033C9.89839 11.1221 9.21585 11.6242 8.09358 10.9503L6.52504 10.0121C6.24283 9.84033 5.7703 9.84033 5.48809 10.0121L3.91955 10.9503C2.79728 11.6176 2.11473 11.1221 2.41007 9.84033L2.78416 8.21502C2.84322 7.89789 2.71853 7.46844 2.50195 7.25041L1.19592 5.93563C0.428057 5.16261 0.677449 4.383 1.74721 4.198L3.42076 3.92051C3.70297 3.87426 4.03768 3.6232 4.16237 3.36553L5.08775 1.50237C5.5931 0.498112 6.4069 0.498112 6.91225 1.50897Z"
      fill="#00BB6D"
    />
  </svg>
);
export default StarIconSvg;
