import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import Sheet from "react-modal-sheet";
import AddIcon from "@material-ui/icons/Add";
// import "./menu-topbar.css";
import { dishActions } from "../../store/dishSlice";
import mixpanel from "mixpanel-browser";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import HomeSvgIcon from "../../assets/ReactSvg/HomeSvgIcon";
import LocationSVGComponent from "../jsxsvg/LocationSVGComponent";
import CloseModalIcon from "../../assets/ReactSvg/CloseModalIcon";
import { MyLocation } from "@material-ui/icons";
import LocationSelector from "../location/LocationSelector";
import { ToastContainer } from "react-toastify";
import PinMapScreen from "../location/PinMapScreen";
import { addNewAddress, deleteUserAddress, updateUserAddress } from "../../store/loginActions";
import RecentSearches from "../location/RecentSearches";
import { loginActions } from "../../store/loginSlice";
// import clevertap from "clevertap-web-sdk";

const CartMenuTopBar = forwardRef((props, ref) => {
  const { chefList, cartData, isAreaServiceable } = props;
  const isAddressUpdateSuccess = useSelector(
    ({ auth }) => auth.isAddressUpdateSuccess
  );
  const location = useSelector(({ auth }) => auth.location);
  const recentSearchesRef = useRef();
  const chefIds = cartData.map((c) => c.chefId);
  const userid = useSelector(({ auth }) => auth.user.userid);
  const cartChefList = chefList.filter((chef) => chefIds.includes(chef._id));
  const cartChefLabel = cartChefList.map((chef) => chef.name).join(", ");
  const [addressBottomModal, setAddressBottomModal] = useState(false);
  const [pinMapScreenVisible, setPinMapScreenVisible] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [showSaveLocationPopup, setShowSaveLocationPopup] = useState(false);
  const [isLocationManuallySelected, setIsLocationManuallySelected] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const dispatch = useDispatch();
  const onBackClick = () => {
    if (!props.notMenu) {
      dispatch(chefActions.onBackClick());
      dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
    }
    props.tilesClicked
      ? dispatch(dishActions.updateTilesClicked())
      : dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    mixpanel.track("menu-back-clicked");
    // clevertap.event.push("menu-back-clicked");
  };

  const handleOpenAddressModal = () =>{
    setAddressBottomModal(true)
  }

  useImperativeHandle(ref, () =>{
    return {
      handleOpenAddressModal
    }
  })

  const locationSelectClicked = (event) => {
    dispatch(bottomBarActions.loadTab({ tabName: "locationSelector" }));
  };
  
  const isAddressSelectedSuccessfully = () => {
    setAddressBottomModal(false);
  };


  useEffect(() => {
    if (isAddressUpdateSuccess) {
      setPinMapScreenVisible(false);
      dispatch(loginActions.setAddressUpdateSuccess(false));
      setSelectedAddress({});
      setOpen(false);
      setEditAddress({})
    }
  }, [isAddressUpdateSuccess]);
  const handleAddNewAddress = () => {
    setPinMapScreenVisible(true);
    setShowSaveLocationPopup(true);
    setEditAddress({});
  };

  const onConfirmLocationClick = () => {
    if(editAddress?._id){
      dispatch(updateUserAddress(userid, editAddress._id, editAddress));
    }else{
      dispatch(addNewAddress(userid, editAddress));
    }
  };

  const onDeleteLocationClick = () => {
    dispatch(deleteUserAddress(userid, selectedAddress._id));
  };

  return (
    <div className="common-nav">
      <div className="back-btn-wrapper">
        <button
          onClick={props.onBackClick ? props.onBackClick : onBackClick}
          type="button"
          className="btn back-btn"
        >
          <BackBtnSvg />
        </button>
      </div>
      <div className="nav-chef-detail">
        <div className="chef-title Satoshi">{cartChefLabel}</div>
        <div className="chef-caption">
          <HomeSvgIcon size={16} fill="#E77A45" />
          <div className="chef-caption-address-label">
            <div className="cartAddressDeliveryLabel Satoshi">
              {props.name + (props.notMenu ? "" : "’s Menu")}
            </div>
            <span className="chef-caption-address-label-dot"></span>
            <div className="d-flex cartAddressDeliveryLocationWrapper">
              <div className="cartAddressDeliveryLocation Satoshi">
                {location
                  ? location.length >= 20
                    ? location.slice(0, 20) + "..."
                    : location
                  : "Mumbai"}
              </div>
              <span
                style={{ height: 20 }}
                onClick={() => setAddressBottomModal(true)}
              >
                <LocationSVGComponent size={20} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <Sheet
        springConfig={{
          stiffness: 300,
          damping: 30,
          mass: 0.2,
        }}
        isOpen={addressBottomModal}
        onClose={() => {
          setAddressBottomModal(false);
        }}
        detent="content-height"
        className="install-modal-popup customReactSheet"
      >
        <Sheet.Container>
          <Sheet.Content>
            <div
              onClick={() => {
                setAddressBottomModal(false);
              }}
              className="closeIconInstallModal"
            >
              <CloseModalIcon />
            </div>
            <div className="paymentModeModalContentWrapper">
              <div className="cartSelectDeliveryAddressHeader Satoshi">
                Select delivery address
              </div>
              <>
                <div
                  style={{ display: "none" }}
                  className="store_location_click_data"
                ></div>
                <div
                  style={{ padding: 0, marginBottom: 16 }}
                  className="locationSearchInputContainer"
                >
                  <div className="locationSearchInputWrapper">
                    <div
                      className="current_location_button gps_wrapper"
                      onClick={handleAddNewAddress}
                    >
                      <AddIcon style={{ color: "#851D1E" }} />
                      <div className="gps_content cartMenuPaymentModalAddressLabel Satoshi">
                        Add new address
                      </div>
                    </div>
                  </div>
                </div>
                <RecentSearches
                  initialRender={false}
                  ref={recentSearchesRef}
                  setIsLocationManuallySelected={setIsLocationManuallySelected}
                  isOpen={isOpen}
                  setOpen={setOpen}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  setPinMapScreenVisible={setPinMapScreenVisible}
                  setEditAddress={setEditAddress}
                  showContentInModal={true}
                  onDeleteLocationClick={onDeleteLocationClick}
                  isAddressSelectedSuccessfully={isAddressSelectedSuccessfully}
                />
                <ToastContainer limit={1} />
              </>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onClick={() => {
            setAddressBottomModal(false);
          }}
        />
      </Sheet>
      <Sheet
        springConfig={{
          stiffness: 300,
          damping: 30,
          mass: 0.2,
        }}
        isOpen={pinMapScreenVisible}
        onClose={() => {
          setPinMapScreenVisible(false);
        }}
        height={500}
        detent="content-height"
        className="install-modal-popup customReactSheet pinMapScreenSheetWrapperPopup"
      >
        <Sheet.Container>
          <Sheet.Content>
            <div
              onClick={() => {
                setPinMapScreenVisible(false);
              }}
              className="closeIconInstallModal"
            >
              <CloseModalIcon />
            </div>
            <div className="PinMapScreenSheetWrapper">
              <PinMapScreen
                setPinMapScreenVisible={setPinMapScreenVisible}
                editAddress={editAddress}
                setEditAddress={setEditAddress}
                onConfirmLocationClick={onConfirmLocationClick}
                showSaveLocationPopup={showSaveLocationPopup}
                setShowSaveLocationPopup={setShowSaveLocationPopup}
                showContentInModal={true}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onClick={() => {
            setPinMapScreenVisible(false);
          }}
        />
      </Sheet>
    </div>
  );
});

export default CartMenuTopBar;
