
import * as React from "react";
const CloseCircleBorderIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0013 18.3334C14.5846 18.3334 18.3346 14.5834 18.3346 10.0001C18.3346 5.41675 14.5846 1.66675 10.0013 1.66675C5.41797 1.66675 1.66797 5.41675 1.66797 10.0001C1.66797 14.5834 5.41797 18.3334 10.0013 18.3334Z"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.64062 12.3583L12.3573 7.6416"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3573 12.3583L7.64062 7.6416"
      stroke="#676C6F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CloseCircleBorderIcon;
