import * as React from "react";

const ReOrderSvg = () => {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7046 18.7021C10.4963 18.5208 10.4744 18.205 10.6557 17.9967L12.0749 16.3664C11.4379 16.521 10.7472 16.6167 9.99961 16.6167C5.7318 16.6167 2.27461 13.1595 2.27461 8.89168C2.27461 4.62387 5.7318 1.16668 9.99961 1.16668C14.2674 1.16668 17.7246 4.62387 17.7246 8.89168C17.7246 10.4774 17.2433 11.9567 16.424 13.1857C16.2708 13.4155 15.9604 13.4775 15.7306 13.3244C15.5008 13.1712 15.4387 12.8608 15.5919 12.631C16.3059 11.56 16.7246 10.2726 16.7246 8.89168C16.7246 5.17615 13.7151 2.16668 9.99961 2.16668C6.28408 2.16668 3.27461 5.17615 3.27461 8.89168C3.27461 12.6072 6.28408 15.6167 9.99961 15.6167C10.8129 15.6167 11.5519 15.4888 12.2264 15.2906L10.3381 13.9122C10.115 13.7494 10.0662 13.4366 10.229 13.2135C10.3918 12.9905 10.7046 12.9417 10.9277 13.1045L13.7312 15.151C13.7386 15.1562 13.7458 15.1617 13.753 15.1675C13.7987 15.2038 13.8363 15.2467 13.8657 15.2938C13.9449 15.4211 13.9633 15.5791 13.9138 15.722C13.8979 15.7677 13.8751 15.812 13.845 15.8531C13.8408 15.8589 13.8365 15.8646 13.832 15.8702C13.8685 15.8246 13.8957 15.7744 13.9138 15.722M13.8144 15.8911L11.41 18.6533C11.2287 18.8616 10.9129 18.8834 10.7046 18.7021" fill="#851D1E"/>
    </svg>
    
  );
};

export default ReOrderSvg;
