import React, { useEffect, Suspense, useLayoutEffect } from "react";
import BottomBar from "../BottomBar";
import Dish from "../menu/Dish";
import cartBg from "../../assets/cartBg.png";
import browseLogo from "../../assets/browseLogo.png";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useSelector, useDispatch } from "react-redux";
import "./dishBook.css";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { SuspenseImg } from "../util/SuspendImg";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ImageLoader from "../chef/ImageLoader";
import "../menu/browse-menu-button.css";
import ReactGA from "react-ga4";
import { setBookingData } from "../../store/loginActions";
import OrderHistory from "./orderHistory";
import BackIconSvg from "../../assets/ReactSvg/BackIconSvg";
import SearchSVGComponent from "../jsxsvg/SearchSVGComponent";
import { useState } from "react";
import { fetchChefList } from "../../store/chefActions";

const DishBook = () => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "dishBook",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const [query, setQuery] = useState("");
  const [filteredBookingHistory, setFilteredBookingHistory] = useState([]);
  const dispatch = useDispatch();
  const userBookingHistory = [...user.booking].reverse();

  const searchBookingHistory = (searchQuery) =>{
    const query = searchQuery.toLowerCase(); 
    const searchResult = userBookingHistory.filter(order => {
        const orderMatches =
            order.orderRef.toLowerCase().includes(query) ||
            order.billingAmount.toString().includes(query);

        const dishesMatch = order.bookedDishes.some(dish => 
            dish.dish.name.toLowerCase().includes(query) ||
            dish.dish.chefName.toLowerCase().includes(query)
        );

        return orderMatches || dishesMatch;
    });
    setFilteredBookingHistory(searchResult)
  }

  useEffect(() => {
    searchBookingHistory(query);
  }, [query]);

  useEffect(() => {
    dispatch(setBookingData(user.userid));
  }, [dispatch, user.userid]);


  useEffect(() =>{
    dispatch(fetchChefList());
  },[])

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);

  const onBackClick = () => {
    switchTab("profile");
  };

  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="order_history_wrapper">
          <div className="order_history_div_wrapper">
            <div
              className="location-search_back-btn"
              onClick={() => {
                onBackClick();
              }}
            >
              <BackIconSvg />
            </div>
            <span className="Satoshi enterLocationHeaderLabel">
              Order history
            </span>
          </div>
        </div>
        <div className="orderhistorylocationSearchInput">
          <label htmlFor="searchAutoComplete" className="location-search_input">
            <SearchSVGComponent />
            <input
              id="searchAutoComplete"
              type="text"
              name="area"
              autoFocus
              onChange={({ target }) => {
                setQuery(target.value);
              }}
              className="locationSearchInputBox"
              placeholder="Search by chef or dish"
              value={query}
            />
          </label>
        </div>
        <section
          className="section-food-accordian"
          style={{
            paddingTop: 0,
            backgroundColor: "var(--light-peach)",
            marginTop: "2px",
          }}
        >
          {user.booking && user.booking.length > 0 ? (
            <div
              className="food-accordian"
              style={{
                padding: "20px",
                margin: "0",
              }}
            >
              <div>
                {filteredBookingHistory.map((data, index) => (
                  <React.Fragment key={index}>
                    <OrderHistory data={data} />
                  </React.Fragment>
                ))}
                {/* {[...orders].map((orderData, index) => (
                  <React.Fragment key={index}>
                    <OrderHistory data={orderData} />
                  </React.Fragment>
                ))} */}
              </div>
            </div>
          ) : (
            <>
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>Pre-order now, Pay later!</h5>
                  <p>
                    You can pre-order 2 dishes from the first 8 chefs now.{" "}
                    <br />
                    When we launch, you can pay and schedule your delivery.
                  </p>
                  <button onClick={() => switchTab("chef")}>
                    <img src={browseLogo} alt="browseLogo" />
                    BROWSE Chefs
                  </button>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default DishBook;
