import * as React from "react";
const DownArrowSvgIcon = (props) => (
  <svg
    width={10}
    height={7}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 2L5 6L9 2"
      stroke="#404548"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownArrowSvgIcon;
