import React, { useEffect, useRef, useState } from "react";
import "./pinMapScreen.css";
import BackIconSvg from "../../assets/ReactSvg/BackIconSvg";
import { extractPincode, getLocationIcon } from "../../utils/helper";
import { MyLocation } from "@material-ui/icons";
import SaveAddressBottomModal from "./SaveAddressBottomModal";
import { useDispatch, useSelector } from "react-redux";
import { uniqBy } from "lodash";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Script from "react-load-script";
import { loginActions } from "../../store/loginSlice";
import axios from "axios";
import {
  API_TEST,
  FREE_DILIVERY_DISTANCE_RANGE,
  MINIMUM_AMOUNT_MAX_RANGE,
  MINIMUM_AMOUNT_MIN_RANGE,
  ORDER_DISTANCE_RANGE,
  ORDER_DISTANCE_RANGE_MIN,
  PIDGE_API_TOKEN,
  PIDGE_API_URL,
  YOUR_GOOGLE_MAPS_API_KEY,
} from "../../store/constants";
import { cartAction } from "../../store/cartSlice";
import { addTempUser } from "../../store/loginActions";
import { toast } from "react-toastify";
import { getAddressFromLatLng } from "../MapTracking/mapHelper";
import SearchSVGComponent from "../jsxsvg/SearchSVGComponent";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const containerModalStyle = {
  width: "100%",
  height: "60vh",
};

const center = {
  lat: 19.076,
  lng: 72.8777,
};

const SelectLocation = ({
  isLoaded,
  markerPosition,
  handleSetMarkerPosition,
  handleMarkerDragEnd,
  showContentInModal,
}) => {
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={
        showContentInModal ? containerModalStyle : containerStyle
      }
      center={markerPosition}
      zoom={14}
      onClick={(e) => {
        handleSetMarkerPosition(e.latLng.lat(), e.latLng.lng());
      }}
    >
      <Marker
        position={markerPosition}
        draggable={true}
        onDragEnd={handleMarkerDragEnd}
      />
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

const PinMapScreen = ({
  setPinMapScreenVisible,
  editAddress,
  setEditAddress,
  onConfirmLocationClick,
  showSaveLocationPopup,
  setShowSaveLocationPopup,
  showContentInModal = false,
}) => {
  const isAddressUpdateLoading = useSelector(
    ({ auth }) => auth.isAddressUpdateLoading
  );
  const dispatch = useDispatch();
  const distanceMatrixRef = useRef(null);
  const autocompleteRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const chefList = useSelector((state) => state.chef.chefList);
  const addresses = user.addressLine1;
  const [markerPosition, setMarkerPosition] = useState(center);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });
  const webviewLocationObjValue =
    JSON.parse(localStorage.getItem("webviewLocationObj")) || {};
  const [webviewLocationObj, setWebviewLocationObj] = useState(
    webviewLocationObjValue
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: YOUR_GOOGLE_MAPS_API_KEY, // Replace with your API key
  });
  const formattedAddress = `
  ${editAddress?.houseNumber || ""}
  ${editAddress?.area ? `, ${editAddress.area}` : ""}
  ${editAddress?.landMark ? `, ${editAddress.landMark}` : ""}
  ${
    editAddress?.location?.locationName
      ? `, ${editAddress.location.locationName}`
      : ""
  }
`.trim();

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" &&
          mutation.target.classList.contains("store_location_click_data")
        ) {
          const newTextContent = mutation.target.textContent;
          try {
            const newData = JSON.parse(newTextContent);
            if (newData.type === "ADD_LOCATION") {
              const { type, ...locationObj } = newData;
              setWebviewLocationObj(locationObj);
              handleSetMarkerPosition(locationObj?.lng, locationObj?.lat);
              localStorage.setItem(
                "webviewLocationObj",
                JSON.stringify(locationObj)
              );
            }
            // setNotificationData(newData);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        }
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSetMarkerPosition = async (lat, lng) => {
    try {
      const fetchedAddress = await getAddressFromLatLng(lat, lng);
      setMarkerPosition({ lat: lat, lng: lng, locationName: fetchedAddress });
      setEditAddress({
        ...editAddress,
        location: { lat: lat, lng: lng, locationName: fetchedAddress },
      });
    } catch (error) {
      setMarkerPosition({ lat: lat, lng: lng });
      setEditAddress({ ...editAddress, location: { lat: lat, lng: lng } });
    }
  };

  const onSuccessFetchLocation = (location) => {
    handleSetMarkerPosition(
      location.coords.latitude,
      location.coords.longitude
    );
  };

  const handleLocateMeClick = () => {
    if (
      Object.keys(webviewLocationObj).length === 0 &&
      window.ReactNativeWebView
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "ASK_LOCATION_PERMISSION" })
      );
      setLoading(false);
      // location permission
    } else if (
      Object.keys(webviewLocationObj).length !== 0 &&
      window.ReactNativeWebView
    ) {
      try {
        handleSetMarkerPosition(
          webviewLocationObj?.lng,
          webviewLocationObj?.lat
        );
      } catch (error) {
        console.error("Error parsing JSON:", error);
      } finally {
        setLoading(false);
      }
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        onSuccessFetchLocation,
        () => {
          console.error("Error in location fetch");
        },
        {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleScriptLoad = () => {
    const options = {
      fields: ["formatted_address", "geometry", "name", "address_component"],
      componentRestrictions: { country: "In" },
      strictBounds: false,
      //types: ["geocode"],
    };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("searchAutoComplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", function () {
      const addressObject = autocompleteRef.current.getPlace();
      if (addressObject && addressObject.geometry) {
        const locationObject = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          area: addressObject.formatted_address,
        };
        setLocation(locationObject);
        handleSetMarkerPosition(locationObject.lat, locationObject.lng);
      }

      setQuery(addressObject ? addressObject.formatted_address : "");
      dispatch(loginActions.setIsLocationEnabled());
    });
  };

  const handleMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    handleSetMarkerPosition(newLat, newLng);
  };

  return (
    <div className="pinMapScreenWrapper">
      <div className="auto_search_wrapper">
        <div
          className="location-search_back-btn"
          onClick={() => {
            setPinMapScreenVisible(false);
          }}
        >
          <BackIconSvg />
        </div>
        <span className="Satoshi enterLocationHeaderLabel">
          Enter your location
        </span>
        <div className="pinMapScreenInputSearchWrapper">
          <div className="locationSearchInput pinMapLocationSearchInput">
            <label
              htmlFor="searchAutoComplete"
              className="location-search_input"
            >
              <SearchSVGComponent />
              <input
                id="searchAutoComplete"
                type="text"
                name="area"
                autoFocus
                onChange={({ target }) => {
                  setQuery(target.value);
                }}
                className="locationSearchInputBox"
                placeholder="Eg. enter area, street name..."
                value={query}
              />
            </label>
          </div>
        </div>
      </div>
      <div>
        <SelectLocation
          isLoaded={isLoaded}
          markerPosition={markerPosition}
          handleSetMarkerPosition={handleSetMarkerPosition}
          handleMarkerDragEnd={handleMarkerDragEnd}
          showContentInModal={showContentInModal}
        />
      </div>
      <div
        style={showContentInModal ? { position: "relative" } : {}}
        className="confirmLocationBottomPopup"
      >
        <div className="locateMeBtnWrapper">
          <button
            className="locateMeBtnContent Satoshi"
            onClick={handleLocateMeClick}
          >
            <MyLocation style={{ color: "#851D1E" }} />
            Locate me
          </button>
        </div>
        <div className="confirmLocationBottomHeaderPopup">
          <div style={{ display: "inline-flex" }}>
            <span style={{ marginTop: 2 }}>
              {getLocationIcon(editAddress?.tag)}
            </span>
            <div className="confirmLocationPopupDescWrapper">
              <div className="confirmLocationPopupTag Satoshi">
                {editAddress.tag}
              </div>
              <div>
                {formattedAddress.length >= 50
                  ? `${formattedAddress.slice(0, 50)}...`
                  : formattedAddress}
              </div>
            </div>
          </div>
          <button
            className="transparentBtn changeLocationBtn Satoshi"
            onClick={() => setShowSaveLocationPopup(true)}
          >
            Change
          </button>
        </div>
        <div className="confirmLocationBottomPopupBtn">
          <button
            disabled={isAddressUpdateLoading}
            className="pinMapConfirmBtn Satoshi"
            onClick={onConfirmLocationClick}
          >
            Confirm location
          </button>
        </div>
      </div>
      {showSaveLocationPopup && (
        <SaveAddressBottomModal
          isOpen={showSaveLocationPopup}
          setPinMapScreenVisible={setPinMapScreenVisible}
          setOpen={setShowSaveLocationPopup}
          editAddress={editAddress}
          setEditAddress={setEditAddress}
        />
      )}
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${YOUR_GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
    </div>
  );
};

export default PinMapScreen;
