import * as React from "react";
const CheckIconSvg = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.44272 18C9.08254 18 8.74037 17.8661 8.48824 17.6317L3.3917 12.8936C2.86943 12.4081 2.86943 11.6045 3.3917 11.1189C3.91396 10.6334 4.77839 10.6334 5.30065 11.1189L9.44272 14.9697L18.6993 6.36414C19.2216 5.87862 20.086 5.87862 20.6083 6.36414C21.1306 6.84967 21.1306 7.6533 20.6083 8.13882L10.3972 17.6317C10.1451 17.8661 9.8029 18 9.44272 18Z"
      fill="#00BB6D"
    />
  </svg>
);
export default CheckIconSvg;
