import { useState, useEffect } from "react";
import CustomizeBottomSlider from "../customizeBottomSlider/Index";
import RemoveDishPortion from "../customizeBottomSlider/RemoveDishPortion";
import { useSelector } from "react-redux";
import { uniq } from "lodash";
import { toast } from "react-toastify";
import AddCartIconSvg from "../../assets/ReactSvg/AddCartIconSvg";
import MinusIconSvg from "../../assets/ReactSvg/MinusIconSvg";
import AddIconSvg from "../../assets/ReactSvg/AddIconSvg";

const DishCheckPrice = ({
  showAdd,
  setShowAdd,
  showAdded,
  portion,
  setShowAdded,
  showSchedule,
  onAddDish,
  onRemoveDish,
  updateDish,
  discountedPrice,
  dishBand,
  dish,
  cartData,
}) => {
  const { isFromCart } = dish;
  const [isOpen, setOpen] = useState(false);
  const [isPortionBottonOpen, setPortionBottonOpen] = useState(false);
  const cartChefIds = cartData.map((a) => a.chefId);
  const chefList = useSelector((state) => state.chef.chefList);
  const allCartChefList =
    chefList
      ?.filter((a) => cartChefIds.includes(a._id))
      ?.map((c) => c.kitchenId) || [];
  const dishPortion = dish?.dish?.portion || "";
  const dishInfo = dish?.dish;
  const cartScreenPortion = dish.dish?.portion || "";
  const categoryScreenPortion =
    cartData
      ?.filter((order) => order._id === dish.id)
      .reduce((prev, curr) => curr?.portion + prev, 0) || 0;

  const totalCartAddedDishCount = isFromCart
    ? cartScreenPortion
    : categoryScreenPortion;
  const isValidCustomizeObj = !!dish?.dish?.customizeObj?.length;
  const isValidVariations =
    !!dish?.dish?.variations?.groupName &&
    !!dish?.dish?.variations?.options?.length;

  const handleAddToCartClick = () => {
    const currentChef = chefList.find((chef) => chef._id === dish.dish.chefId);
    if (
      [...uniq(allCartChefList)].length &&
      ![...uniq(allCartChefList)].includes(currentChef.kitchenId)
    ) {
      //already added chef kitchen location
      toast.error("Please order from one restaurant at a time");
    } else {
      if (
        (!!dish?.dish?.variations?.groupName &&
        !!dish?.dish?.variations?.options?.length) && !isFromCart
      ) {
        setOpen(true);
      } else if (dish?.dish?.customizeObj?.length && !isFromCart) {
        setOpen(true);
      } else {
        onAddDish(dish.id, 0, [], dish?.dish?.cartId);
      }
    }
  };

  const onRemoveDishClick = () => {
    const currentDishesCartData = cartData?.filter(
      (order) => order._id === dish.id
    );

    if (!isFromCart && currentDishesCartData.length > 1) {
      setPortionBottonOpen(true);
    } else {
      onRemoveDish(
        dish.id,
        setShowAdd,
        setShowAdded,
        totalCartAddedDishCount,
        dish?.dish?.cartId
      );
    }
  };

  useEffect(() => {
    if (!showAdd && !showAdded && !showSchedule && !dish.unAvailable) {
      updateDish(
        dish.id,
        setShowAdd,
        dish.price,
        dish.dish.ingredientCost,
        dish.dish.fuelCost,
        dish.dish.packagingCost,
        dish.dish.deliveryCost,
        dishBand.band
      );
    }
  }, [showAdd, showAdded, showSchedule, dish.unAvailable]);
  return (
    <>
      <div className="dish-add-wrapper">
        {!showAdd && !showAdded && !showSchedule && !dish.unAvailable ? (
          <button
            type="button"
            className={
              dish.img
                ? "btn btn-add check-price-btn"
                : "btn btn-add check-price-btn bottom1"
            }
            onClick={() =>
              updateDish(
                dish.id,
                setShowAdd,
                dish.price,
                dish.dish.ingredientCost,
                dish.dish.fuelCost,
                dish.dish.packagingCost,
                dish.dish.deliveryCost,
                dishBand.band
              )
            }
          >
            <span>Check Price</span>
            <img
              src="images/Icons/Icon-06.svg"
              alt="search-item"
              loading="lazy"
            />
          </button>
        ) : (
          ""
        )}
        {showAdd && !showSchedule && !dish.unAvailable ? (
          <button
            type="button"
            className={dish.img ? "btn btn-add" : "btn btn-add bottom1"}
            onClick={handleAddToCartClick}
          >
            <AddCartIconSvg />
            <div style={{ marginLeft: 4 }}>Add</div>
          </button>
        ) : (
          ""
        )}

        {dish.unAvailable && !showSchedule && (
          <button type="button" className="btn btn-add">
            <span>unavailable</span>
            <img
              src="images/access-denied-icon.svg"
              alt="search-item"
              loading="lazy"
            />
          </button>
        )}
        {showAdded && !showSchedule && !dish.unAvailable && (
          <div className="dishCartEditBtn">
            <button className="dishCartAddToCartBtn transparentBtn" onClick={onRemoveDishClick}>
              <MinusIconSvg />
            </button>
            <div className="dishTotalCartAddedDishCount Satoshi">{totalCartAddedDishCount}</div>
            <button
              className="dishCartRemoveToCartBtn transparentBtn"
              onClick={() => handleAddToCartClick(dish.id)}
            >
              <AddIconSvg />
            </button>
          </div>
        )}
        {showSchedule && dish.isScheduleActive ? (
          <div className="input-group add-items item-schedule">
            <button type="button" className="btn btn-added btn-schedule">
              <span> Schedule </span>
            </button>
            <img src="images/time-period.svg" />
          </div>
        ) : (
          ""
        )}
        {!isFromCart && (
          <CustomizeBottomSlider
            isOpen={isOpen}
            discountedPrice={discountedPrice}
            dishId={dish.id}
            onAddDish={onAddDish}
            setOpen={setOpen}
            dishPortion={dishPortion}
            dishInfo={dishInfo}
          />
        )}
        {!isFromCart && (
          <RemoveDishPortion
            dishPortionDetails={cartData?.filter(
              (order) => order._id === dish.id
            )}
            isOpen={isPortionBottonOpen}
            discountedPrice={discountedPrice}
            dishId={dish.id}
            onAddDish={onAddDish}
            setOpen={setPortionBottonOpen}
            setShowAdd={setShowAdd}
            setShowAdded={setShowAdded}
            totalCartAddedDishCount={totalCartAddedDishCount}
            onRemoveDish={onRemoveDish}
            dishPortion={dishPortion}
            dishInfo={dishInfo}
          />
        )}
      </div>
      {(isValidCustomizeObj || isValidVariations) && (
        <div className="customisable-dish-menu-label">Customisable</div>
      )}
    </>
  );
};

export default DishCheckPrice;
