import * as React from "react";
const ArrowDownIconSvg = ({ rotate, ...rest }) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${!rotate ? 180 : 0}deg)`,
      transition: "transform 0.3s ease-in-out", // Smooth rotation
    }}
    {...rest}
  >
    <path
      d="M2.65137 6.1001L7.99985 11.4334L13.3483 6.1001"
      stroke="#676C6F"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowDownIconSvg;
