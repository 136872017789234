import React from "react";
import "./menu-categories.css";
import "../base.css";
import Category from "./Category";
import MenuNotFound from "./MenuNotFound";
import AppContainer from "../util/AppContainer";
import BottomBar from "../BottomBar";
import sampleDishImage from "../../../src/assets/dish-sample-icon.png";
import DefaultChefImage from "../../assets/ChefDefaultIcon.svg";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { getFoodTypeIcon } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import StarIconSvg from "../../assets/ReactSvg/StarIconSvg";
import BackFilledSvgIcon from "../../assets/ReactSvg/BackFilledSvgIcon";
import { dishActions } from "../../store/dishSlice";
import { chefActions } from "../../store/chefSlice";

const MultiDishMenu = (props) => {
  const dispatch = useDispatch();
  const chefList = useSelector((state) => state.chef.chefList);
  const allCategories = useSelector((state) => state.chef.categories);
  const { selectedDishGroup, activeTile, categories } = props;
  const dishImage = selectedDishGroup[0]?.img
    ? S3_MENU_THUMBNAIL_FOLDER + selectedDishGroup[0]?.img
    : selectedDishGroup[0]?.name == "Sides"
    ? "images/sides.jpg"
    : "images/rice.jpg";

  const categoriesList = categories?.filter((a) => a.dishes?.length);
  const dishFoodType =
    selectedDishGroup[0]?.categories[0]?.dishes[0]?.type || "";
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  
  const onChefClick = (chefInfo) => {
    const chefCat = allCategories[chefInfo._id];
    const categoryInfo = chefCat.find((c) => c.name === activeTile);
    dispatch(menuActions.setCurrentChefIdClicked(chefInfo._id));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
    dispatch(bottomBarActions.updateFrom({ from: "dishesScreen" }));
    dispatch(chefActions.onMenuCategorieId(categoryInfo._id));
  };

  
  const handleBackClick = () => dispatch(dishActions.updateTilesClicked())

  return (
    <AppContainer>
      <section className="section-food-accordian menu-categories-accordian">
        <div className="dishGroupImageWrapper">
          <img
            className="dishGroupImage"
            src={selectedDishGroup[0]?.img ? dishImage : sampleDishImage}
          />
          <div className="dishGroupImageAbsolutePosition">
            {getFoodTypeIcon(dishFoodType)}
            <div className="dishGroupNameType Satoshi">{activeTile}</div>
          </div>
          <div onClick={handleBackClick} className="dishGroupBackBtn">
            <BackFilledSvgIcon />
          </div>
        </div>
        <div className="food-accordian multiDishMenuFoodAccordian">
          <div className="multiDishMenuChefCount Satoshi">
            Chefs ({categories.length})
          </div>
          <div className="multiDishMenuChefBoxWrapper">
            {props.noDishes ? (
              <MenuNotFound
                img="images/Empty State-05.svg"
                header="Unlock Chef Menus."
                description="Browse Chefs and unlock their menu. Get quick access to all their dishes here."
              />
            ) : categories?.length > 0 ? (
              categoriesList.map((category, index) => {
                const chefId = category.dishes[0].chefId;
                const chefInfo = chefList.find((c) => c._id === chefId);
                const kitchenInfo = kitchensList.find(
                  (kitchen) => kitchen?._id === chefInfo?.kitchenId
                );
                const chefImage = chefInfo?.profileImg?.includes(
                  "amazonaws.com"
                )
                  ? chefInfo?.profileImg
                  : S3_MENU_THUMBNAIL_FOLDER + chefInfo?.profileImg;
                return (
                  <div className="multiDishMenuChefBoxContainer">
                    <div
                      className="multiDishMenuChefResultContainer"
                      onClick={() => onChefClick(chefInfo)}
                    >
                      <img
                        src={
                          chefInfo?.profileImg ? chefImage : DefaultChefImage
                        }
                        alt="Chef image"
                        className="orderSummaryUserAvatar"
                      />
                      <div className="orderSummaryUserDetails">
                        <div className="orderSummaryUserName Satoshi">
                          {chefInfo.name}
                        </div>
                        <div className="orderSummaryUserLocation Satoshi">
                          {kitchenInfo?.address
                            ? kitchenInfo.address.length >= 40
                              ? kitchenInfo.address.slice(0, 40) + "..."
                              : kitchenInfo.address
                            : ""}
                        </div>
                      </div>
                      <span
                        style={{ marginLeft: "auto" }}
                        className="d-flex align-items-center"
                      >
                        <StarIconSvg />
                        <span
                          style={{ marginLeft: 3 }}
                          className="successRateLabelDish Satoshi"
                        >
                          {chefInfo.successRate || "100"}
                        </span>
                        <span
                          style={{ fontSize: 12 }}
                          className="successRateLabelDish Satoshi"
                        >
                          %
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <MenuNotFound
                img="images/Empty State-06.svg"
                header="No dishes match your search."
                description="Please check menus of other chefs."
              />
            )}
          </div>
        </div>
      </section>
      <BottomBar />
    </AppContainer>
  );
};
export default MultiDishMenu;
