
import * as React from "react";
const CloseCircleIconSvg = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99967 1.83334C4.32634 1.83334 1.33301 4.82668 1.33301 8.50001C1.33301 12.1733 4.32634 15.1667 7.99967 15.1667C11.673 15.1667 14.6663 12.1733 14.6663 8.50001C14.6663 4.82668 11.673 1.83334 7.99967 1.83334ZM10.2397 10.0333C10.433 10.2267 10.433 10.5467 10.2397 10.74C10.1397 10.84 10.013 10.8867 9.88634 10.8867C9.75967 10.8867 9.63301 10.84 9.53301 10.74L7.99967 9.20668L6.46634 10.74C6.36634 10.84 6.23967 10.8867 6.11301 10.8867C5.98634 10.8867 5.85967 10.84 5.75967 10.74C5.56634 10.5467 5.56634 10.2267 5.75967 10.0333L7.29301 8.50001L5.75967 6.96668C5.56634 6.77334 5.56634 6.45334 5.75967 6.26001C5.95301 6.06668 6.27301 6.06668 6.46634 6.26001L7.99967 7.79334L9.53301 6.26001C9.72634 6.06668 10.0463 6.06668 10.2397 6.26001C10.433 6.45334 10.433 6.77334 10.2397 6.96668L8.70634 8.50001L10.2397 10.0333Z"
      fill="#676C6F"
    />
  </svg>
);
export default CloseCircleIconSvg;
