import React from "react";
import LocationIcon from "./location/LocationIcon";
import LocationSelection from "./location/LocationSelection";
import "./top-bar.css";

const TopBar = () => {
  return (
    <div className="search-nav-wrapper">
      <ul className="search-nav">
        <LocationIcon />
        <LocationSelection />
        {/* <SearchButton /> */}
      </ul>
      
    </div>
  );
};

export default TopBar;
