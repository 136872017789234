
import * as React from "react";
const DishesIconSvg = (props) => {
  const fillColor = props.active ? "#851D1E" : "#676C6F";
  return <svg
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <path
    d="M15.8083 18.3333H4.1416C1.6416 18.3333 1.6416 17.2083 1.6416 15.8333V15C1.6416 14.5416 2.0166 14.1666 2.47493 14.1666H17.4749C17.9333 14.1666 18.3083 14.5416 18.3083 15V15.8333C18.3083 17.2083 18.3083 18.3333 15.8083 18.3333Z"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.2668 10.8333V14.1666H2.7251V10.8333C2.7251 7.63329 4.98343 4.95829 7.99176 4.31663C8.44176 4.21663 8.90843 4.16663 9.39176 4.16663H10.6001C11.0834 4.16663 11.5584 4.21663 12.0084 4.31663C15.0168 4.96663 17.2668 7.63329 17.2668 10.8333Z"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.0832 3.74996C12.0832 3.94996 12.0582 4.13329 12.0082 4.31663C11.5582 4.21663 11.0832 4.16663 10.5998 4.16663H9.3915C8.90817 4.16663 8.4415 4.21663 7.9915 4.31663C7.9415 4.13329 7.9165 3.94996 7.9165 3.74996C7.9165 2.59996 8.84984 1.66663 9.99984 1.66663C11.1498 1.66663 12.0832 2.59996 12.0832 3.74996Z"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.5 9.16663H7.5"
    stroke={fillColor}
    strokeWidth={1.4}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
};
export default DishesIconSvg;
