import * as React from "react";
const ShareSvgIcon = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.66699 7.8333L14.1337 2.36664"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6668 5.03331V1.83331H11.4668"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33301 1.83331H5.99967C2.66634 1.83331 1.33301 3.16665 1.33301 6.49998V10.5C1.33301 13.8333 2.66634 15.1666 5.99967 15.1666H9.99967C13.333 15.1666 14.6663 13.8333 14.6663 10.5V9.16665"
      stroke="#404548"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ShareSvgIcon;
