import React, { useEffect, useState } from "react";
import "./menu-search.css";
import "../base.css";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
import AntdCustomInput from "../AntdCustomInput/Index";
import SearchSVGComponent from "../jsxsvg/SearchSVGComponent";
import FilterSvgIcon from "../../assets/ReactSvg/FilterSvgIcon";
import DownArrowSvgIcon from "../../assets/ReactSvg/DownArrowSvgIcon";
import VegSvgIcon from "../../assets/ReactSvg/VegSvgIcon";
import EggSvgIcon from "../../assets/ReactSvg/EggSvgIcon";
import NonVegSvgIcon from "../../assets/ReactSvg/NonVegSvgIcon";
import DailyMealSvgIcon from "../../assets/ReactSvg/DailyMealSvgIcon";
import WeeklyMealSvgIcon from "../../assets/ReactSvg/WeeklyMealSvgIcon";
import VeganSvgIcon from "../../assets/ReactSvg/VeganSvgIcon";
import SpicySvgIcon from "../../assets/ReactSvg/SpicySvgIcon";
import FilterBottomModal from "./FilterBottomModal";
import CloseCircleBorderIcon from "../../assets/ReactSvg/CloseCircleBorderIcon";
import MicrophoneIconSvg from "../../assets/ReactSvg/MicrophoneIconSvg";
import CloseMaroonIconSvg from "../../assets/ReactSvg/CloseMaroonIconSvg";
// import clevertap from "clevertap-web-sdk";

const MenuSearch = ({
  setCategoriesdata,
  categories,
  menuIds,
  setMenuIdsData,
  showAntdInput = false,
  dishSeachText,
  setDishSeachText,
}) => {
  const dishesTab = useSelector((state) => state.bottomBar.dishes);
  const tilesClicked = useSelector((state) => state.dish.tilesClicked);
  const [isOpen, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    veg: false,
    egg: false,
    nonVeg: false,
    dailyMeal: false,
    weeklyMeal: false,
    vegan: false,
    spicy: false,
  });

  // Handler for toggling filters
  const toggleFilter = (filter) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };

  const checkDishFilterApplied = (dishInfo, selectedFilters, category) => {
    const { veg, egg, nonVeg, dailyMeal, weeklyMeal, vegan, spicy } =
      selectedFilters;
      if(!veg && !egg && !nonVeg && !dailyMeal && !weeklyMeal) return true
      
    const isVegMatch = dishInfo?.type === "veg" && veg;
    const isEggMatch = dishInfo?.type === "egg" && egg;
    const isNonVegMatch = dishInfo?.type === "non veg" && nonVeg;
    const isDailyVegMatch = category.name === "Daily specials" && dailyMeal;
    const isWeeklyMatch = category.name === "Weekly specials" && weeklyMeal;
    return (
      isVegMatch ||
      isEggMatch ||
      isNonVegMatch ||
      isDailyVegMatch ||
      isWeeklyMatch
    );
  };

  const filterDishes = (category, searchValue) => {
    return category.dishes.filter(
      (dish) =>
        (searchValue &&
          (dish.name?.toLowerCase().includes(searchValue) ||
            dish.description?.toLowerCase().includes(searchValue))) ||
        checkDishFilterApplied(dish, selectedFilters, category)
    );
  };

  const processCategories = (
    categoriesList,
    lowercasedValue,
    selectedFilters
  ) => {
    const filteredMenuIds = [];
    const filteredData = categoriesList
      .map((category, index) => {
        if (dishesTab && !tilesClicked) {
          const hasMatchingDish = category.categories.some((subcategory) => {
            // if(category.name === "Main course - Chicken"){
            // console.log('subcategory', subcategory)
            // }
            return subcategory.dishes.some((dish) => {
                return (
                  (lowercasedValue &&
                    (dish.name?.toLowerCase().includes(lowercasedValue) ||
                      dish.description?.toLowerCase().includes(lowercasedValue) ||
                      category.name?.toLowerCase().includes(lowercasedValue))) ||
                  checkDishFilterApplied(dish, selectedFilters, category)
                );
            });
          });
          return hasMatchingDish ? category : null;
        } else {
          const filteredDishes = filterDishes(category, lowercasedValue);
          if (filteredDishes.length > 0) {
            if (menuIds) filteredMenuIds.push(menuIds[index]);
            return { ...category, dishes: filteredDishes };
          }
        }
        return null;
      })
      .filter(Boolean);

    return { filteredData, filteredMenuIds };
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setDishSeachText(value);
    const lowercasedValue = value?.toLowerCase().trim();

    if (!lowercasedValue) {
      setCategoriesdata(categories);
      return;
    }

    const { filteredData, filteredMenuIds } = processCategories(
      categories,
      lowercasedValue,
      selectedFilters
    );

    if (filteredData.length === 0) {
      setCategoriesdata([]);
    } else {
      setCategoriesdata(filteredData);
      if (menuIds) setMenuIdsData(filteredMenuIds);
    }

    // Track the search event
    mixpanel.track("menu-searched");
    // clevertap.event.push("menu-searched");
  };

  useEffect(() => {
    const { filteredData, filteredMenuIds } = processCategories(
      categories,
      dishSeachText?.toLowerCase().trim(),
      selectedFilters
    );

    if (filteredData.length === 0) {
      setCategoriesdata([]);
    } else {
      setCategoriesdata(filteredData);
      if (menuIds) setMenuIdsData(filteredMenuIds);
    }
  }, [
    selectedFilters.veg,
    selectedFilters.egg,
    selectedFilters.nonVeg,
    selectedFilters.dailyMeal,
    selectedFilters.weeklyMeal,
    selectedFilters.vegan,
    selectedFilters.spicy,
  ]);

  if (showAntdInput) {
    return (
      <div className="dishesTabMenuContainer">
        <div className="topChefSearchBarwrapper">
          <AntdCustomInput
            className="chefMainScreenSearchBar Satoshi"
            size="large"
            placeholder="Search for dishes"
            prefix={<SearchSVGComponent />}
            onChange={handleChange}
            style={{ width: "100%" }}
            allowClear={{ clearIcon: <CloseCircleBorderIcon /> }}
            addonAfter={<MicrophoneIconSvg />}
            value={dishSeachText}
          />
        </div>
        <div className="filterTransparentBtnWrapper">
          <button
            className="transparentBtn filterTransparentBtn Satoshi filterMainBtn"
            onClick={() => setOpen(true)}
          >
            <FilterSvgIcon />
            Filters
            <DownArrowSvgIcon />
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.veg ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("veg")}
          >
            <VegSvgIcon />
            Veg
            {selectedFilters.veg && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.egg ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("egg")}
          >
            <EggSvgIcon />
            Egg
            {selectedFilters.egg && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.nonVeg ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("nonVeg")}
          >
            <NonVegSvgIcon />
            Non-veg
            {selectedFilters.nonVeg && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.dailyMeal ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("dailyMeal")}
          >
            <DailyMealSvgIcon />
            Daily meals
            {selectedFilters.dailyMeal && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.weeklyMeal ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("weeklyMeal")}
          >
            <WeeklyMealSvgIcon />
            Weekly special
            {selectedFilters.weeklyMeal && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.vegan ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("vegan")}
          >
            <VeganSvgIcon />
            Vegan
            {selectedFilters.vegan && <CloseMaroonIconSvg />}
          </button>
          <button
            className={`transparentBtn filterTransparentBtn Satoshi ${
              selectedFilters.spicy ? "activeFilterBtn" : ""
            }`}
            onClick={() => toggleFilter("spicy")}
          >
            <SpicySvgIcon />
            Spicy
            {selectedFilters.spicy && <CloseMaroonIconSvg />}
          </button>
        </div>
        <FilterBottomModal
          isOpen={isOpen}
          setOpen={setOpen}
          defaultSelectedFilters={selectedFilters}
          setDefaultSelectedFilters={setSelectedFilters}
        />
      </div>
    );
  }
  return (
    <section className="section-search-items">
      <div className="row">
        <div className="col">
          <div className="input-group">
            <button className="btn btn-search-item append-item-left">
              <img
                src="images/search_item.svg"
                alt="search-item"
                loading="lazy"
              />
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="Search your favorite"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenuSearch;

{
  /* <section className={styles["section-search-items"]}>
      <div className={`${baseStyles["row"]} ${styles["row"]}`}>
        <div className={`${baseStyles["col"]} ${styles["col"]}`}>
          <div className={`${baseStyles["input-group"]} ${styles["input-group"]}`}>
            <button
              className={`${baseStyles["btn"]} ${styles["btn-search-item"]} ${baseStyles["append-item-left"]} ${styles["append-item-left"]}`}
            >
              <img
                src="images/search_item.svg"
                alt="search-item"
                loading="lazy"
              />
            </button>
            <input
              type="text"
              className={`${baseStyles["form-control"]} ${styles["form-control"]}`}
              placeholder="Search for Cuisine"
            />
          </div>
        </div>
        <div className={baseStyles["col-auto"]}>
          <button
            type="button"
            className={`${baseStyles["btn"]} ${styles["btn-menu-bar"]}`}
          >
            <img
              src="images/icon-menu-bar.svg"
              alt="search-item"
              loading="lazy"
            />
          </button>
        </div>
      </div>
    </section> */
}
