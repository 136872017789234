import React, { useEffect, useRef, useState } from "react";
import "./menu-categories.css";
import "../base.css";
import MenuNotFound from "./MenuNotFound";
import DishMenuCategoryCard from "./DishMenuCategoryCard";
import sampleDishImage from "../../../src/assets/dish-sample-icon.png";
import Masonry from "react-responsive-masonry";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { getFoodTypeIcon } from "../../utils/helper";
import ChefSvgIcon from "../../assets/ReactSvg/ChefSvgIcon";
import { dishActions } from "../../store/dishSlice";
import mixpanel from "mixpanel-browser";
import { useDispatch } from "react-redux";

const DishMenuCategories = (props) => {
  const { dishSeachText, activeTile } = props;
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        window.requestAnimationFrame(() => {
          const newWidth = entry.contentRect.width;
          if (Math.abs(newWidth - width) > 1) {
            setWidth(newWidth);
          }
        });
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [width]);

  const elementWidth = (width - 36) / 2;

  const filteredCategories = props.categories;

  const handleDishClick = (catName) => {
    dispatch(dishActions.updateTilesClicked());
    dispatch(dishActions.updateActiveTile(catName));
    mixpanel.track("chef-story-clicked");
    // clevertap.event.push("chef-story-clicked");
  };

  if (dishSeachText) {
    return (
      <div className="dishScreenSearchBoxWrapper">
        {filteredCategories.map((cat) => {
          const categoryImage = cat.img
            ? S3_MENU_THUMBNAIL_FOLDER + cat.img
            : cat.name == "Sides"
            ? "images/sides.jpg"
            : "images/rice.jpg";
          const foodType = cat.categories[0].dishes[0].type;
          return (
            <div
              className="dishScreenSearchSingleDishResult"
              onClick={() =>handleDishClick(cat.name)}
            >
              <img
                className="dishImageTagContent dishScreenSearchSingleDishResultImage"
                src={cat.img ? categoryImage : sampleDishImage}
                alt="profile image"
              />
              <div className="dishScreenSearchSingleDishInfoWrapper">
                <div className="d-flex align-items-center">
                  {getFoodTypeIcon(foodType)}
                  <span className="dishScreenSearchSingleDishName Satoshi">
                    {cat.name}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <ChefSvgIcon color="#676C6F" />
                  <span className="dishScreenSearchChefCountLabel Satoshi">
                    {cat.categories.length}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <section className="section-food-accordian">
      <div ref={divRef} className="food-accordian">
        <ul className="collapsible">
          {filteredCategories?.length > 0 ? (
            <div className="category-card-container">
              <Masonry columnsCount={2} gutter="8px">
                {filteredCategories.map((category, index) => (
                  <DishMenuCategoryCard
                    key={index}
                    index={index}
                    name={category.name}
                    totalDishes={category.categories.length}
                    img={category.img}
                    elementWidth={elementWidth}
                    category={category}
                  />
                ))}
              </Masonry>
            </div>
          ) : (
            <MenuNotFound
              img="images/Empty State-06.svg"
              header="No dishes match your search."
              description="Please check menus of other chefs."
            />
          )}
        </ul>
      </div>
    </section>
  );
};
export default DishMenuCategories;
