import React from "react";
import "./orderSummaryDetails.css";
import CallIconSvg from "../../assets/ReactSvg/CallIconSvg";
import { getFoodTypeIcon } from "../../utils/helper";
import HomeSvgIcon from "../../assets/ReactSvg/HomeSvgIcon";
import CallFilledIconSvg from "../../assets/ReactSvg/CallFilledIconSvg";
import { paymentOptionIcon, paymentOptionLabel } from "../cart/cartHelper";
import HeadPhoneSvgIcon from "../../assets/ReactSvg/HeadPhoneSvgIcon";
import CloseCircleIconSvg from "../../assets/ReactSvg/CloseCircleIconSvg";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import DefaultChefImage from "../../assets/ChefDefaultIcon.svg";
import DeliveryPartnerDefaultIcon from "../../assets/DeliveryPartnerDefaultIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { orderActions } from "../../store/orderSlice";
import { updateOrderAction } from "../../store/loginActions";

const OrderSummaryDetails = ({
  uniqueChefInfoList,
  orderDetails,
  itemTotal,
  platformFees,
  gstFees,
  onSupportClick,
  handleCallDriverButtonClick,
  deliveryTime,
  isOrderDelivered,
  hideRiderInfo
}) => {
  const {
    orderRef = "",
    bookedDishes = [],
    address = {},
    paymentMode,
  } = orderDetails || {};
  const dispatch = useDispatch()
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const username = useSelector(({ auth }) => auth.user.username);
  const phonenumber = useSelector(({ auth }) => auth.user.phonenumber);
  const driverNameReducer = useSelector((state) => state.order.driverName);
  const orderStatusReducerValue = useSelector(
    (state) => state.order.orderStatus
  );
  const orderFulfillmentStatusReducer = useSelector(
    (state) => state.order.orderFulfillment?.status
  );
  const orderStatusReducer = orderStatusReducerValue || orderDetails?.status;

  const enrichedChefList = uniqueChefInfoList.map((chef) => ({
    ...chef,
    chefImage: chef?.profileImg?.includes("amazonaws.com")
      ? chef.profileImg
      : S3_MENU_THUMBNAIL_FOLDER + chef.profileImg,
    kitchenInfo: kitchensList.find(
      (kitchen) => kitchen?._id === chef?.kitchenId
    ),
  }));

  const handleChefCallButtonClick = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleCancelOrderClick = () =>{
    dispatch(updateOrderAction(orderDetails._id, { status: "CANCELLED_BY_CUSTOMER" }))
  }

  const onViewOrderDetailClick = () =>{
    dispatch(orderActions.setOrderDetailPageData(orderDetails));
    dispatch(bottomBarActions.updateFrom({ from: "orderSummary" }));
    dispatch(bottomBarActions.loadTab({ tabName: "orderDetailsPage" }));
  }
  
  return (
    <div className="orderDetailsContainer Satoshi">
      <div className="orderSummaryDetailLinear"></div>
      {!!driverNameReducer && !hideRiderInfo && (
        <div className="orderSummaryRiderInfoWrapper">
          <div className="orderSummaryRiderInfo">
            <img
              src={DeliveryPartnerDefaultIcon}
              alt="Rider image"
              className="orderSummaryUserAvatar"
            />
            <div className="orderSummaryUserDetails">
              {isOrderDelivered ? (
                <div className="orderSummaryRiderDesc Satoshi">
                  Your order has been successfully delivered by our delivery partner{" "}
                  {driverNameReducer} at {deliveryTime}
                </div>
              ) : (
                <div className="orderSummaryRiderDesc Satoshi">
                  Your order will be delivered by our delivery partner{" "}
                  {driverNameReducer} at {deliveryTime}
                </div>
              )}
            </div>
            <button
              style={{ height: 16 }}
              onClick={() => handleCallDriverButtonClick()}
              className="transparentBtn"
            >
              <CallIconSvg />
            </button>
          </div>
        </div>
      )}

      <div className="orderSummaryChefDishInfoWrapper">
        {enrichedChefList.map((chef) => {
          return (
            <div className="orderSummaryUserInfo" key={chef?.kitchenId}>
              <img
                src={chef?.profileImg ? chef.chefImage : DefaultChefImage}
                alt="Chef image"
                className="orderSummaryUserAvatar"
              />
              <div className="orderSummaryUserDetails">
                <div className="orderSummaryUserName Satoshi">{chef.name}</div>
                <div className="orderSummaryUserLocation Satoshi">
                  {chef.kitchenInfo?.address
                    ? chef.kitchenInfo.address.length >= 40
                      ? chef.kitchenInfo.address.slice(0, 40) + "..."
                      : chef.kitchenInfo.address
                    : ""}
                </div>
              </div>
              <button
                style={{ height: 16 }}
                onClick={() =>
                  handleChefCallButtonClick(chef.kitchenInfo?.phoneNumber)
                }
                className="transparentBtn"
              >
                <CallIconSvg />
              </button>
            </div>
          );
        })}
        <div className="orderSummaryOrderInfo">
          <div className="orderSummaryOrderHeader">
            <span className="orderSummaryOrderId Satoshi">
              Order #{orderRef}
            </span>
            <button onClick={onViewOrderDetailClick} className="orderSummaryViewDetails Satoshi transparentBtn">
              View details
            </button>
          </div>
          <div className="orderSummaryOrderItems">
            {bookedDishes.map((dish) => (
              <div className="orderSummaryOrderDishInfoWrapper">
                {getFoodTypeIcon(dish?.dish?.type)}
                <div className="orderSummaryOrderDishInfoDesc Satoshi">
                  {dish.portion} x {dish?.dish?.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="orderSummaryDeliveryAddress">
        <div className="orderSummarySectionTitle Satoshi">Delivery address</div>
        <div className="orderSummaryAddressBox">
          <div className="orderSummaryAddressBoxContainer">
            <div className="orderSummaryAddressLabelWrapper">
              <HomeSvgIcon size={16} />
              <div className="orderSummaryAddressLabel Satoshi">
                {address?.tag}
              </div>
            </div>
            <div className="orderSummaryAddressDetails Satoshi">
              {address?.houseNumber}
              {address?.area && `, ${address?.area}`}
              {address?.landMark && `, ${address?.landMark}`}
              {address?.location?.locationName &&
                `, ${address?.location?.locationName}`}
            </div>
          </div>
          <div className="orderSummaryContactInfo Satoshi">
            <CallFilledIconSvg />
            {username}, +91 {phonenumber}
          </div>
        </div>
      </div>

      <div className="orderSummaryPaymentMode">
        <div className="orderSummarySectionTitle Satoshi">Payment mode</div>
        <div className="orderSummaryPaymentInfo">
          {paymentOptionIcon(paymentMode)}
          <div className="orderSummaryPaymentInfoLabel Satoshi">
            {paymentOptionLabel(paymentMode)}
          </div>
        </div>
      </div>
      <div className="orderSummaryBillingDetailsWrapper">
        <div className="orderSummarySectionTitle Satoshi">Billing details</div>
        <div className="orderSummaryBillingDetails">
          <div className="orderSummaryBillingItemContainer">
            <div className="orderSummaryBillingItem">
              <span className="orderSummaryBillingItemLabel Satoshi">
                Item total
              </span>
              <span className="orderSummaryBillingItemValue Satoshi">
                ₹{itemTotal}
              </span>
            </div>
            <div className="orderSummaryBillingItem">
              <span className="orderSummaryBillingItemLabel Satoshi">
                Delivery fees
              </span>
              <span className="orderSummaryBillingItemValue Satoshi">
                ₹{orderDetails.deliveryFee}
              </span>
            </div>
            <div className="orderSummaryBillingItem">
              <span className="orderSummaryBillingItemLabel Satoshi">
                Platform fee
              </span>
              <span className="orderSummaryBillingItemValue Satoshi">
                ₹{platformFees}
              </span>
            </div>
            <div className="orderSummaryBillingItem">
              <span className="orderSummaryBillingItemLabel Satoshi">GST</span>
              <span className="orderSummaryBillingItemValue Satoshi">
                ₹{gstFees}
              </span>
            </div>
          </div>
          <div className="orderSummaryBillingTotal">
            <span className="orderSummaryBillingTotalLabel Satoshi">
              Grand total
            </span>
            <span className="orderSummaryBillingTotalValue Satoshi">
              ₹{orderDetails.billingAmount}
            </span>
          </div>
        </div>
      </div>

      <div className="orderSummaryActions">
        <div className="orderSummaryHelpSupportWrapper">
          <div
            style={{ height: 53 }}
            onClick={onSupportClick}
            className="orderSummaryAddressBoxContainer"
          >
            <div className="orderSummaryAddressLabelWrapper">
              <HeadPhoneSvgIcon />
              <div className="orderSummaryAddressLabel Satoshi">
                Need help with your order?
              </div>
            </div>
            <button className="orderSummaryHelpSupportBtn Satoshi transparentBtn">
              Get help & support
            </button>
          </div>
        </div>
        <button onClick={handleCancelOrderClick} className="orderSummaryCancelOrder Satoshi transparentBtn">
          <CloseCircleIconSvg /> Cancel order
        </button>
      </div>
    </div>
  );
};

export default OrderSummaryDetails;
