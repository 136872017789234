import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import { paymentOptionIcon, paymentOptionLabel } from "./cartHelper";
import CloseModalIcon from "../../assets/ReactSvg/CloseModalIcon";
import { FormControlLabel, Radio } from "@material-ui/core";

const CartPlaceOrderButton = ({
  totalAmountCost,
  paymentMode,
  setPaymentMode,
  onConfirmHandler,
  disablePlaceOrderBtn,
}) => {
  const [paymentOptionModal, setPaymentOptionModal] = useState(false);

  const handlePaymentClick = (method) =>{
    setPaymentMode(method);
    setPaymentOptionModal(false)
  }
  return (
    <div className="payBottomModalContainer">
      <div className="payBottomModalPaymentOptionWrapper">
        {paymentOptionIcon("cash")}
        <div className="payBottomModalPaymentOptionLabel">
          <div className="payBottomModalPaymentOptionSelectionLabel Satoshi">
            Pay using
          </div>
          <div className="payBottomModalPaymentOptionValue Satoshi">{paymentOptionLabel(paymentMode)}</div>
        </div>
        <button
          className="transparentBtn payBottomModalPaymentChangeBtn Satoshi"
          onClick={() => setPaymentOptionModal(true)}
        >
          Change
        </button>
      </div>
      <button
        className={`payBottomModalContainerConfirmBtn Satoshi ${
          disablePlaceOrderBtn ? "button--loading" : ""
        }`}
        disabled={disablePlaceOrderBtn}
        onClick={onConfirmHandler}
      >
        <div className="payBottomModalContainerConfirmBtnContent">
          Place order I ₹{totalAmountCost}
        </div>
      </button>
      {/* <div className="view-bill">
      <div>&#8377; {totalAmountCost} </div>
    </div>
    <div className="pay-bill" onClick={onProceedToPay}>
      <span>PROCEED TO PAY</span>
    </div> */}
      <Sheet
        springConfig={{
          stiffness: 300,
          damping: 30,
          mass: 0.2,
        }}
        isOpen={paymentOptionModal}
        onClose={() => {
          setPaymentOptionModal(false);
        }}
        detent="content-height"
        className="install-modal-popup customReactSheet"
      >
        <Sheet.Container>
          <Sheet.Content>
            <div
              onClick={() => {
                setPaymentOptionModal(false);
              }}
              className="closeIconInstallModal"
            >
              <CloseModalIcon />
            </div>
            <div className="paymentModeModalContentWrapper">
              <div className="paymentModeModalHeader Satoshi">Payment mode</div>
              <div
                className="paymentModeModalOptionWrapper"
                onClick={() => handlePaymentClick("online")}
              >
                {paymentOptionIcon("online")}
                <div className="paymentModeModalOptionDesc Satoshi">Online</div>
                <Radio
                  className="paymentModeModalOptionRadio"
                  checked={paymentMode === "online"}
                />
              </div>
              <div
                className="paymentModeModalOptionWrapper"
                onClick={() => handlePaymentClick("upi")}
              >
                {paymentOptionIcon("upi")}
                <div className="paymentModeModalOptionDesc Satoshi">
                  UPI on Delivery
                </div>
                <Radio
                  className="paymentModeModalOptionRadio"
                  checked={paymentMode === "upi"}
                />
              </div>
              <div
                className="paymentModeModalOptionWrapper"
                onClick={() => handlePaymentClick("cash")}
              >
                {paymentOptionIcon("cash")}
                <div className="paymentModeModalOptionDesc Satoshi">
                  Cash on Delivery
                </div>
                <Radio
                  className="paymentModeModalOptionRadio"
                  checked={paymentMode === "cash"}
                />
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onClick={() => {
            setPaymentOptionModal(false);
          }}
        />
      </Sheet>
    </div>
  );
};

export default CartPlaceOrderButton;
