import * as React from "react";
const ChefIconSvg = (props) => {
  const fillColor = props.active ? "#851D1E" : "#676C6F";
  return <svg
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <g clipPath="url(#clip0_893_1322)">
    <path
      d="M10.2686 10.3983C10.0165 10.3983 9.80176 10.584 9.80176 10.8021V16.3458C9.80176 16.572 10.0165 16.75 10.2686 16.75C10.5301 16.75 10.7355 16.572 10.7355 16.3458V10.8021C10.7355 10.584 10.5301 10.3983 10.2686 10.3983Z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth={0.6}
      strokeLinecap="round"
    />
    <path
      d="M13.9854 10.3983C13.7333 10.3983 13.5186 10.584 13.5186 10.8021V16.3458C13.5186 16.572 13.7333 16.75 13.9854 16.75C14.2469 16.75 14.4522 16.572 14.4522 16.3458V10.8021C14.4522 10.584 14.2469 10.3983 13.9854 10.3983Z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth={0.6}
      strokeLinecap="round"
    />
    <path
      d="M17.7022 10.3983C17.4501 10.3983 17.2354 10.584 17.2354 10.8021V16.3458C17.2354 16.572 17.4501 16.75 17.7022 16.75C17.9636 16.75 18.1691 16.572 18.1691 16.3458V10.8021C18.1691 10.584 17.9636 10.3983 17.7022 10.3983Z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth={0.6}
      strokeLinecap="round"
    />
    <path
      d="M19.1749 6.8811C18.4452 5.85896 17.2839 5.22564 16.0196 5.22564C15.6557 5.22564 15.2966 5.27872 14.9526 5.38341L14.6497 5.47567L14.4468 5.22159C13.4458 3.96871 11.9763 3.25 10.415 3.25C8.25909 3.25 6.3597 4.6442 5.54868 6.66365C5.50777 6.7658 5.47159 6.87032 5.43613 6.97546C5.20292 6.93598 4.96424 6.91489 4.72098 6.91489C4.15087 6.91489 3.59124 7.03049 3.06247 7.25077C2.55454 7.4712 2.08796 7.77552 1.6942 8.17451C1.30028 8.56287 0.989378 9.02452 0.771675 9.52833C0.553965 10.0531 0.439941 10.6094 0.439941 11.1868C0.439941 11.7536 0.543478 12.3097 0.771675 12.8345C0.989378 13.3488 1.28995 13.8105 1.68386 14.1989C2.07777 14.5977 2.53386 14.9021 3.03146 15.1225C3.5326 15.3416 4.06195 15.4552 4.61036 15.4667C4.61774 15.4672 4.62468 15.469 4.63222 15.469H7.3478C7.59739 15.469 7.80183 15.262 7.80183 15.0093V14.8893C7.80183 14.6367 7.59739 14.4297 7.3478 14.4297H4.76071C4.74136 14.4237 4.72157 14.4194 4.70045 14.4194C3.83996 14.4194 3.03146 14.073 2.41984 13.4539C2.11927 13.1599 1.89108 12.8134 1.72522 12.4253C1.5595 12.0264 1.47664 11.6168 1.47664 11.1868C1.47664 10.7564 1.5595 10.3366 1.72522 9.94826C1.89108 9.55989 2.12961 9.21354 2.41984 8.91968C2.72041 8.61521 3.07282 8.38432 3.45639 8.21638C3.86064 8.04844 4.28571 7.96454 4.72098 7.96454C5.42595 7.96454 6.07907 8.18497 6.6285 8.61521C6.84607 8.78315 7.17779 8.74113 7.35415 8.51038C7.51998 8.27948 7.4785 7.95393 7.25047 7.77552C6.96762 7.55464 6.66203 7.37654 6.34005 7.2397C6.95005 5.49452 8.54279 4.24492 10.4105 4.24492C11.9029 4.24492 13.2736 5.03855 14.0771 6.36785C14.2081 6.58709 14.4694 6.6647 14.6855 6.5509C15.1088 6.33122 15.5567 6.2204 16.0196 6.2204C16.8996 6.2204 17.6915 6.61805 18.2431 7.27679C18.6041 7.70778 19.4204 7.61132 19.1749 6.8811Z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth={0.6}
      strokeLinecap="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_893_1322">
      <rect
        width={20}
        height={15}
        fill="white"
        transform="translate(0 2.5)"
      />
    </clipPath>
  </defs>
</svg>
};
export default ChefIconSvg;
