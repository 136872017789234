import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "./chefListScreen.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  API_TEST,
  PREP_TIME,
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
} from "../../store/constants";
import { getTodayDateValue, secondShiftApplies } from "../../utils/timeHelper";
import moment from "moment";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "video.js/dist/video-js.css";
import CustomVideoPlayer from "../CustomVideoPlayer/Index";
import ChefCardSkeleton from "./ChefCardSkeleton";
import ClockIconSvg from "../../assets/ReactSvg/ClockIconSvg";
import StarIconSvg from "../../assets/ReactSvg/StarIconSvg";
import ExpandIconSvg from "../../assets/ReactSvg/ExpandIconSvg";
import { Collapse, Modal } from "antd";
import NonVegIconSvg from "../../assets/ReactSvg/NonVegSvgIcon";
import VegIconSvg from "../../assets/ReactSvg/VegSvgIcon";
import RightIconSvg from "../../assets/ReactSvg/RightIconSvg";
import classNames from "classnames";
import BackFilledSvgIcon from "../../assets/ReactSvg/BackFilledSvgIcon";
import ChefOfflineBottomModal from "./ChefOfflineBottomModal";
import ChefLockIconSvg from "../../assets/ReactSvg/ChefLockIconSvg";
import ThankYouPopupIconSvg from "../../assets/ReactSvg/ThankYouPopupIconSvg";
import { toast } from "react-toastify";
import axios from "axios";
import { chefActions } from "../../store/chefSlice";

const ChefCardComponent = ({ chef, isChefMainScreen, chefVideo }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.chef.categories);
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const chefId = chef?._id;
  const userId = useSelector(({ auth }) => auth.user.userid);
  const [windowWidth, setWindowWidth] = useState(
    Math.min(window.innerWidth - 30, 550 - 30)
  );
  const [isReady, setIsReady] = useState(false);
  const [isChefOfflineModalOpen, setIsChefOfflineModalOpen] = useState(false);
  const [isVideoReadyToPlay, setIsVideoReadyToPlay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const deliveryDetails = useSelector(({ auth }) => auth.deliveryDetails);
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const chefCategoriesList = categories[chefId] || [];
  const kitchenId = chef?.kitchenId || "";
  const kitchenInfo = kitchensList.find((k) => k._id === kitchenId);
  const dailySpecialDishes =
    chefCategoriesList?.find((chef) => chef.type === "daily")?.dishes || [];
  const weeklySpecialDishes =
    chefCategoriesList?.find((chef) => chef.type === "weekly")?.dishes || [];
  const dailySpecialCatId =
    chefCategoriesList?.find((chef) => chef.type === "daily")?._id || "";
  const weeklySpecialCatId =
    chefCategoriesList?.find((chef) => chef.type === "weekly")?._id || "";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(Math.min(window.innerWidth - 30, 550 - 30));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showModal = async () => {
    setLoading(true);
    const apiUrl = `${API_TEST}add_chef_interest`;
    const body = { chefId, userId };
    try {
      const response = await axios.post(apiUrl, body);
      const responseValue = response.data;
      if (responseValue.statusCode === 200) {
        setIsModalOpen(true);
        setTimeout(() => {
          handleClose();
        }, 2000);
      }
    } catch (error) {
      toast.error("Failed to add in interested");
    }
    setLoading(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onSpecialDishClick = (catId) => {
    const chefId = chef._id;
    dispatch(menuActions.setCurrentChefIdClicked(chefId));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
    dispatch(bottomBarActions.updateFrom({ from: "dishesScreen" }));
    dispatch(chefActions.onMenuCategorieId(catId));
  };

  const onChefClick = (chefInfo) => {
    const chefId = chefInfo._id;
    dispatch(menuActions.setCurrentChefIdClicked(chefId));
    dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
  };

  const todayChefSchedule = getTodayDateValue(chef?.schedules);
  const chefDeliveryDetails = deliveryDetails[kitchenId] || {};
  const delivery_distance = chefDeliveryDetails?.delivery_distance;
  const deliveryDistanceInKMS = Math.round(delivery_distance / 1000);

  const getChefDistance = () => {
    return `${deliveryDistanceInKMS}km`;
  };

  const getStatusLabel = (chefInfo) => {
    const { isSwitchOff, chefWaitTime, schedules = [], kitchenId } = chefInfo;
    const chefDeliveryDetails = deliveryDetails[kitchenId] || {};
    const delivery_distance = chefDeliveryDetails?.delivery_distance;
    const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;
    let delivery_Time = delivery_duration
      ? Math.round(delivery_duration / 60)
      : 10;

    const chefWaitTimeValue = Math.max(PREP_TIME, chefWaitTime || 0);
    const todayChefSchedule = getTodayDateValue(schedules);

    if (!isServiceable) {
      return "Pre-bookings only";
    } else if (isSwitchOff) {
      return "offline";
    } else if (!todayChefSchedule?.isAvailable) {
      const findNextDayIndex = schedules.findIndex(
        (s) => s._id === todayChefSchedule._id
      );
      if (findNextDayIndex === schedules.length - 1) {
        const nextDayInfo = schedules[0];
        const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
        return `Opens tomorrow at ${startTime}`;
      } else {
        const nextDayInfo = schedules[findNextDayIndex + 1];
        const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
        return `Opens tomorrow at ${startTime}`;
      }
    } else if (todayChefSchedule?.online) {
      if (delivery_duration) {
        return `${delivery_Time + chefWaitTimeValue} min`;
      } else {
        return "Open now";
      }
    } else if (todayChefSchedule?.isAvailable) {
      if (
        secondShiftApplies(todayChefSchedule) &&
        todayChefSchedule?.startTime1 &&
        todayChefSchedule?.endTime1
      ) {
        return `Opens at ${moment(
          todayChefSchedule?.startTime1,
          "h:mm A"
        ).format("h a")}`;
      }
      return `Opens at ${moment(todayChefSchedule?.startTime, "h:mm A").format(
        "h a"
      )}`;
    }
  };

  const getStatusClassname = (todayChefSchedule) => {
    if (!isServiceable) {
      return "chef-non-serviciable";
    } else if (todayChefSchedule?.online) {
      return "chef-online";
    } else {
      return "chef-away";
    }
  };

  const getItems = () => [
    {
      key: "1",
      label: (
        <span className="chefInfoListLabel Satoshi">Culinary milestones</span>
      ),
      children: (
        <p>
          <ul className="chefInfoWrapperDescriptionListWrapper Satoshi">
            {chef?.label1 && <li>{`${chef?.label1}`}</li>}
            {chef?.label2 && <li>{`${chef?.label2}`}</li>}
            {chef?.label3 && <li>{`${chef?.label3}`}</li>}
          </ul>
        </p>
      ),
      style: {
        border: "none",
      },
    },
  ];

  const ChefDetailInfoWrapper = () => (
    <div className={`chefDetailInfoWrapper`}>
      <div style={{ width: "100%" }}>
        <div
          className="chefNameContainerWrapper"
        >
          <div className="chefInfoWrapper">
            <div style={isChefCommingSoon ? { filter: "blur(4px)" } : {}} className={`chef-name-light chef-name-label-wrapper Satoshi`}>
              <span
                className={`status-dot ${getStatusClassname(
                  todayChefSchedule
                )}`}
              ></span>
              <div className="chef-name-container">{chef.name}</div>
            </div>
            <div className="d-flex align-items-center">
              <StarIconSvg />
              <span
                style={{ marginLeft: 3 }}
                className="successRateLabel Satoshi"
              >
                {chef.successRate || "100"}
              </span>
              <span
                style={{ fontSize: 12 }}
                className="successRateLabel Satoshi"
              >
                %
              </span>
            </div>
          </div>
          <div className="chefInfoWrapper">
            <div
              className="flex-container"
              onClick={() => setIsChefOfflineModalOpen(true)}
            >
              <span>
                <ClockIconSvg />
              </span>
              <span className={`status-label-light status-label Satoshi`}>
                {getStatusLabel(chef)}
              </span>
              {!isNaN(deliveryDistanceInKMS) && (
                <span className={`distance-label-light distance-label Satoshi`}>
                  {getChefDistance(chef)}
                </span>
              )}
            </div>
            <div className="chefDishesServedLabel Satoshi">
              {chef.dishes} Dishes served
            </div>
          </div>
        </div>
        {!isChefMainScreen &&
          (chef?.label1 || chef?.label2 || chef?.label3) && (
            <div className="chefInfoWrapperDescription">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIconPosition="end"
                className="chefLabelDescriptionCollapse"
                expandIcon={({ isActive }) => (
                  <ExpandIconSvg rotate={isActive ? 90 : 0} />
                )}
                style={{ background: "#fff" }}
                items={getItems()}
              />
            </div>
          )}
        {!!dailySpecialDishes.length && (
          <div className="dailySpecialDishesWrapper">
            {dailySpecialDishes.map((dish, index) => (
              <div
                key={index}
                className="dailySpecialDishesContainer"
                onClick={() => onSpecialDishClick(dailySpecialCatId)}
              >
                <div className="dailySpecialLeftContainer">
                  <div className="dailySpecialDishImageParent">
                    <img
                      className="dailySpecialDishImage"
                      src={dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""}
                      alt="Dish image"
                    />
                  </div>
                  <div className="dailyMealsContainer">
                    <span className="dailyMealsLabel Satoshi gradient-text">
                      Daily Meals - {dish.type === "veg" ? "Veg" : "Non-Veg"}
                    </span>
                    <span className="dailyMealsDesc Satoshi">{`${dish.name} @ ₹${dish.price}`}</span>
                  </div>
                </div>
                <div className="dailySpecialCount Satoshi">{`${index + 1}/${
                  dailySpecialDishes.length
                }`}</div>
              </div>
            ))}
          </div>
        )}
        {!isChefMainScreen && !!weeklySpecialDishes.length && (
          <>
            <div className="weeklySpecialLabel Satoshi">Weekly Special</div>
            <div className="weeklySpecialDishesWrapper">
              {weeklySpecialDishes.map((dish, index) => (
                <div
                  key={index}
                  className="weeklySpecialDishesContainer"
                  onClick={() => onSpecialDishClick(weeklySpecialCatId)}
                >
                  <div className="weeklySpecialDishImageWrapper">
                    <span className="weeklySpecialDishType">
                      {dish.type === "veg" ? <VegIconSvg /> : <NonVegIconSvg />}
                    </span>
                    <img
                      className="weeklySpecialDishImage"
                      src={dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""}
                      alt="Dish image"
                    />
                  </div>
                  <span className="weeklySpecialDishName Satoshi">
                    {dish.name}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
        {!isChefMainScreen && !isChefCommingSoon && (
          <button
            className="exploreMenuLabel Satoshi transparentBtn"
            onClick={() => onChefClick(chef)}
          >
            {`Explore menu`} <RightIconSvg />
          </button>
        )}
        {isChefCommingSoon && (
          <button
            disabled={loading}
            className="chefInteresttedBtn Satoshi"
            onClick={showModal}
          >
            I am interested!
          </button>
        )}
      </div>
    </div>
  );

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("chef");
  };

  const dishesImage = categories[chef._id];
  const cardImages = [chef.profileImg];
  const dishesArrayOfArray = dishesImage
    ?.map((d) => d?.dishes?.length)
    ?.map((category) =>
      category.dishes?.map((dish) => dish.stories?.map((story) => story.img))
    );
  const storyArray = [].concat.apply([], dishesArrayOfArray);
  const dishArrayImages = [].concat
    .apply([], storyArray)
    .map((img) => S3_MENU_FOLDER + img);
  const cardImagesArray = [...cardImages, ...dishArrayImages];
  const slicecardImagesArray = cardImagesArray.slice(0, 1) || [];

  const onChange = () => {};

  const onClickItem = () => {};

  const onClickThumb = () => {};

  const chefVideoUrl = S3_MENU_FOLDER + chefVideo;

  // const CarouselArray = chefVideoUrl && !isChefMainScreen ? [chefVideoUrl, ...slicecardImagesArray] : [...slicecardImagesArray];
  const CarouselArray = [...slicecardImagesArray];

  const onChefVideoReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    onChefVideoReady();
  }, []);

  const handleSetIsVideoReadyToPlay = (value) => {
    // if(videoLoadedIndex <= chefCardComponentIndex){
    //     setVideoLoadedIndex(chefCardComponentIndex + 1);
    // }
    setIsVideoReadyToPlay(value);
  };
  useEffect(() => {
    // if (isChefMainScreen) {
    //     handleSetIsVideoReadyToPlay(true)
    // }
    if (true) {
      handleSetIsVideoReadyToPlay(true);
    }
  }, [isChefMainScreen]);

  const allCuisineList = chef?.cuisine.split(",");
  const isChefCommingSoon = !kitchenInfo?.restID;

  return (
    <>
      {!isVideoReadyToPlay && <ChefCardSkeleton />}
      <div
        style={!isChefMainScreen ? { marginBottom: 15 } : {}}
        className={`chefCardWrapper ${isVideoReadyToPlay ? "show" : "hide"}`}
      >
        <div
          className={classNames({
            chefCardMainScreen: isChefMainScreen,
            chefCardWrapperContainer: !isChefMainScreen,
          })}
        >
          <div className="carouselOverlayContentWrapper">
            <div
              style={
                isChefCommingSoon ? { filter: "blur(4px) brightness(0.7)" } : {}
              }
            >
              <Carousel
                preventMovementUntilSwipeScrollTolerance={true}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                // showIndicators={!isChefMainScreen}
                showIndicators={false}
                swipeScrollTolerance={100}
                className="slider-custom-carousel"
                onChange={onChange}
                destroyCarousel
                onClickItem={onClickItem}
                onClickThumb={onClickThumb}
              >
                {CarouselArray.map((img, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      // onChefClick(chef)
                    }}
                  >
                    {img.includes(".mp4") ? (
                      <div className="carousel-react-player">
                        <CustomVideoPlayer
                          videoUrl={img}
                          height={220}
                          onReady={onChefVideoReady}
                          index={index}
                          handleSetIsVideoReadyToPlay={
                            handleSetIsVideoReadyToPlay
                          }
                        />
                        <div
                          // onClick={() => onChefClick(chef)}
                          className="chefPhotoClickDiv"
                        ></div>
                      </div>
                    ) : (
                      <img
                        style={{ height: 220, objectFit: "cover" }}
                        alt=""
                        src={
                          img.includes("amazonaws.com")
                            ? img
                            : S3_MENU_THUMBNAIL_FOLDER + img
                        }
                      />
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
            {isChefMainScreen && (
              <div className="carouselTopBackbtn" onClick={onBackClick}>
                <BackFilledSvgIcon />
              </div>
            )}
            <div
              style={isChefCommingSoon ? { filter: "blur(4px)" } : {}}
              className={`carouselOverlayContent`}
            >
              {allCuisineList?.map((cuisine, index) => (
                <span
                  key={index}
                  className="carouselOverlayCuisineName Satoshi"
                >
                  {cuisine?.trim()}
                </span>
              ))}
            </div>
            {isChefCommingSoon && (
              <div className="chefCarouselOverlayCommingSoonWrapper">
                <div className="chefCarouselOverlayCommingSoonBox">
                  <ChefLockIconSvg />
                  <div className="chefCarouselOverlayCommingSoonDesc Satoshi">
                    This Chef's details are currently locked. Choose 'I am
                    interested!' to be alerted about updates.
                  </div>
                </div>
              </div>
            )}
            <div className="chefCardLinearGradient"></div>
            <div
              style={{ left: "-10px", textAlign: "end" }}
              className="carouselOverlayContent"
            ></div>
          </div>
          <ChefDetailInfoWrapper />
        </div>
      </div>
      <ChefOfflineBottomModal
        isOpen={isChefOfflineModalOpen}
        setOpen={setIsChefOfflineModalOpen}
      />
      <Modal
        className="hideTopBarAndBottomBar"
        wrapClassName="hideTopBarAndBottomBarWrapper"
        centered
        title="Basic Modal"
        open={isModalOpen}
      >
        <ThankYouPopupIconSvg />
      </Modal>
    </>
  );
};

export default ChefCardComponent;
