import CODPaymentIconSvg from "../../assets/ReactSvg/CODPaymentIconSvg";
import OnlinePaymentIconSvg from "../../assets/ReactSvg/OnlinePaymentIconSvg";
import OnlineUpiPaymentIconSvg from "../../assets/ReactSvg/OnlineUpiPaymentIconSvg";

export const paymentOptionIcon = (type) => {
  switch (type) {
    case "upi":
      return <OnlineUpiPaymentIconSvg />;
    case "cash":
      return <CODPaymentIconSvg />;
    case "online":
      return <OnlinePaymentIconSvg />;
    default:
      break;
  }
};

export const paymentOptionLabel = (type) => {
  switch (type) {
    case "upi":
      return "Upi";
    case "cash":
      return "Cash";
    case "online":
      return "Online";
    default:
      break;
  }
};
