import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import CloseModalIcon from "../../assets/ReactSvg/CloseModalIcon";
import DangerSvgIcon from "../../assets/ReactSvg/DangerSvgIcon";

const ChefOfflineBottomModal = ({ isOpen, setOpen }) => {
  return (
    <Sheet
      springConfig={{
        stiffness: 300,
        damping: 30,
        mass: 0.2,
      }}
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      detent="content-height"
      className="install-modal-popup customReactSheet"
    >
      <Sheet.Container>
        <Sheet.Content>
          <div
            onClick={() => {
              setOpen(false);
            }}
            className="closeIconInstallModal"
          >
            <CloseModalIcon />
          </div>
          <div className="chef-offline-modal-wrapper">
            <div className="chefOfflineLabelWrapper">
              <DangerSvgIcon />
              <div className="chefOfflineLabelHeaderContainer">
                <div className="chefOfflineLabelHeader Satoshi">
                  Chef Ustaad is currently offline
                </div>
                <div className="chefOfflineLabelHeaderdesc Satoshi">
                  Chef Ustaad’s timings are 2pm - 8pm. We will notify you once
                  the Chef is online.
                </div>
              </div>
            </div>
            <div className="chefOfflineBtnWrapper">
              <button className="chefOfflineNotifyMe Satoshi">Notify me</button>
              <button className="chefOfflineExploreOtherChefBtn Satoshi transparentBtn">Explore other chef</button>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => {
          setOpen(false);
        }}
      />
    </Sheet>
  );
};

export default ChefOfflineBottomModal;
