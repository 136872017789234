import * as React from "react";
const SwipeLeftIconSvg = (props) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="60"
      height="60"
      rx="30"
      transform="matrix(-1 0 0 1 60 0)"
      fill="white"
    />
    <path
      d="M26.0007 20.0022C40 22 45.9971 32 47.9985 42C42.9985 34.9986 36.0007 31.7984 26.0007 31.7984V39.9986L12.0014 25.9993L26.0007 12V20.0022Z"
      fill="#E77A45"
    />
  </svg>
);
export default SwipeLeftIconSvg;
