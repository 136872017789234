import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import "./dish-menu-category-card.css";
import { useDispatch } from "react-redux";
import { dishActions } from "../../store/dishSlice";
import mixpanel from "mixpanel-browser";
import VegSvgIcon from "../../assets/ReactSvg/VegSvgIcon";
import NonVegSvgIcon from "../../assets/ReactSvg/NonVegSvgIcon";
import ChefSvgIcon from "../../assets/ReactSvg/ChefSvgIcon";
import { getFoodTypeIcon } from "../../utils/helper";
// import clevertap from "clevertap-web-sdk";

const DishMenuCategoryCard = ({ index, name, totalDishes, img, elementWidth, category }) => {
  
  const dispatch = useDispatch();
  const tileMore = () => {
    dispatch(dishActions.updateTilesClicked());
    dispatch(dishActions.updateActiveTile(name));
    mixpanel.track("chef-story-clicked");
    // clevertap.event.push("chef-story-clicked");
  };
  const height = Math.random() < 0.5 ? elementWidth : elementWidth * 1.5;


  const categoriesList = category.categories || [];
  const foodType = categoriesList[0]?.dishes?.filter((a) => a?.type)[0]?.type ?? null;

  
  return (
    <div style={{height: height}} className="card-container">
      <div className="CategoryCardVegTypeIcon">
        {getFoodTypeIcon(foodType, 16)}
      </div>
      <div style={{paddingTop: 42}} className="text-container" onClick={tileMore}>
        <h1>{name}</h1>
        <div className="d-flex align-items-center dishesCardChefCount Satoshi"><ChefSvgIcon /> {totalDishes}</div>
      </div>
      <img
        src={
          img
            ? S3_MENU_THUMBNAIL_FOLDER + img
            : name == "Sides"
            ? "images/sides.jpg"
            : "images/rice.jpg"
        }
        alt="card image"
        className="dish-image"
        onClick={tileMore}
      />
    </div>
  );
};

export default DishMenuCategoryCard;
