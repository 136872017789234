import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import AppContainer from "../util/AppContainer";
import BottomBar from "../BottomBar";
import moment from "moment";
import "./orderDetailPage.css";
import {
  getExactTime,
  getExactTimeDateObj,
  getFoodTypeIcon,
  getOrderStatusLabel,
} from "../../utils/helper";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import HomeSvgIcon from "../../assets/ReactSvg/HomeSvgIcon";
import CheckIconSvg from "../../assets/ReactSvg/CheckIconSvg";
import { paymentOptionIcon, paymentOptionLabel } from "../cart/cartHelper";

const GST_RATE = 5;

const OrderDetailPage = () => {
  const dispatch = useDispatch();
  const firebaseToken = useSelector((state) => state.auth.user.firebaseToken);
  const from = useSelector((state) => state.bottomBar.from);
  const orderDetailPageData = useSelector(
    (state) => state.order.orderDetailPageData
  );
  const {
    orderRef,
    bookedDishes = [],
    status,
    paymentMode,
    address,
    totalCost,
    deliveryFee,
    packagingFee,
    gstFee,
    billingAmount
  } = orderDetailPageData || {};

  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const activeOrderDetails = useSelector((state) => state.auth.orderDetails);
  const singleOrderHistoryDetails = useSelector(
    (state) => state.auth.singleOrderHistoryDetails
  );
  const selectedDeliveryAddress = useSelector(
    (state) => state.auth.selectedAddress
  );
  const chefList = useSelector((state) => state.chef.chefList);
  const orderDeliveredDate = useSelector((state) => state.order.deliveredDate);
  const driverPhoneNumber = useSelector(
    (state) => state.order.driverPhoneNumber
  );
  const driverNameReducer = useSelector((state) => state.order.driverName);
  const orderStatus = useSelector((state) => state.order.orderStatus);
  const orderFulfillmentStatus = useSelector(
    (state) => state.order.orderFulfillment?.status
  );
  const user = useSelector((state) => state.auth.user);

  const orderDetails =
    singleOrderHistoryDetails &&
    Object.keys(singleOrderHistoryDetails).length !== 0
      ? singleOrderHistoryDetails
      : activeOrderDetails;

  const deliveryAddress = orderDetails?.address || selectedDeliveryAddress;
  const isOrderScheduled =
    !!orderDetails?.scheduleTime &&
    moment(orderDetails.scheduleTime).isValid() &&
    orderDetails.scheduleTime !== "NOW";

  const deliveryDateTime = isOrderScheduled
    ? orderDetails.scheduleTime
    : orderDetails.createdAt;
  const deliveryTime = getExactTime(
    deliveryDateTime,
    isOrderScheduled ? 0 : orderDetails?.deliveryTime || 0,
    isOrderScheduled
  );

  const deliveryTimeInDateObj = getExactTimeDateObj(
    deliveryDateTime,
    isOrderScheduled ? 0 : orderDetails?.deliveryTime || 0,
    isOrderScheduled
  );

  const totalCostValue = orderDetails?.bookedDishes?.reduce(
    (acc, item) => acc + item.dealPrice,
    0
  );

  const getGSTAmount = () => {
    return (parseInt(totalCostValue) * GST_RATE) / 100;
  };

  const uniqueChefIdList = [
    ...new Set(orderDetails.bookedDishes?.map((dish) => dish.dish.chefId) || [])
  ];
  const uniqueChefInfoList = chefList.filter((c) =>
    uniqueChefIdList.includes(c._id)
  );
  const orderChefNameList = uniqueChefInfoList.map((c) => c.name).join(", ");

  const kitchenInfo = kitchensList?.find(
    (kitchen) => kitchen?._id === uniqueChefInfoList?.[0]?.kitchenId
  );
  const isOrderDelivered = ["DELIVERED", "completed", "ended"].includes(
    status
  );

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName }));
  };

  const handleCallDriverButtonClick = () => {
    window.open(`tel:+91${driverPhoneNumber}`);
  };

  const onSupportClick = () => {
    let url = `https://wa.me/918655861798?text=Hey+Cuirato,+I+want+help+regarding+an+order+Number+${orderDetails?.orderRef}.`;
    url = window.encodeURI(url);
    window.open(url, "_blank");
  };

  const onBackClick = () => {
    switch (from) {
      case "orderSummary":
        switchTab("orderSummary");
        dispatch(bottomBarActions.updateFrom({ from: "" }));
        break;
      default:
        dispatch(loginActions.setSingleOrderHistoryDetails(null));
        switchTab("chef");
        break;
    }
  };

  const platformFees = packagingFee;

  return (
    <AppContainer>
      <div className="orderDetailPageWrapperContainer">
        <div className="orderDetailPageWrapper">
          <div className="orderDetailPageTopbar">
            <div
              style={{ columnGap: 12 }}
              className="d-flex align-items-center"
            >
              <span style={{ height: 24 }}>
                <button
                  onClick={onBackClick}
                  type="button"
                  className="btn back-btn orderStatusSummaryBackBtn"
                >
                  <BackBtnSvg />
                </button>
              </span>
              <div className="orderDetailPageTopbarInfoWrapper">
                <div className="orderDetailPageTopbarInfoLabel Satoshi">
                  Order #{orderRef}
                </div>
                <div className="orderDetailPageTopbarInfoDesc Satoshi">
                  {getOrderStatusLabel(status)}, {bookedDishes.length} items, ₹
                  {billingAmount}
                </div>
              </div>
            </div>
            <button
              onClick={onSupportClick}
              className="transparentBtn orderDetailPageHelpBtn Satoshi"
            >
              Help
            </button>
          </div>
        </div>
        <div className="orderDetailPageContent">
          <div style={{ columnGap: 12 }} className="d-flex align-items-center">
            <span style={{ height: 24 }}>
              <button
                onClick={onBackClick}
                type="button"
                className="btn back-btn orderStatusSummaryBackBtn"
              >
                <img src="images/location1.png" alt="location" />
              </button>
            </span>
            <div className="orderDetailPageTopbarInfoWrapper">
              <div className="orderDetailChefNameLabel Satoshi">
                {orderChefNameList}
              </div>
              <div className="orderDetailChefAddressInfo Satoshi">
                {kitchenInfo?.address
                  ? kitchenInfo?.address?.length >= 40
                    ? kitchenInfo?.address?.slice(0, 40) + "..."
                    : kitchenInfo?.address
                  : ""}
              </div>
            </div>
          </div>
          <div style={{ columnGap: 12 }} className="d-flex align-items-center">
            <span style={{ height: 24 }}>
              <button
                onClick={onBackClick}
                type="button"
                className="btn back-btn orderStatusSummaryBackBtn"
              >
                <HomeSvgIcon size={24} />
              </button>
            </span>
            <div className="orderDetailPageTopbarInfoWrapper">
              <div className="orderDetailDeliveryAddressLabel Satoshi">
                {address?.tag}
              </div>
              <div className="orderDetailDeliveryAddressDesc Satoshi">
                {address?.houseNumber}
                {address?.area && `, ${address?.area}`}
                {address?.landMark && `, ${address?.landMark}`}
                {address?.location?.locationName &&
                  `, ${address?.location?.locationName}`}
              </div>
            </div>
          </div>
          <div
            style={{ justifyContent: "space-between" }}
            className="d-flex align-items-center"
          >
            <div
              style={{ columnGap: 12 }}
              className="d-flex align-items-center"
            >
              <span style={{ height: 24 }}>
                <button
                  onClick={onBackClick}
                  type="button"
                  className="btn back-btn orderStatusSummaryBackBtn"
                >
                  <CheckIconSvg />
                </button>
              </span>
              <div className="orderDetailPageTopbarInfoWrapper">
                <div className="orderDetailPageDeliveryDescription Satoshi">
                  {isOrderDelivered ? `Order delivered by ${driverNameReducer}` : `order will be delivered on ${deliveryTime} by ${driverNameReducer}`}
                </div>
              </div>
            </div>
            <div className="orderDetailPageDeliveryDescLabel Satoshi">
              ON TIME
            </div>
          </div>
        </div>
        <div style={{ padding: 16 }}>
          <div className="orderSummarySectionTitle Satoshi">
            Billing details
          </div>
          <div className="orderSummaryBillingDetails">
            <div className="orderSummaryOrderItems orderDetailPageItemsWrapper">
              {[...bookedDishes].map(
                (dish) => (
                  <div className="orderSummaryOrderDishInfoWrapper">
                    {getFoodTypeIcon(dish?.dish?.type)}
                    <div className="orderSummaryOrderDishInfoDesc Satoshi">
                      {dish.portion} x {dish?.dish?.name}
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              style={{ marginTop: 12 }}
              className="orderSummaryBillingItemContainer"
            >
              <div className="orderSummaryBillingItem">
                <span className="orderSummaryBillingItemLabel Satoshi">
                  Item total
                </span>
                <span className="orderSummaryBillingItemValue Satoshi">
                  ₹{totalCost}
                </span>
              </div>
              <div className="orderSummaryBillingItem">
                <span className="orderSummaryBillingItemLabel Satoshi">
                  Delivery fees
                </span>
                <span className="orderSummaryBillingItemValue Satoshi">
                  ₹{deliveryFee}
                </span>
              </div>
              <div className="orderSummaryBillingItem">
                <span className="orderSummaryBillingItemLabel Satoshi">
                  Platform fee
                </span>
                <span className="orderSummaryBillingItemValue Satoshi">
                  ₹{platformFees}
                </span>
              </div>
              <div className="orderSummaryBillingItem">
                <span className="orderSummaryBillingItemLabel Satoshi">
                  GST
                </span>
                <span className="orderSummaryBillingItemValue Satoshi">
                  ₹{gstFee}
                </span>
              </div>
            </div>
            <div
              style={{ margin: "12px 0px" }}
              className="orderSummaryBillingTotal"
            >
              <span
                style={{ color: "#181C22", fontWeight: 700 }}
                className="orderSummaryBillingTotalLabel Satoshi"
              >
                Grand total
              </span>
              <span className="orderSummaryBillingTotalValue Satoshi">
                ₹{billingAmount}
              </span>
            </div>
            <div className="orderSummaryBillingTotal orderDetailBillingTotal">
              <span className="orderSummaryBillingTotalLabel Satoshi">
                Payment mode
              </span>
              <div className="d-flex align-items-center">
                <span style={{ marginRight: 8, height: 32 }}>
                  {paymentOptionIcon(paymentMode)}
                </span>
                <span className="orderSummaryBillingTotalValue Satoshi">
                  {paymentOptionLabel(paymentMode)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default OrderDetailPage;
