import * as React from "react";
const OnlinePaymentIconSvg = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.3} y={0.3} width={31.4} height={31.4} rx={6.1} fill="white" />
    <rect
      x={0.3}
      y={0.3}
      width={31.4}
      height={31.4}
      rx={6.1}
      stroke="#D5D1C9"
      strokeWidth={0.6}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4768 19.897C19.315 20.9845 19.0893 21.976 18.8057 22.8266C18.5924 23.4664 18.3502 24.0281 18.0674 24.5024C20.3784 23.9414 22.3322 22.4586 23.5206 20.4688C22.3456 20.2125 20.9644 20.0188 19.4776 19.8961L19.4768 19.897Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5274 19.897C11.0406 20.0196 9.65937 20.2142 8.48438 20.4704C9.67265 22.4595 11.6266 23.9414 13.9376 24.5024C13.6547 24.0282 13.4126 23.4665 13.1993 22.8266C12.9157 21.9758 12.6891 20.9844 12.5282 19.897L12.5274 19.897Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0022 19.7502C15.2491 19.7502 14.51 19.7752 13.7842 19.8127C13.9357 20.801 14.1396 21.6924 14.3857 22.4323C14.6537 23.2354 14.9717 23.8651 15.2771 24.2526C15.5826 24.6393 15.824 24.7502 16.0021 24.7502C16.1802 24.7502 16.4217 24.6393 16.7271 24.2518C17.0326 23.8643 17.3506 23.2346 17.6185 22.4315C17.8654 21.6917 18.0685 20.8003 18.2201 19.8119C17.4943 19.7752 16.7553 19.7502 16.0022 19.7502Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7426 16.6252C19.7277 17.3221 19.6887 17.9994 19.627 18.6494C21.2668 18.783 22.7941 19.0033 24.1011 19.3119C24.4457 18.4736 24.6613 17.5697 24.7285 16.6251L19.7426 16.6252Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5127 16.6252C13.5283 17.2947 13.5658 17.9447 13.626 18.5658C14.4025 18.526 15.1939 18.5002 16.0018 18.5002C16.8096 18.5002 17.601 18.526 18.3776 18.5658C18.4385 17.9447 18.476 17.2947 18.4908 16.6252H13.5127Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27637 16.6252C7.34355 17.5697 7.55919 18.4736 7.9037 19.312C9.21074 19.0026 10.7373 18.7831 12.3779 18.6495C12.3162 17.9995 12.2771 17.3221 12.2623 16.6253L7.27637 16.6252Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.615 13.5634C13.5611 14.1455 13.5268 14.7509 13.5127 15.3751H18.4909C18.4768 14.7517 18.4424 14.1462 18.3885 13.565C17.6089 13.6056 16.8135 13.6306 16.0017 13.6306C15.1899 13.6306 14.3948 13.604 13.615 13.5634Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1461 12.8064C22.8313 13.1204 21.2921 13.3447 19.6367 13.4806C19.6922 14.0907 19.7281 14.7243 19.7414 15.3751H24.7266C24.6625 14.4751 24.4625 13.6119 24.1453 12.8063L24.1461 12.8064Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8576 12.8064C7.54043 13.6111 7.34043 14.4743 7.27637 15.3752H12.2615C12.2756 14.7244 12.3115 14.0908 12.3662 13.4807C10.7108 13.3455 9.17165 13.1213 7.85685 12.8064L7.8576 12.8064Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0664 7.49792C18.3492 7.97214 18.5914 8.53386 18.8047 9.1737C19.1 10.0589 19.3344 11.0932 19.4969 12.2331C21.0047 12.1081 22.403 11.9089 23.5874 11.6456C22.407 9.598 20.4218 8.06972 18.0664 7.49792Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9389 7.49788C11.5836 8.06976 9.59836 9.59787 8.41797 11.6455C9.60234 11.908 11.0016 12.1072 12.5086 12.233C12.671 11.0931 12.9054 10.0588 13.2007 9.17358C13.414 8.53374 13.6561 7.9721 13.9389 7.49788Z"
      fill="#676C6F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0027 7.25024C15.8246 7.25024 15.5832 7.36118 15.2777 7.74868C14.9723 8.13618 14.6543 8.76586 14.3863 9.56899C14.1285 10.3416 13.9168 11.2768 13.7637 12.3182C14.4965 12.3565 15.2418 12.3815 16.0027 12.3815C16.7628 12.3815 17.5089 12.3565 18.2417 12.3182C18.0885 11.2768 17.8768 10.3416 17.619 9.56899C17.351 8.76588 17.0331 8.13618 16.7276 7.74868C16.4221 7.36118 16.1808 7.25024 16.0027 7.25024Z"
      fill="#676C6F"
    />
  </svg>
);
export default OnlinePaymentIconSvg;
