import * as React from "react";
const ChefLockIconSvg = (props) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M19.9999 28.9167C21.5002 28.9167 22.7165 27.7004 22.7165 26.2C22.7165 24.6996 21.5002 23.4833 19.9999 23.4833C18.4995 23.4833 17.2832 24.6996 17.2832 26.2C17.2832 27.7004 18.4995 28.9167 19.9999 28.9167Z"
      fill="white"
    />
    <path
      d="M27.7502 15.7333H12.2502C5.41683 15.7333 3.3335 17.8167 3.3335 24.65V27.75C3.3335 34.5833 5.41683 36.6667 12.2502 36.6667H27.7502C34.5835 36.6667 36.6668 34.5833 36.6668 27.75V24.65C36.6668 17.8167 34.5835 15.7333 27.7502 15.7333ZM20.0002 31.2333C17.2168 31.2333 14.9668 28.9667 14.9668 26.2C14.9668 23.4333 17.2168 21.1667 20.0002 21.1667C22.7835 21.1667 25.0335 23.4333 25.0335 26.2C25.0335 28.9667 22.7835 31.2333 20.0002 31.2333Z"
      fill="white"
    />
    <path
      opacity={0.4}
      d="M11.8665 15.75V13.8C11.8665 8.91666 13.2499 5.66666 19.9999 5.66666C26.7499 5.66666 28.1332 8.91666 28.1332 13.8V15.75C28.9832 15.7667 29.7499 15.8 30.4665 15.9V13.8C30.4665 9.29999 29.3832 3.33333 19.9999 3.33333C10.6165 3.33333 9.5332 9.29999 9.5332 13.8V15.8833C10.2332 15.8 11.0165 15.75 11.8665 15.75Z"
      fill="white"
    />
  </svg>
);
export default ChefLockIconSvg;
